import ourBusinessesActionTypes from './ourBusinessesActionTypes';

const getListOurBusinessesRequest = () => ({
    type: ourBusinessesActionTypes.GET_LIST_OUR_BUSINESSES_REQUEST
});

const getListOurBusinessesSuccess = (payload) => ({
    type: ourBusinessesActionTypes.GET_LIST_OUR_BUSINESSES_SUCCESS,
    payload
});

const getListOurBusinessesFailure = (error) => ({
    type: ourBusinessesActionTypes.GET_LIST_OUR_BUSINESSES_FAILURE,
    payload: error
});

// ? Get list medicines

const getListMedicinesRequest = () => ({
    type: ourBusinessesActionTypes.GET_LIST_MEDICINES_REQUEST
});

const getListMedicinesSuccess = (payload) => ({
    type: ourBusinessesActionTypes.GET_LIST_MEDICINES_SUCCESS,
    payload
});

const getListMedicinesFailure = (error) => ({
    type: ourBusinessesActionTypes.GET_LIST_MEDICINES_FAILURE,
    payload: error
});

// ? Get list tender

const getListTenderRequest = (payload) => ({
    type: ourBusinessesActionTypes.GET_LIST_TENDER_REQUEST,
    payload
});

const getListTenderSuccess = (payload) => ({
    type: ourBusinessesActionTypes.GET_LIST_TENDER_SUCCESS,
    payload
});

const getListTenderFailure = (error) => ({
    type: ourBusinessesActionTypes.GET_LIST_TENDER_FAILURE,
    payload: error
});

// ? Search data

const searchDataRequest = (payload) => {
    return {
        type: ourBusinessesActionTypes.SEARCH_DATA_REQUEST,
        payload
    };
};

const searchDataSuccess = (payload) => ({
    type: ourBusinessesActionTypes.SEARCH_DATA_SUCCESS,
    payload
});

const searchDataFailure = (error) => ({
    type: ourBusinessesActionTypes.SEARCH_DATA_FAILURE,
    payload: error
});

// ? Get here for you

const getHereForYouRequest = () => ({
    type: ourBusinessesActionTypes.GET_HERE_FOR_YOU_REQUEST
});

const getHereForYouSuccess = (payload) => ({
    type: ourBusinessesActionTypes.GET_HERE_FOR_YOU_SUCCESS,
    payload
});

const getHereForYouFailure = (error) => ({
    type: ourBusinessesActionTypes.GET_HERE_FOR_YOU_FAILURE,
    payload: error
});

export default {
    getListOurBusinessesRequest,
    getListOurBusinessesSuccess,
    getListOurBusinessesFailure,
    getListMedicinesRequest,
    getListMedicinesSuccess,
    getListMedicinesFailure,
    getListTenderRequest,
    getListTenderSuccess,
    getListTenderFailure,
    searchDataRequest,
    searchDataSuccess,
    searchDataFailure,
    getHereForYouRequest,
    getHereForYouSuccess,
    getHereForYouFailure
};
