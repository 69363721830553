import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

const Clinic = ({ item }) => {
  var clinicItems;
  clinicItems = item.items.map((_item) => {
    return (
      <div key={_item.id} className='bg-white p-5 mb-5'>
        <h1 className='text-2xl font-bold'>
          <a href='our-services?d_id=9'>{_item.name}</a>
        </h1>
        <hr className='my-2' />
        <div className='flex items-start justify-between mb-5'>
          <div className='w-8/12 pr-5'>
            <div className='space-y-4'>
              {ReactHtmlParser(_item.description)}
              <div>
                <a className='inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded'>
                  Call: {_item.phone_number}
                  <i className='fas fa-phone-rotary ml-2' aria-hidden='true'></i>
                </a>
              </div>
            </div>
          </div>
          <div className='w-4/12'>
            <h3 className='text-lg font-semibold'>Quick Info</h3>
            <br />
            {ReactHtmlParser(_item.location)}
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className='container mx-auto'>
      <div className='pt-8 pb-16'>
        <div className='grid grid-cols-1 gap-8'>{clinicItems}</div>
      </div>
    </div>
  );
};

Clinic.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        image_thumb: PropTypes.string,
        price: PropTypes.number,
      })
    ),
    item_per_row: PropTypes.number,
  }),
};

export default Clinic;
