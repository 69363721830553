import React from 'react';
import PropTypes from 'prop-types';
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';

const HereForYouHeaderSection = ({ data = {} }) => {
    return (
        <BaseHeaderWithContentSection
            imagePath={ourHospitalsImagePath}
            title={data?.title}
            desc={data?.desc}
        />
    );
};

HereForYouHeaderSection.propTypes = {
    data: PropTypes.object
};

export default HereForYouHeaderSection;
