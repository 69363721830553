import contactUsActionTypes from './contactUsActionTypes';

const getContactUsDataRequest = () => ({
    type: contactUsActionTypes.GET_CONTACT_US_DATA_REQUEST
});

const getContactUsDataSuccess = (payload) => ({
    type: contactUsActionTypes.GET_CONTACT_US_DATA_SUCCESS,
    payload
});

const getContactUsDataFailure = (error) => ({
    type: contactUsActionTypes.GET_CONTACT_US_DATA_FAILURE,
    payload: error
});

// ? Submit contact us form
const submitContactUsFormRequest = (payload) => ({
    type: contactUsActionTypes.SUBMIT_CONTACT_US_FORM_REQUEST,
    payload
});

const submitContactUsFormSuccess = (payload) => ({
    type: contactUsActionTypes.SUBMIT_CONTACT_US_FORM_SUCCESS,
    payload
});

const submitContactUsFormFailure = (error) => ({
    type: contactUsActionTypes.SUBMIT_CONTACT_US_FORM_FAILURE,
    payload: error
});

// ? Get care card program
const getCareCardProgramRequest = () => ({
    type: contactUsActionTypes.GET_CARE_CARD_PROGRAM_REQUEST
});

const getCareCardProgramSuccess = (payload) => ({
    type: contactUsActionTypes.GET_CARE_CARD_PROGRAM_SUCCESS,
    payload
});

const getCareCardProgramFailure = (error) => ({
    type: contactUsActionTypes.GET_CARE_CARD_PROGRAM_FAILURE,
    payload: error
});

// ? Get list careers
const getListCareersRequest = (payload) => ({
    type: contactUsActionTypes.GET_LIST_CAREERS_REQUEST,
    payload
});

const getListCareersSuccess = (payload) => ({
    type: contactUsActionTypes.GET_LIST_CAREERS_SUCCESS,
    payload
});

const getListCareersFailure = (error) => ({
    type: contactUsActionTypes.GET_LIST_CAREERS_FAILURE,
    payload: error
});

// ? Submit resume
const submitResumeRequest = (payload) => ({
    type: contactUsActionTypes.SUBMIT_RESUME_REQUEST,
    payload
});

const submitResumeSuccess = (payload) => ({
    type: contactUsActionTypes.SUBMIT_RESUME_SUCCESS,
    payload
});

const submitResumeFailure = (payload) => ({
    type: contactUsActionTypes.SUBMIT_RESUME_FAILURE,
    payload
});

const startSubmittingResume = () => ({
    type: contactUsActionTypes.START_SUBMITTING_RESUME
});

// ? Reset is submit success
const resetIsSubmitSuccess = () => ({
    type: contactUsActionTypes.RESET_IS_SUBMIT_SUCCESS
});

// ? Submit applications form
const submitApplicationsFormRequest = (payload) => ({
    type: contactUsActionTypes.SUBMIT_APPLICATIONS_REQUEST,
    payload
});

const submitApplicationsFormSuccess = (payload) => ({
    type: contactUsActionTypes.SUBMIT_APPLICATIONS_SUCCESS,
    payload
});

const submitApplicationsFormFailure = (error) => ({
    type: contactUsActionTypes.SUBMIT_APPLICATIONS_FAILURE,
    payload: error
});

export default {
    getContactUsDataRequest,
    getContactUsDataSuccess,
    getContactUsDataFailure,
    submitContactUsFormRequest,
    submitContactUsFormSuccess,
    submitContactUsFormFailure,
    getCareCardProgramRequest,
    getCareCardProgramSuccess,
    getCareCardProgramFailure,
    getListCareersRequest,
    getListCareersSuccess,
    getListCareersFailure,
    submitResumeRequest,
    submitResumeSuccess,
    submitResumeFailure,
    resetIsSubmitSuccess,
    startSubmittingResume,
    submitApplicationsFormRequest,
    submitApplicationsFormSuccess,
    submitApplicationsFormFailure
};
