import packageInitState from './packagesInitState';
import packagesActionsTypes from './packagesActionTypes';

import { getPackageDetailsHandler } from './packagesReducerHandler';

const packagesReducer = (state = packageInitState, action) => {
    switch (action.type) {
        case packagesActionsTypes.GET_PACKAGE_DETAILS_SUCCESS:
            return getPackageDetailsHandler(state, action);

        default:
            return state;
    }
};

export default packagesReducer;
