import React from 'react';
import PropTypes from 'prop-types';

import BaseSearchCard from 'components/BaseSearchCard';

const SearchLayout = ({ items = [] }) => {
    // ? Render items
    const renderItems = () => {
        if (items.length > 0) {
            return items.map((item, idx) => {
                return <BaseSearchCard key={idx} data={item} />;
            });
        }
    };
    return (
        <div className='container mx-auto flex gap-8 pt-8 pb-4 flex-col items-center '>
            {items.length > 0 && (
                <div className='py-5 px-5 bg-white w-full flex flex-col gap-5'>{renderItems()}</div>
            )}
        </div>
    );
};

SearchLayout.propTypes = {
    items: PropTypes.array
};

export default SearchLayout;
