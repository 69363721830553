import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// ~ Components
import { Tabs } from 'flowbite-react';

const OurBusinessesTabs = ({ tabs = [] }) => {
    // ? Render tabs
    const renderTabs = () => {
        return tabs.map((tab, index) => {
            return (
                <Tabs.Item key={index} title={tab.title} className='text-uppercase'>
                    <div className='bg-white p-6'>{ReactHtmlParser(tab.content)}</div>
                </Tabs.Item>
            );
        });
    };

    // ? Render tab group
    const renderTabGroup = () => {
        if (tabs.length > 0) {
            return (
                <Tabs.Group
                    id='businesses-tabs'
                    style='underline'
                    className='container mx-auto md:px-[80px] px-4 justify-center border-none w-full mt-5'
                >
                    {renderTabs()}
                </Tabs.Group>
            );
        }
    };
    return <>{renderTabGroup()}</>;
};

OurBusinessesTabs.propTypes = {
    tabs: PropTypes.array
};

export default OurBusinessesTabs;
