import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'flowbite-react';
import { Link } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

const BaseDropdownMenu = ({ item = {} }) => {
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const renderDropdownItem = (item, key) => {
        if (item.slug.includes('https')) {
            return (
                <a href={item.slug} key={key}>
                    <ListGroup.Item>{ReactHtmlParser(item.name)}</ListGroup.Item>
                </a>
            );
        } else {
            return (
                <Link to={item.slug} key={key} className='text-start'>
                    <ListGroup.Item>{ReactHtmlParser(item.name)}</ListGroup.Item>
                </Link>
            );
        }
    };

    return (
        <div className='relative'>
            <div
                className='py-2 relative group cursor-pointer'
                onMouseEnter={() => setIsShowDropdown((prevState) => !prevState)}
                onMouseLeave={() => setIsShowDropdown(false)}
            >
                {item.name}
            </div>

            <ListGroup
                className={`absolute z-10 ${
                    isShowDropdown ? 'block' : 'hidden'
                } min-w-[200px] w-fit dropdown-header`}
                onMouseEnter={() => setIsShowDropdown(true)}
                onMouseLeave={() => setIsShowDropdown(false)}
            >
                {item.childItems.map((dropdownItem, index) => {
                    return renderDropdownItem(dropdownItem, index);
                })}
            </ListGroup>
        </div>
    );
};

BaseDropdownMenu.propTypes = {
    item: PropTypes.object
};

export default BaseDropdownMenu;
