import React from 'react';
import PropTypes from 'prop-types';

const PhoneCircledIcon = ({ fill = '#e98076' }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.83 31.84' width={32} height={32}>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <path
                        fill={fill}
                        d='M31.83,16.23A15.92,15.92,0,1,1,16.28,0,15.94,15.94,0,0,1,31.83,16.23ZM6.35,10.84l0,.33a11,11,0,0,0,1.14,3.48,22.39,22.39,0,0,0,8.73,9.16,11.57,11.57,0,0,0,4.41,1.62,1.14,1.14,0,0,0,.66-.1,17.25,17.25,0,0,0,3.84-3.23c.42-.49.4-.76-.1-1.14a22.92,22.92,0,0,0-4.17-2.5c-.51-.23-.67-.19-1,.23s-.78.87-1.16,1.31a.37.37,0,0,1-.47.12c-.54-.23-1.12-.41-1.64-.69a9.76,9.76,0,0,1-4.87-5.81.39.39,0,0,1,.13-.49l1.14-1c.61-.54.69-.68.29-1.38-.74-1.32-1.53-2.61-2.34-3.88-.4-.63-.71-.65-1.26-.15A17.63,17.63,0,0,0,6.5,10.48,1.46,1.46,0,0,0,6.35,10.84Zm19.09,4.39A8.79,8.79,0,0,0,16.6,6.41V7.72a7.56,7.56,0,0,1,7.5,7.51ZM16.6,11.1a4.21,4.21,0,0,1,4.12,4.12H22A5.32,5.32,0,0,0,16.6,9.83Z'
                    />
                </g>
            </g>
        </svg>
    );
};

PhoneCircledIcon.propTypes = {
    fill: PropTypes.string
};

export default PhoneCircledIcon;
