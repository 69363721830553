import React from 'react';
import PropTypes from 'prop-types';

// ~ Components
import CardCareProgramTabs from './CardCareProgramTabs';

const CardCareProgramLayout = ({ sections = [] }) => {
    return (
        <div>
            <CardCareProgramTabs tabs={sections} />
        </div>
    );
};

CardCareProgramLayout.propTypes = {
    sections: PropTypes.array
};

export default CardCareProgramLayout;
