import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import HealthTourismHeaderSection from 'layouts/health-tourism/HealthTourismHeaderSection';
import HealthTourismLocationSection from 'layouts/health-tourism/HealthTourismLocationSection';
import HealthTourismTabs from 'layouts/health-tourism/HealthTourismTabs';
import healthTourismActions from 'services/health-tourism/healthTourismActions';
import { useHealthTourismSlice } from 'services/health-tourism';
import {
    makeSelectHealthTourismLocation,
    makeSelectHealthTourismTabsData
} from 'services/health-tourism/healthTourismSelector';

import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { makeSelectHealthTourismMetaData } from 'services/health-tourism/healthTourismSelector';

const HealthTourism = ({
    getHealthTourismData = () => {},
    healthTourismLocationData = {},
    healthTourismTabsData = {},
    metaData = {},
    metaDataHealthTourism = {},
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            useHealthTourismSlice();
            getHealthTourismData();
        }
    }, [isAuthenticated]);

    // ? Render meta
    const renderMetaData = () => {
        return (
            <Helmet>
                <meta charSet='utf-8' />
                <title>
                    {metaDataHealthTourism?.title !== ''
                        ? metaDataHealthTourism.title
                        : metaData.title}
                </title>
                <meta
                    name='description'
                    content={
                        metaDataHealthTourism?.description !== ''
                            ? metaDataHealthTourism?.description
                            : metaData.description
                    }
                />
                <meta
                    name='keywords'
                    content={
                        metaDataHealthTourism?.keywords !== ''
                            ? metaDataHealthTourism.keywords
                            : metaData.keywords
                    }
                />
            </Helmet>
        );
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer md:px-0 px-4'>
                <HealthTourismHeaderSection />
                <HealthTourismLocationSection data={healthTourismLocationData} />
                <HealthTourismTabs tabs={healthTourismTabsData.items} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getHealthTourismData: () => dispatch(healthTourismActions.getHealthTourismPageRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        healthTourismLocationData: makeSelectHealthTourismLocation(state),
        healthTourismTabsData: makeSelectHealthTourismTabsData(state),
        metaData: makeSelectMetaData(state),
        metaDataHealthTourism: makeSelectHealthTourismMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

HealthTourism.propTypes = {
    getHealthTourismData: PropTypes.func,
    healthTourismLocationData: PropTypes.object,
    healthTourismTabsData: PropTypes.object,
    metaData: PropTypes.object,
    metaDataHealthTourism: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthTourism);
