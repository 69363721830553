import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

// ~ Layouts
import DoctorDetailsBreadcrumb from 'layouts/find-doctor/DoctorDetailsBreadcrumb';
import DoctorDetailsLayout from 'layouts/find-doctor/DoctorDetailsLayout';

// ~ Services
import { useDoctorSlice } from 'services/doctor';
import doctorActions from 'services/doctor/doctorActions';
import { makeSelectDoctorDetails } from 'services/doctor/doctorSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const DoctorDetails = ({
    getDoctorDetails = () => {},
    doctorDetailsData = {},
    isAuthenticated = false
}) => {
    const navigate = useNavigate();
    const { slug } = useParams();

    useEffect(() => {
        useDoctorSlice();
        if (slug && isAuthenticated) {
            getDoctorDetails({ slug, navigate });
        }
    }, [slug, isAuthenticated]);

    return (
        <div className='bg-layer'>
            <DoctorDetailsBreadcrumb slug={slug} />
            <DoctorDetailsLayout data={doctorDetailsData} />
        </div>
    );
};

const makeDispatchToProps = (dispatch) => {
    return {
        getDoctorDetails: (slug) => dispatch(doctorActions.getDoctorDetails(slug))
    };
};

const makeStateToProps = (state) => {
    return {
        doctorDetailsData: makeSelectDoctorDetails(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

DoctorDetails.propTypes = {
    getDoctorDetails: PropTypes.func,
    doctorDetailsData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(makeStateToProps, makeDispatchToProps)(DoctorDetails);
