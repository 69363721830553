import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const Event = ({ item }) => {
  let eventItems;
  if (item.items.length === 0) {
    eventItems = () => {
      return (
        <div className='text-center mt-6'>
          <h3 className='text-lg font-semibold'>No Recently Events</h3>
        </div>
      );
    };
  } else {
    eventItems = item.items.map((_item) => {
      const url = 'events/' + _item.slug;
      return (
        <div key={_item.id} className='w-full sm:w-full md:w-full lg:w-auto'>
          <div className='container'>
            <div className='px-4 py-4'>
              <div className='bg-white border border-gray-200 rounded-none overflow-hidden' style={{ width: '300px' }}>
                <div className>
                  <div className='flex'>
                    <div className='w-2/3 bg-indigo-500' style={{backgroundColor:'rgb(87 117 211)'}} >
                        
                    <div className='text-white text-xs mt-2 p-2'
                        style={{ fontSize: '10px', textAlign: 'center' }}>
                        <p>
                            {new Date(_item.startDate.date).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                            })}<br />
                            <hr className='my-4 border-gray-300' />
                            {new Date(_item.endDate.date).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                            })}
                        </p>
                    </div>


                        </div> {/* Empty column for blank space */}
                    <div className='w-3/4 p-2' style={{ width: '400px' }}>
                      <a className='text-lg font-semibold text-blue-700 hover:text-blue-900' href={url} style={{ fontSize:'12px', color:'black' }}>
                        {ReactHtmlParser(_item.title)}
                      </a>
                      <div className='text-sm mt-2'>
                        <a className='text-blue-500 hover:text-black-700 text-gray-700' style={{ fontSize:'13px' }} href={url}>More</a>
                      </div>
      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  
  return (
    <div>
      <div className='flex flex-wrap -mx-4'>
        {eventItems}
      </div>
    </div>
  );
};

Event.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        image_thumb: PropTypes.string,
        price: PropTypes.number,
      })
    ),
    item_per_row: PropTypes.number,
  }),
};

export default Event;
