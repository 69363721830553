import newsInitState from './newsInitState';
import newsActionTypes from './newsActionTypes';

import { getListNewsHandler, getNewsDetailsHandler } from './newsReducerHandler';

const newsReducer = (state = newsInitState, action) => {
    switch (action.type) {
        case newsActionTypes.GET_LIST_NEWS_SUCCESS:
            return getListNewsHandler(state, action);

        case newsActionTypes.GET_NEWS_DETAILS_SUCCESS:
            return getNewsDetailsHandler(state, action);

        default:
            return state;
    }
};

export default newsReducer;
