import csrActionTypes from './csrActionTypes';
import csrInitState from './csrInitState';
import {
    getCovid19BookletDataHandler,
    getCsrDataHandler,
    getKlinikWaqafDataHandler
} from './csrReducerHandler';

const csrReducer = (state = csrInitState, action) => {
    switch (action.type) {
        case csrActionTypes.GET_CSR_DATA_SUCCESS:
            return getCsrDataHandler(state, action);
        case csrActionTypes.GET_KLINIK_WAQAF_DATA_SUCCESS:
            return getKlinikWaqafDataHandler(state, action);
        case csrActionTypes.GET_COVID19_BOOKLET_DATA_SUCCESS:
            return getCovid19BookletDataHandler(state, action);
        default:
            return state;
    }
};

export default csrReducer;
