import {
    ourServicesSectionsDataTransformer,
    ourServicesSubheaderDataTransformer,
    ourServicesMetaDataTransformer
} from 'transformer/our-services';

import { centerForSightDataTransformer } from 'transformer/center-for-sight';

/**
 * TODO: Get list services handler
 */

const getListServicesHandler = (state, { payload }) => {
    return {
        ...state,
        sections: ourServicesSectionsDataTransformer(payload?.sections),
        subheader: ourServicesSubheaderDataTransformer(payload),
        metaData: ourServicesMetaDataTransformer(payload)
    };
};

/**
 * TODO: Get center for sight handler
 */

const getCenterForSightHandler = (state, { payload }) => {
    return {
        ...state,
        centerForSight: centerForSightDataTransformer(payload)
    };
};

/**
 * TODO: Get senior living care handler
 */

const getSeniorLivingCareHandler = (state, { payload }) => {
    return {
        ...state,
        seniorLivingCare: centerForSightDataTransformer(payload)
    };
};

/**
 * TODO: Get laboratory services handler
 */

const getLaboratoryServicesHandler = (state, { payload }) => {
    return {
        ...state,
        laboratoryServices: centerForSightDataTransformer(payload)
    };
};

/**
 * TODO: Get dental speciality center handler
 */

const getDentalSpecialityCenterHandler = (state, { payload }) => {
    return {
        ...state,
        dentalSpecialityCenter: centerForSightDataTransformer(payload)
    };
};

/**
 * TODO: Get rehabilitation services handler
 */

const getRehabilitationServicesHandler = (state, { payload }) => {
    return {
        ...state,
        rehabilitationServices: centerForSightDataTransformer(payload)
    };
};

/**
 * TODO: Get bariatric surgery handler
 */

const getBariatricSurgeryHandler = (state, { payload }) => {
    return {
        ...state,
        bariatricSurgery: centerForSightDataTransformer(payload)
    };
};

export {
    getListServicesHandler,
    getCenterForSightHandler,
    getSeniorLivingCareHandler,
    getLaboratoryServicesHandler,
    getDentalSpecialityCenterHandler,
    getRehabilitationServicesHandler,
    getBariatricSurgeryHandler
};
