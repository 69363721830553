import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import DirectionImagePath from 'assets/common/direction.png';

const HealthTourismLocationSection = ({ data = {} }) => {
    return (
        <div
            id='contact-us'
            className={`mt-10 mb-4 md:grid mx-auto md:px-[80px] px-0 container md:h-[640px] h-[1048px] overflow-hidden md:overflow-visible md:grid-cols-${data?.column} h-full flex flex-col`}
        >
            {ReactHtmlParser(data?.leftSection)}
            {ReactHtmlParser(data?.rightSection)}

            <div className='h-[48px] w-full flex items-center justify-center bg-black text-white col-span-2 cursor-pointer hover:opacity-80'>
                <div className='flex gap-3 items-center'>
                    <img src={DirectionImagePath} className='w-4 h-4' />

                    <a
                        href='https://www.waze.com/ul?q=Menara%20KPJ%20Kuala%20Lumpur'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <div>Direction</div>
                    </a>
                </div>
            </div>
        </div>
    );
};

HealthTourismLocationSection.propTypes = {
    data: PropTypes.object
};

export default HealthTourismLocationSection;
