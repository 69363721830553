import ourServicesInitState from './ourServicesInitState';
import ourServicesActionTypes from './ourServicesActionTypes';

import {
    getListServicesHandler,
    getCenterForSightHandler,
    getSeniorLivingCareHandler,
    getLaboratoryServicesHandler,
    getDentalSpecialityCenterHandler,
    getRehabilitationServicesHandler,
    getBariatricSurgeryHandler
} from './ourServicesReducerHandler';

const ourServicesReducer = (state = ourServicesInitState, action) => {
    switch (action.type) {
        case ourServicesActionTypes.LIST_OUR_SERVICES_SUCCESS:
            return getListServicesHandler(state, action);

        case ourServicesActionTypes.GET_CENTER_FOR_SIGHT_SUCCESS:
            return getCenterForSightHandler(state, action);

        case ourServicesActionTypes.GET_SENIOR_LIVING_CARE_SUCCESS:
            return getSeniorLivingCareHandler(state, action);

        case ourServicesActionTypes.GET_LABORATORY_SERVICES_SUCCESS:
            return getLaboratoryServicesHandler(state, action);

        case ourServicesActionTypes.GET_DENTAL_SPECIALITY_CENTER_SUCCESS:
            return getDentalSpecialityCenterHandler(state, action);

        case ourServicesActionTypes.GET_REHABILITATION_CENTER_SUCCESS:
            return getRehabilitationServicesHandler(state, action);

        case ourServicesActionTypes.GET_BARIATRIC_SURGERY_SUCCESS:
            return getBariatricSurgeryHandler(state, action);

        default:
            return state;
    }
};

export default ourServicesReducer;
