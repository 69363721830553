import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
// ~ Components
import CareersHeaderSection from 'layouts/careers/CareersHeaderSection';
import CareersLayout from 'layouts/careers/CareersLayout';

// ~ Services
import { connect } from 'react-redux';
import contactUsActions from 'services/contact-us/contactUsActions';
import { useContactUsSlice } from 'services/contact-us';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const Careers = ({ getListCareers = () => {}, metaData = {}, isAuthenticated = false }) => {
    useEffect(() => {
        if (isAuthenticated) {
            useContactUsSlice();
            getListCareers();
        }
    }, [isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta name='keywords' content={metaData?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <CareersHeaderSection />
                <CareersLayout />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListCareers: () => dispatch(contactUsActions.getListCareersRequest())
    };
};
const mapStateToProps = (state) => {
    return {
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

Careers.propTypes = {
    getListCareers: PropTypes.func,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(Careers);
