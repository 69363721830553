import { camelize } from 'transformer/common';
import { transformContent } from 'utils';

const centerForSightDataTransformer = (data) => {
    const camelizeData = camelize(data);

    let dataTransformer = {};

    dataTransformer.meta = {
        title: camelizeData.metaTitle,
        description: camelizeData.metaDescription,
        keywords: camelizeData.metaKeywords
    };

    // ? Sections
    const sections = camelizeData.sections;
    const staticBlocks = camelizeData.staticBlocks;

    let sectionData = [];

    sections.forEach((section) => {
        let tempSectionData = {
            contents: []
        };

        if (section.content.length > 0) {
            section.content.forEach((content) => {
                let tempObject = {};
                staticBlocks.forEach((staticBlock) => {
                    if (content.includes(staticBlock.shortcode)) {
                        tempObject.items = staticBlock.items;
                    }
                });

                tempObject.content = transformContent(content);

                const firstIndex = tempObject.content.indexOf('[');
                const lastIndex = tempObject.content.lastIndexOf(']');

                const firstString = tempObject.content.substring(0, firstIndex);
                const lastString = tempObject.content.substring(lastIndex + 1).trim();

                tempObject.content = firstString + lastString;

                tempSectionData.contents.push(tempObject);
            });
        }

        sectionData.push(tempSectionData);
    });

    dataTransformer.layout = {
        title: camelizeData.title,
        content: camelizeData.content,
        sections: sectionData
    };

    return dataTransformer;
};

export { centerForSightDataTransformer };
