import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ? Components
import TheTopHeader from './TheTopHeader';
import TheSideNavBar from './TheSideNavBar';
import { useMetaSlice } from 'services/meta';
import { useOurServicesSlice } from 'services/our-services';
import metaActions from 'services/meta/metaActions';

import { makeSelectHeaderData } from 'services/meta/metaSelectors';
import ourServicesActions from 'services/our-services/ourServicesActions';

const TheHeader = ({
    headerData,
    getMetaData = () => {},
    getListStates = () => {},
    //    getAllSpecialities = () => {},
    getListServices = () => {}
}) => {
    useEffect(() => {
        useMetaSlice();
        getMetaData();
        setTimeout(() => {
            getListStates();
            //    getAllSpecialities();
            useOurServicesSlice();
            getListServices();
        }, 1000);
    }, []);
    return (
        <header className='w-full bg-main text-white'>
            <div className='container mx-auto'>
                <TheTopHeader data={headerData.topHeader} />

                <div className='w-full h-px bg-white' />

                <TheSideNavBar menus={headerData.menus} />
            </div>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        headerData: makeSelectHeaderData(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMetaData: () => dispatch(metaActions.getMetaData()),
        getListStates: () => dispatch(metaActions.getAllState()),
        getAllSpecialities: () => dispatch(metaActions.getAllSpecialities()),
        getListServices: () => dispatch(ourServicesActions.listOurServices())
    };
};

TheHeader.propTypes = {
    headerData: PropTypes.object,
    getMetaData: PropTypes.func,
    getListStates: PropTypes.func,
    getAllSpecialities: PropTypes.func,
    getListServices: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(TheHeader);
