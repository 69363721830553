import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFoundImagePath from 'assets/common/404.png';

const PageNotFound = () => {
    return (
        <div className='flex flex-col justify-center items-center py-10 bg-layer gap-5'>
            <div className='w-[178px] h-[203px]'>
                <img src={PageNotFoundImagePath} />
            </div>

            <div className='text-[#2b76b7] font-bold text-[30px]'>Oops..Page not found</div>

            <div className='font-bold text-center'>
                <div>
                    Sorry, we can&apos;t find that page! It might be an old link or maybe it moved.
                </div>

                <Link to='/'>
                    <div className='underline'>Click here to go back to the homepage</div>
                </Link>

                <a href='https://www.kpjhealth.com.my/kpj-cares-card-program'>
                    <div className='underline'>Go to latest Care Card page</div>
                </a>
            </div>
        </div>
    );
};

export default PageNotFound;
