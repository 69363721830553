import React from 'react';

const contactUsValue = [
    {
        text: 'General Line',
        value: '+603-2681 6222'
    },
    {
        text: 'Marketing',
        value: 'marketing@kpjhealth.com.my'
    },
    {
        text: 'Health Tourism',
        value: 'medtourism@kpjhealth.com.my'
    },
    { text: 'Investor Relation', value: 'ir@kpjhealth.com.my' },
    {
        text: 'Career',
        value: 'hr@kpjhealth.com.my'
    }
];

const ContactUsInformationSection = () => {
    // ? Render single item
    const renderSingleItem = (text, value, key) => {
        return (
            <div key={key} className='flex gap-5 mt-1'>
                <div className='font-bold w-[180px]'>{text}</div>
                <div>{value}</div>
            </div>
        );
    };
    return (
        <div className='mx-auto container md:mt-0 pt-5'>
            <div className='px-4 bg-[#fff]'>
                <div className='text-[28px] pt-5'>Contact Us</div>

                <div className='w-[55px] h-[2px] bg-main'></div>
                <div className='py-5'>
                    {contactUsValue.map((item, index) => {
                        return renderSingleItem(item.text, item.value, index);
                    })}
                </div>
            </div>
        </div>
    );
};

export default ContactUsInformationSection;
