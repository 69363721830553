import React from 'react';
import PropTypes from 'prop-types';

// ~ Components
import BaseCareerCard from 'components/BaseCareerCard';

const CareersCurrentOpening = ({ items = [] }) => {
    // ? Render list careers
    const renderListCareers = () => {
        if (items.length > 0) {
            return items.map((item, idx) => {
                return <BaseCareerCard key={idx} data={item} />;
            });
        }
    };

    return (
        <>
            <div className='text-center'>
                We are in search of remarkable people to join us in exploring and discovering better
                energy solutions together. Whether you are a student, a graduate or an experienced
                professional, discover the impact you could make with a career at KPJ Healthcare
                Group.
            </div>

            <div className='flex flex-col gap-5'>{renderListCareers()}</div>
        </>
    );
};

CareersCurrentOpening.propTypes = {
    items: PropTypes.array
};

export default CareersCurrentOpening;
