import { createSelector } from '@reduxjs/toolkit';
import doctorState from './doctorInitState';

const selectGlobal = (state) => {
    return state.doctor || doctorState;
};

// ? Make selector the list doctors
const makeSelectListDoctors = createSelector(selectGlobal, (globalState) => {
    return globalState.doctors;
});

// ? Make selector the list doctors paginator
const makeSelectListDoctorsPaginator = createSelector(selectGlobal, (globalState) => {
    return globalState.pagination;
});

// ? Make selector the doctor details data
const makeSelectDoctorDetails = createSelector(selectGlobal, (globalState) => {
    return globalState.doctorDetailsData;
});

export { makeSelectListDoctors, makeSelectListDoctorsPaginator, makeSelectDoctorDetails };
