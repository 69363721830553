const CONTACT_US_CONSTANTS = {
    GET_CONTACT_US_DATA: 'v1/page/contact-us',
    SUBMIT_CONTACT_US_FORM: 'v1/contact-us',
    GET_CARE_CARD_PROGRAM: 'v1/page/kpj-cares-card-program',
    GET_LIST_CAREERS: 'v1/careers',
    SUBMIT_RESUME: '/v1/career/submit',
    SUBMIT_APPLICATIONS: '/v1/application/submit'
};

export default CONTACT_US_CONSTANTS;
