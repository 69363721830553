import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ~ Components
// import Covid19BookletHeaderSection from 'layouts/covid19-booklet/Covid19BookletHeaderSection';
import Covid19BookletLayout from 'layouts/covid19-booklet/Covid19BookletLayout';

// ~ Services
import { useCsrSlice } from 'services/csr';
import csrActions from 'services/csr/csrActions';
import { makeSelectCovid19BookletData } from 'services/csr/csrSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const Covid19Booklet = ({
    getCovid19Booklet = () => {},
    covid19BookletData = {},
    isAuthenticated = false
}) => {
    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useCsrSlice();
            getCovid19Booklet();
        }
    }, [isAuthenticated]);
    return (
        <div className='bg-layer'>
            {/* <Covid19BookletHeaderSection data={covid19BookletData?.headerSection} /> */}
            <Covid19BookletLayout
                content={covid19BookletData?.content}
                sections={covid19BookletData?.sections}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCovid19Booklet: () => dispatch(csrActions.getCovid19BookletDataRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        covid19BookletData: makeSelectCovid19BookletData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

Covid19Booklet.propTypes = {
    getCovid19Booklet: PropTypes.func,
    covid19BookletData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Covid19Booklet);
