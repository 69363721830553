import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import ourServicesActions from 'services/our-services/ourServicesActions';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';
import { connect } from 'react-redux';
import {
    makeSelectRehabilitationServices,
    makeSelectListServices
} from 'services/our-services/ourServicesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { Helmet } from 'react-helmet-async';
import OurServicesDetailsLayout from 'layouts/our-services/OurServicesDetailsLayout';

const RehabilitationCentre = ({
    getRehabilitationCenter = () => {},
    rehabilitationCenter = {},
    sections = [],
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            getRehabilitationCenter();
        }
    }, [sections, isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{rehabilitationCenter?.meta?.title}</title>
                <meta name='description' content={rehabilitationCenter?.meta?.description} />
                <meta name='keywords' content={rehabilitationCenter?.meta?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <OurServicesHeaderSection defaultCheckedTitle='rehabilitation-centre' />
                <OurServicesDetailsLayout data={rehabilitationCenter?.layout} />
                <OurServicesFooter currentIndex={5} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRehabilitationCenter: () => dispatch(ourServicesActions.getRehabilitationCenter())
    };
};

const mapStateToProps = (state) => {
    return {
        rehabilitationCenter: makeSelectRehabilitationServices(state),
        sections: makeSelectListServices(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

RehabilitationCentre.propTypes = {
    getRehabilitationCenter: PropTypes.func,
    rehabilitationCenter: PropTypes.object,
    sections: PropTypes.array,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(RehabilitationCentre);
