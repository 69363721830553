import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import OurServicesDetailsLayout from 'layouts/our-services/OurServicesDetailsLayout';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';
import ourServicesActions from 'services/our-services/ourServicesActions';
import {
    makeSelectDentalSpecialityCenter,
    makeSelectListServices
} from 'services/our-services/ourServicesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

import { Helmet } from 'react-helmet-async';

const DentalSpecialistCentre = ({
    getDentalSpecialityCenter = () => {},
    dentalSpecialityCenter = {},
    sections = [],
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            getDentalSpecialityCenter();
        }
    }, [sections, isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{dentalSpecialityCenter?.meta?.title}</title>
                <meta name='description' content={dentalSpecialityCenter?.meta?.description} />
                <meta name='keywords' content={dentalSpecialityCenter?.meta?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <OurServicesHeaderSection defaultCheckedTitle='dental-specialist-centre' />
                <OurServicesDetailsLayout data={dentalSpecialityCenter?.layout} />
                <OurServicesFooter currentIndex={4} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDentalSpecialityCenter: () => dispatch(ourServicesActions.getDentalSpecialityCenter())
    };
};

const mapStateToProps = (state) => {
    return {
        dentalSpecialityCenter: makeSelectDentalSpecialityCenter(state),
        sections: makeSelectListServices(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

DentalSpecialistCentre.propTypes = {
    getDentalSpecialityCenter: PropTypes.func,
    dentalSpecialityCenter: PropTypes.object,
    sections: PropTypes.array,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(DentalSpecialistCentre);
