import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// ~ Components
import NewsHeaderSectionLayout from 'layouts/news/NewsHeaderSectionLayout';
import NewsDetailsContentLayout from 'layouts/news/NewsDetailsContentLayout';

// ~ Services
import { useNewsSlice } from 'services/news';
import newsActions from 'services/news/newsActions';
import { makeSelectNewsDetailsData } from 'services/news/newsSelector';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

// ~ Utils
import { isEmptyObject } from 'utils';

const NewsDetails = ({
    getNewsDetails = () => {},
    newsDetailsData = {},
    metaData = {},
    isAuthenticated = false
}) => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const renderMetaData = () => {
        if (!isEmptyObject(newsDetailsData)) {
            const metaDataNews = newsDetailsData?.metaData;
            return (
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {metaDataNews?.title !== '' ? metaDataNews.title : metaData.title}
                    </title>
                    <meta
                        name='description'
                        content={
                            metaDataNews?.description !== ''
                                ? metaDataNews?.description
                                : metaData.description
                        }
                    />
                    <meta
                        name='keywords'
                        content={
                            metaDataNews?.keywords !== ''
                                ? metaDataNews.keywords
                                : metaData.keywords
                        }
                    />
                </Helmet>
            );
        }
    };

    useEffect(() => {
        useNewsSlice();
        if (slug && isAuthenticated) {
            getNewsDetails({ slug, navigate });
        }
    }, [slug, isAuthenticated]);

    return (
        <>
            {renderMetaData()}

            <div className='bg-layer'>
                <NewsHeaderSectionLayout data={newsDetailsData?.headerSection} />
                <NewsDetailsContentLayout data={newsDetailsData?.contentSection} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNewsDetails: (payload) => dispatch(newsActions.getNewsDetailsRequest(payload))
    };
};

const mapStateToProps = (state) => {
    return {
        newsDetailsData: makeSelectNewsDetailsData(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

NewsDetails.propTypes = {
    getNewsDetails: PropTypes.func,
    newsDetailsData: PropTypes.object,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
