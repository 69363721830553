import React from 'react';
import PropTypes from 'prop-types';

// ~ Components
import { Tabs } from 'flowbite-react';
import CareersCurrentOpening from './CareersCurrentOpening';
import CareersFormData from './CareersFormData';

// ~ Services
import { connect } from 'react-redux';
import { makeSelectListCareers } from 'services/contact-us/contactUsSelector';

const CareersLayout = ({ listCareers = [] }) => {
    return (
        <div className='container mx-auto'>
            <Tabs.Group id='careers-tabs' style='underline'>
                <Tabs.Item title='Current opening'>
                    <div className='bg-white p-5 mt-5'>
                        <CareersCurrentOpening items={listCareers} />
                    </div>
                </Tabs.Item>
                <Tabs.Item title='Drop resume'>
                    <div className='bg-white p-5 mt-5'>
                        <CareersFormData />
                    </div>
                </Tabs.Item>
            </Tabs.Group>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        listCareers: makeSelectListCareers(state)
    };
};

CareersLayout.propTypes = {
    listCareers: PropTypes.array
};

export default connect(mapStateToProps)(CareersLayout);
