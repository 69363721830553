import camelCase from 'lodash/camelCase';

const camelize = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => camelize(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelize(obj[key])
            }),
            {}
        );
    }

    return obj;
};

const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
};

// ? Check if the object is empty
const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};
export { camelize, chunkArray, isEmptyObject };
