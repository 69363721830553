import React from 'react';
import PropTypes from 'prop-types';

const InstagramIcon = ({ height = 18, width = 18, className = '' }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 19.57 19.24'
            height={height}
            width={width}
            className={className}
        >
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <path
                        fill='#fff'
                        d='M19.57,10.46a40,40,0,0,1-.5,4.91,4.57,4.57,0,0,1-4.65,3.8c-3.15.09-6.29.1-9.44,0A4.85,4.85,0,0,1,.11,14.38C0,11.26,0,8.12.05,5A4.86,4.86,0,0,1,4.75.11C8,0,11.33,0,14.61.11A4.81,4.81,0,0,1,19.29,4.9c.09,1.85,0,3.7,0,5.55ZM1.78,10.28H1.59c.06,1.25.11,2.5.19,3.75a3.48,3.48,0,0,0,3.46,3.45c3,.07,5.95.07,8.92,0a3.42,3.42,0,0,0,3.43-3.39q.11-4.47,0-9a3.39,3.39,0,0,0-3.37-3.35c-3-.08-6-.09-9,0A3.42,3.42,0,0,0,1.79,5.25C1.72,6.93,1.78,8.61,1.78,10.28Z'
                    />
                    <path
                        fill='#fff'
                        d='M9.61,4.68a5,5,0,1,1-4.89,5A4.88,4.88,0,0,1,9.61,4.68Zm3.36,5a3.28,3.28,0,1,0-3.28,3.24A3.24,3.24,0,0,0,13,9.65Z'
                    />
                    <path
                        fill='#fff'
                        d='M14.89,5.57a1.1,1.1,0,0,1-1.12-1.14,1.15,1.15,0,1,1,2.29.05A1.09,1.09,0,0,1,14.89,5.57Z'
                    />
                </g>
            </g>
        </svg>
    );
};

InstagramIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string
};

export default InstagramIcon;
