import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';

import { Button, Select } from 'flowbite-react';

const headerSectionData = {
    title: 'Our Hospitals',
    desc: 'Ensuring the growth and strength of KPJ over the years through creative and innovative strategies has enabled the organisation to move forward',
    imagePath: ourHospitalsImagePath
};
const OurHospitalsHeaderSection = ({ items = [], onSearch = () => {} }) => {
    const [currentState, setCurrentState] = useState('');

    // ? Render items
    const renderItems = () => {
        if (items.length > 0) {
            return items.map((item) => <option key={item.id}>{item.name}</option>);
        } else {
            return <option>No data</option>;
        }
    };

    const handleOnClickSearch = () => {
        // ? Find id of state
        const state = items.find((item) => item.name === currentState);
        onSearch(state ? state.id : '');
    };

    const handleOnChangeValue = (e) => {
        setCurrentState(e.target.value);
    };
    return (
        <BaseHeaderWithContentSection
            title={headerSectionData.title}
            desc={headerSectionData.desc}
            imagePath={headerSectionData.imagePath}
        >
            <div className='flex gap-3 mt-7 md:flex-row flex-col'>
                <Select className='w-[360px]' onChange={handleOnChangeValue}>
                    <option key=''>Choose State</option>
                    {renderItems()}
                </Select>
                <Button
                    className='px-2 md:w-auto w-fit mx-auto'
                    color='dark'
                    onClick={handleOnClickSearch}
                >
                    Search
                </Button>
            </div>
        </BaseHeaderWithContentSection>
    );
};

OurHospitalsHeaderSection.propTypes = {
    items: PropTypes.array,
    onSearch: PropTypes.func
};

export default OurHospitalsHeaderSection;
