import { createSelector } from '@reduxjs/toolkit';
import healthTourismInitState from './healthTourismInitState';

const selectGlobal = (state) => {
    return state.healthTourism || healthTourismInitState;
};

const makeSelectHealthTourismLocation = createSelector(selectGlobal, (globalState) => {
    return globalState.healthTourismLocation;
});

const makeSelectHealthTourismTabsData = createSelector(selectGlobal, (globalState) => {
    return globalState.healthTourismTabsData;
});

const makeSelectHealthTourismMetaData = createSelector(selectGlobal, (globalState) => {
    return globalState.metaData;
});

export {
    makeSelectHealthTourismLocation,
    makeSelectHealthTourismTabsData,
    makeSelectHealthTourismMetaData
};
