import React from 'react';
import PropTypes from 'prop-types';

import BaseAccordion from 'components/BaseAccordion';

const MedicinesAccordion = ({ items = [] }) => {
    return <BaseAccordion items={items} alwaysOpen={true} />;
};

MedicinesAccordion.propTypes = {
    items: PropTypes.array
};

export default MedicinesAccordion;
