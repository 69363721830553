import HospitalServices from 'api/hospitals';

import { call, takeLatest, put, fork } from 'redux-saga/effects';

import hospitalActions from './hospitalActions';
import hospitalActionTypes from './hospitalActionTypes';

/**
 * TODO: Get list hospitals
 */

function* getListHospitalsHandler({ payload }) {
    try {
        const response = yield call(HospitalServices.getListHospitals, payload);
        if (+response.status === 1) {
            yield put(hospitalActions.getListHospitalsSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

function* watchHospitalSaga() {
    yield takeLatest(hospitalActionTypes.GET_LIST_HOSPITALS_REQUEST, getListHospitalsHandler);
}

export default function* rootHospitalSaga() {
    yield fork(watchHospitalSaga);
}
