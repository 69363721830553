import newsActionTypes from './newsActionTypes';

const getListNewsRequest = (payload) => ({
    type: newsActionTypes.GET_LIST_NEWS_REQUEST,
    payload
});

const getListNewsSuccess = (payload) => ({
    type: newsActionTypes.GET_LIST_NEWS_SUCCESS,
    payload
});

const getListNewsFailure = (error) => ({
    type: newsActionTypes.GET_LIST_NEWS_FAILURE,
    payload: error
});

// ? Get news details
const getNewsDetailsRequest = (payload) => ({
    type: newsActionTypes.GET_NEWS_DETAILS_REQUEST,
    payload
});

const getNewsDetailsSuccess = (payload) => ({
    type: newsActionTypes.GET_NEWS_DETAILS_SUCCESS,
    payload
});

const getNewsDetailsFailure = (error) => ({
    type: newsActionTypes.GET_NEWS_DETAILS_FAILURE,

    payload: error
});

export default {
    getListNewsRequest,
    getListNewsSuccess,
    getListNewsFailure,
    getNewsDetailsRequest,
    getNewsDetailsSuccess,
    getNewsDetailsFailure
};
