import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// ~ Components
import KlinikWaqafHeaderSection from 'layouts/klinik-waqaf/KlinikWaqafHeaderSection';
import KlinikWaqafLayout from 'layouts/klinik-waqaf/KlinikWaqafLayout';

// ~ Services
import { useCsrSlice } from 'services/csr';
import csrActions from 'services/csr/csrActions';
import { makeSelectKlinikWaqafData } from 'services/csr/csrSelector';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { isEmptyObject } from 'utils';

const KlinikWakaf = ({
    getKlinikWakaf = () => {},
    klinikWakafData = {},
    metaData = {},
    isAuthenticated = false
}) => {
    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useCsrSlice();
            getKlinikWakaf();
        }
    }, [isAuthenticated]);

    // ? Render Meta Data
    const renderMetaData = () => {
        if (!isEmptyObject(klinikWakafData)) {
            const metaKlinikData = klinikWakafData?.metaData;

            return (
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {metaKlinikData?.title !== '' ? metaKlinikData.title : metaData.title}
                    </title>
                    <meta
                        name='description'
                        content={
                            metaKlinikData?.description !== ''
                                ? metaKlinikData?.description
                                : metaData.description
                        }
                    />
                    <meta
                        name='keywords'
                        content={
                            metaKlinikData?.keywords !== ''
                                ? metaKlinikData.keywords
                                : metaData.keywords
                        }
                    />
                </Helmet>
            );
        }
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer'>
                <KlinikWaqafHeaderSection data={klinikWakafData?.headerSection} />
                <KlinikWaqafLayout
                    content={klinikWakafData?.content}
                    sections={klinikWakafData?.sections}
                />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getKlinikWakaf: () => dispatch(csrActions.getKlinikWaqafDataRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        klinikWakafData: makeSelectKlinikWaqafData(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

KlinikWakaf.propTypes = {
    getKlinikWakaf: PropTypes.func,
    klinikWakafData: PropTypes.object,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(KlinikWakaf);
