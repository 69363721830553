import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

const Covid19BookletLayout = ({ content, sections = [] }) => {
    // ? Render sections
    const renderSections = () => {
        if (sections.length > 0) {
            return sections.map((section) => {
                if (section.content.length > 0) {
                    return section.content.map((content, idx) => {
                        return (
                            <div key={idx} className='mt-2'>
                                {ReactHtmlParser(content)}
                            </div>
                        );
                    });
                }
            });
        }
    };
    return (
        <div className='container mx-auto mt-[24px]'>
            <div className='md:px-0 px-4'>
                {ReactHtmlParser(content)}
                {renderSections()}
            </div>
        </div>
    );
};

Covid19BookletLayout.propTypes = {
    content: PropTypes.string,
    sections: PropTypes.array
};

export default Covid19BookletLayout;
