import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// ? Assets
import LogoPath from 'assets/common/logo.svg';
import PhoneIcon from 'assets/icons/PhoneIcon';
import FacebookIcon from 'assets/icons/FacebookIcon';
import InstagramIcon from 'assets/icons/InstagramIcon';
import SearchIcon from 'assets/icons/SearchIcon';
import TiktokIcon from 'assets/icons/TiktokIcon';
import LinkedinIcon from 'assets/icons/LinkedinIcon';
import TwitterIcon from 'assets/icons/TwitterIcon';
import WhatsappIcon from 'assets/icons/WhatsappIcon';
import YoutubeIcon from 'assets/icons/YoutubeIcon';
import TelegramIcon from 'assets/icons/TelegramIcon';

const TheTopHeader = ({ data = {} }) => {
    const [isShow, setIsShow] = useState(false);
    const [params, setParams] = useState('');
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const handleOnDirectToHomepage = () => {
        window.location.href = '/';
    };

    // ? Render left section
    const renderLeftSection = () => {
        return (
            <div className='cursor-pointer w-[240px]' onClick={handleOnDirectToHomepage}>
                <img src={LogoPath} />
            </div>
        );
    };

    const handleOnClickSearch = () => {
        setIsShow((prevState) => !prevState);
        inputRef.current.focus();
    };

    const handleOnSubmit = () => {
        navigate(`/search/?q=${params}`);
    };

    const handleOnChangeValue = (e) => {
        setParams(e.target.value);
    };

    const handleOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleOnSubmit();
        }
    };

    // ? Render right section
    const renderRightSection = () => {
        return (
            <div className='flex gap-4 items-end'>
                <div className='flex items-center gap-1'>
                    <PhoneIcon />

                    <a href={`tel:${data.generalLine}`}>
                        <div className='text-body-4'>
                            <p>General Line</p>
                            <p className='cursor-pointer leading-3'>{data.generalLine}</p>
                        </div>
                    </a>
                </div>

                {/* Divider */}
                <div className='w-px h-[30px] bg-white' />

                {/* Social */}
                <div className='flex items-end gap-5'>
                    {
                    data.facebookLink
                    ?
                    <a href={data.facebookLink} target='_blank' rel='noreferrer'>
                        <FacebookIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.instagramLink
                    ?
                    <a href={data.instagramLink} target='_blank' rel='noreferrer'>
                        <InstagramIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.tiktokLink
                    ?
                    <a href={data.tiktokLink} target='_blank' rel='noreferrer'>
                        <TiktokIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.linkedInLink
                    ?
                    <a href={data.linkedInLink} target='_blank' rel='noreferrer'>
                        <LinkedinIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.twitterLink
                    ?
                    <a href={data.twitterLink} target='_blank' rel='noreferrer'>
                        <TwitterIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.whatsappLink
                    ?
                    <a href={data.whatsappLink} target='_blank' rel='noreferrer'>
                        <WhatsappIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.youtubeLink
                    ?
                    <a href={data.youtubeLink} target='_blank' rel='noreferrer'>
                        <YoutubeIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    data.telegramLink
                    ?
                    <a href={data.telegramLink} target='_blank' rel='noreferrer'>
                        <TelegramIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    <div onClick={handleOnClickSearch}>
                        <SearchIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className='flex items-center justify-between py-3 relative'>
            {renderLeftSection()}
            {renderRightSection()}

            {/* Search */}
            <div
                className={`z-[1000] absolute w-full bottom-0 transition-all duration-300 ease-out overflow-hidden translate-y-[100%] ${
                    isShow ? 'h-[100px]' : 'h-0'
                }`}
            >
                <input
                    ref={inputRef}
                    type='text'
                    className={`text search-input w-full h-[48px] text-black 
                    ${isShow ? 'opacity-100' : 'opacity-0'}} transition-all duration-300 ease-out`}
                    placeholder='Type here to search...'
                    onChange={(e) => handleOnChangeValue(e)}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                />
            </div>
        </div>
    );
};

TheTopHeader.propTypes = {
    data: PropTypes.object
};

export default TheTopHeader;
