import { camelize } from 'transformer/common';

/**
 * TODO: Get package details
 */

const getPackageDetailsHandler = (state, { payload }) => {
    return {
        ...state,
        packageDetailsData: camelize(payload)
    };
};

export { getPackageDetailsHandler };
