import React from 'react';
import PropTypes from 'prop-types';
// import './gallery.css';

const Gallery = ({ item }) => {
    var _col = item.item_per_row ?? 3;
    var class_column = 'plexcms-span-4';
    switch (_col) {
        case 1:
            class_column = 'plexcms-span-12';
            break;
        case 2:
            class_column = 'plexcms-span-6';
            break;
        case 4:
            class_column = 'plexcms-span-3';
            break;
        default:
            break;
    }
    const galleryItems = item.items.map((_item) => {
        var _img = `https://media.kpjhealth.com.my/${_item.imageThumb}`;
        return (
            <a key={_item.id} className='img-container'>
                <div
                    className={`img-item ${class_column}`}
                    style={{ display: 'inline-block', width: '33.33%' }}
                >
                    <div>
                        <img
                            data-lightbox='true'
                            className='lightbox-image'
                            src={_img}
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>
                </div>
            </a>
        );
    });

    return (
        <div className='lcms-gallery-contents'>
            <div id='lightbox' className='lightbox' style={{ display: 'none' }}>
                <img id='lightbox-image' className='lightbox-image' src='' alt='Lightbox Image' />
            </div>
            {galleryItems}
        </div>
    );
};

Gallery.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        image_thumb: PropTypes.string,
      })
    ),
    item_per_row: PropTypes.number,
  }),
};

export default Gallery;
