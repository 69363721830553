import httpClient from '../index';
import CSR_CONSTANTS from 'api/constants/csr';

/**
 * TODO: Get csr data
 */

const getCsrData = async () => {
    try {
        const getCsrDataResponse = await httpClient.get(CSR_CONSTANTS.GET_CSR_DATA);

        return getCsrDataResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get klinik waqaf data
 */

const getKlinikWaqafData = async () => {
    try {
        const getKlinikWaqafDataResponse = await httpClient.get(
            CSR_CONSTANTS.GET_KLINIK_WAQAF_DATA
        );

        return getKlinikWaqafDataResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get klinik waqaf data
 */

const getCovid19BookletData = async () => {
    try {
        const getCovid19BookletDataResponse = await httpClient.get(
            CSR_CONSTANTS.GET_COVID19_BOOKLET_DATA
        );

        return getCovid19BookletDataResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getCsrData,
    getKlinikWaqafData,
    getCovid19BookletData
};
