import { createSelector } from '@reduxjs/toolkit';
import newsInitState from './newsInitState';

const selectGlobal = (state) => {
    return state.news || newsInitState;
};

// ? Make selectors for news data
const makeSelectNewsData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.newsData;
});

// ? Make selectors for news details data
const makeSelectNewsDetailsData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.newsDetailsData;
});

export { makeSelectNewsData, makeSelectNewsDetailsData };
