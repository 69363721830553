import {
    covid19BookletDataTransformer,
    csrDataTransformer,
    klinikWaqafDataTransformer
} from 'transformer/csr';

/**
 * TODO: Get csr data handler
 */

const getCsrDataHandler = (state, { payload }) => {
    return {
        ...state,
        csrData: csrDataTransformer(payload)
    };
};

/**
 * TODO: Get klinik waqaf data handler
 */

const getKlinikWaqafDataHandler = (state, { payload }) => {
    return {
        ...state,
        klinikWaqafData: klinikWaqafDataTransformer(payload)
    };
};

/**
 * TODO: Get Covid19 Booklet data handler
 */

const getCovid19BookletDataHandler = (state, { payload }) => {
    return {
        ...state,
        covid19BookletData: covid19BookletDataTransformer(payload)
    };
};
export { getCsrDataHandler, getKlinikWaqafDataHandler, getCovid19BookletDataHandler };
