import { createSelector } from '@reduxjs/toolkit';
import contactUsInitState from './contactUsInitState';

const selectGlobal = (state) => {
    return state.contactUs || contactUsInitState;
};

const makeSelectContactUsLocation = createSelector(selectGlobal, (globalState) => {
    return globalState.contactUsLocation;
});

const makeSelectContactUsForm = createSelector(selectGlobal, (globalState) => {
    return globalState.contactUsForm;
});

const makeSelectContactUsSubmitSuccess = createSelector(selectGlobal, (globalState) => {
    return globalState.isSubmitSuccess;
});

const makeSelectCareCardProgramData = createSelector(selectGlobal, (globalState) => {
    return globalState.careCardProgramData;
});

// ? Selector list careers
const makeSelectListCareers = createSelector(selectGlobal, (globalState) => {
    return globalState.listCareers;
});

const makeSelectorIsFetching = createSelector(selectGlobal, (globalState) => {
    return globalState.isFetching;
});

export {
    makeSelectContactUsLocation,
    makeSelectContactUsForm,
    makeSelectContactUsSubmitSuccess,
    makeSelectCareCardProgramData,
    makeSelectListCareers,
    makeSelectorIsFetching
};
