import htttpClient from '../index';
import HOSPITAL_CONTANTS from 'api/constants/hospitals';

/**
 * TODO: Get list hospitals
 */

const getListHospitals = (params) => {
    try {
        const getListHospitalsResponse = htttpClient.post(HOSPITAL_CONTANTS.LIST_HOSPITALS, params);

        return getListHospitalsResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getListHospitals
};
