import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ourServicesActions from 'services/our-services/ourServicesActions';
import {
    makeSelectBariatricSurgery,
    makeSelectListServices
} from 'services/our-services/ourServicesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import OurServicesDetailsLayout from 'layouts/our-services/OurServicesDetailsLayout';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';
import { Helmet } from 'react-helmet-async';

const BariatricSurgery = ({
    getBariatricSurgery = () => {},
    bariatricSurgery = {},
    sections = [],
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            getBariatricSurgery();
        }
    }, [sections, isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{bariatricSurgery?.meta?.title}</title>
                <meta name='description' content={bariatricSurgery?.meta?.description} />
                <meta name='keywords' content={bariatricSurgery?.meta?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <OurServicesHeaderSection defaultCheckedTitle='bariatric-surgery' />
                <OurServicesDetailsLayout data={bariatricSurgery?.layout} />
                <OurServicesFooter currentIndex={6} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBariatricSurgery: () => dispatch(ourServicesActions.getBariatricSurgery())
    };
};

const mapStateToProps = (state) => {
    return {
        bariatricSurgery: makeSelectBariatricSurgery(state),
        sections: makeSelectListServices(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

BariatricSurgery.propTypes = {
    getBariatricSurgery: PropTypes.func,
    bariatricSurgery: PropTypes.object,
    sections: PropTypes.array,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(BariatricSurgery);
