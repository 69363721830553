const csrActionTypes = {
    GET_CSR_DATA_REQUEST: 'GET_CSR_DATA_REQUEST',
    GET_CSR_DATA_SUCCESS: 'GET_CSR_DATA_SUCCESS',
    GET_CSR_DATA_FAILURE: 'GET_CSR_DATA_FAILURE',

    GET_KLINIK_WAQAF_DATA_REQUEST: 'GET_KLINIK_WAQAF_DATA_REQUEST',
    GET_KLINIK_WAQAF_DATA_SUCCESS: 'GET_KLINIK_WAQAF_DATA_SUCCESS',
    GET_KLINIK_WAQAF_DATA_FAILURE: 'GET_KLINIK_WAQAF_DATA_FAILURE',

    GET_COVID19_BOOKLET_DATA_REQUEST: 'GET_COVID19_BOOKLET_DATA_REQUEST',
    GET_COVID19_BOOKLET_DATA_SUCCESS: 'GET_COVID19_BOOKLET_DATA_SUCCESS',
    GET_COVID19_BOOKLET_DATA_FAILURE: 'GET_COVID19_BOOKLET_DATA_FAILURE'
};

export default csrActionTypes;
