import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ourServicesActions from 'services/our-services/ourServicesActions';

import { Helmet } from 'react-helmet-async';
import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import OurServicesDetailsLayout from 'layouts/our-services/OurServicesDetailsLayout';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';

import {
    makeSelectLaboratoryServices,
    makeSelectListServices
} from 'services/our-services/ourServicesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const LaboratoryServices = ({
    getLaboratoryServices = () => {},
    laboratoryServices = {},
    sections = [],
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            getLaboratoryServices();
        }
    }, [sections, isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{laboratoryServices?.meta?.title}</title>
                <meta name='description' content={laboratoryServices?.meta?.description} />
                <meta name='keywords' content={laboratoryServices?.meta?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <OurServicesHeaderSection defaultCheckedTitle='laboratory-services' />
                <OurServicesDetailsLayout data={laboratoryServices?.layout} />
                <OurServicesFooter currentIndex={3} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLaboratoryServices: () => dispatch(ourServicesActions.getLaboratoryServices())
    };
};

const mapStateToProps = (state) => {
    return {
        laboratoryServices: makeSelectLaboratoryServices(state),
        sections: makeSelectListServices(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

LaboratoryServices.propTypes = {
    getLaboratoryServices: PropTypes.func,
    sections: PropTypes.array,
    laboratoryServices: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LaboratoryServices);
