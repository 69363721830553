import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { makeSelectSubHeaderData } from 'services/meta/metaSelectors';

const { REACT_APP_DOMAIN_MEDIA } = process.env;

const HomepageSubheader = ({ items = [] }) => {
    // ? Render single item
    const renderSingleItem = (color, logo, text, key, url) => {
        return (
            <a key={key} href={url}>
                <div
                    style={{ backgroundColor: color }}
                    className={`md:py-10 py-5 md:px-[80px] px-[12px] flex items-center md:gap-5 gap-2 justify-center md:flex-row flex-col h-full`}
                >
                    <img
                        src={`${REACT_APP_DOMAIN_MEDIA}${logo}`}
                        className='md:h-[42px] h-[24px]'
                        style={{
                            filter: 'invert(1)'
                        }}
                    />
                    <div className='text-white uppercase md:text-body-3 text-[10px] text-center'>
                        {ReactHtmlParser(text)}
                    </div>
                </div>
            </a>
        );
    };
    return (
        <div className='container grid grid-cols-3 mx-auto'>
            {items.map((item, index) => {
                return renderSingleItem(item.color, item.logo, item.content, index, item.url);
            })}
        </div>
    );
};

const makeStateToProps = (state) => {
    return {
        items: makeSelectSubHeaderData(state)
    };
};

HomepageSubheader.propTypes = {
    items: PropTypes.array
};

export default connect(makeStateToProps)(HomepageSubheader);
