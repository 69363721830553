import { createSelector } from '@reduxjs/toolkit';
import csrInitState from './csrInitState';

const selectGlobal = (state) => {
    return state.csr || csrInitState;
};

const makeSelectCsrData = createSelector(selectGlobal, (globalState) => {
    return globalState.csrData;
});

const makeSelectKlinikWaqafData = createSelector(selectGlobal, (globalState) => {
    return globalState.klinikWaqafData;
});

const makeSelectCovid19BookletData = createSelector(selectGlobal, (globalState) => {
    return globalState.covid19BookletData;
});
export { makeSelectCsrData, makeSelectKlinikWaqafData, makeSelectCovid19BookletData };
