import React from 'react';
import PropTypes from 'prop-types';

import ReactHtmlParser from 'react-html-parser';

import DefaultDoctorImagePath from 'assets/common/default-doctor.jpg';

import LocationDetailIcon from 'assets/icons/LocaltionDetailIcon';
import DoctorDetailIcon from 'assets/icons/DoctorDetailIcon';
import PhoneDetailIcon from 'assets/icons/PhoneDetailIcon';

const { REACT_APP_DOMAIN_MEDIA } = process.env;
const DoctorDetailsLayout = ({ data }) => {
    // ? Render avatar
    const renderAvatar = () => {
        if (data?.photo) {
            return (
                <img
                    src={`${REACT_APP_DOMAIN_MEDIA}${data?.photo}`}
                    alt={data.name}
                    className='h-full w-full object-top object-cover'
                />
            );
        } else {
            return (
                <img
                    src={DefaultDoctorImagePath}
                    alt={data.name}
                    className='h-full w-full object-top object-cover'
                />
            );
        }
    };

    // ? Render appointment
    const renderAppointment = () => {
        if (data?.bookingUrl) {
            return <iframe className='w-full h-[750px]' src={data?.bookingUrl}></iframe>;
        }
    };

    // ? Render medical school
    const renderMedicalSchool = () => {
        if (data?.medicalSchool) {
            return (
                <>
                    <div className='font-bold text-[18px] border-b-[2px] border-[#5775d3] w-fit mb-5'>
                        Medical School:{' '}
                    </div>
                    <div>{ReactHtmlParser(data?.medicalSchool)}</div>
                </>
            );
        }
    };

    // ? Render fellowships
    const renderFellowships = () => {
        if (data?.fellowships) {
            return (
                <>
                    <div className='font-bold text-[18px] mt-5 border-b-[2px] border-[#5775d3] w-fit mb-5'>
                        Education & Fellowship:{' '}
                    </div>
                    <div>{ReactHtmlParser(data?.fellowships)}</div>
                </>
            );
        }
    };

    // ? Render languages
    const renderLanguages = () => {
        if (data?.language) {
            return (
                <>
                    <div className='font-bold text-[18px]'>Language: </div>
                    <div>{ReactHtmlParser(data?.language)}</div>
                </>
            );
        }
    };
    return (
        <div className='container mx-auto mt-[24px]'>
            {/* Information */}
            <div className='flex md:flex-row flex-col md:px-0 px-4'>
                {/* Left section */}
                <div className='md:min-w-[62%] md:w-[62%] w-full bg-[#E4E4E8] text-black flex flex-col md:flex-row gap-5 px-5 py-5'>
                    {/* Avatar */}
                    <div className='w-[150px] h-[150px] rounded-[50%] min-w-[150px] overflow-hidden'>
                        {renderAvatar()}
                    </div>
                    {/* Info */}

                    <div>
                        <div className='md:text-[32px] text-[28px]'>
                            {ReactHtmlParser(data?.name)}
                        </div>
                        <div className='mt-2'>{ReactHtmlParser(data?.qualification)}</div>
                        <div className='mt-2 px-2 py-1 rounded-[20px] text-white bg-[#E97F76] w-fit'>
                            {ReactHtmlParser(data?.specialty)}
                        </div>
                        {data?.subspecialties !== '' && (
                            <div className='mt-2 px-1 py-1 w-fit text-[14px]'>
                                {ReactHtmlParser(data?.subspecialties)}
                            </div>
                        )}
                    </div>
                </div>

                {/* Right section */}
                <div className='bg-[#5775D3] pt-[20px] pb-[80px] pl-[20px] pr-[50px] text-[#ffffff] w-full'>
                    <div className='text-[22px] font-bold'>{data?.hospital?.name}</div>
                    <div className='text-[12px]'>KKLIU {data?.kkliuNo}</div>
                    <div className='mt-4'>
                        <div className='font-bold flex gap-4'>
                            <div className='w-6 h-6'>
                                <LocationDetailIcon />
                            </div>
                            <div>Location</div>
                        </div>
                        <div className='pl-10'>{ReactHtmlParser(data?.clinicLocation)}</div>
                        <div className='font-bold flex items-center gap-4 mt-1'>
                            <div className='w-6 h-6'>
                                <DoctorDetailIcon />
                            </div>
                            <div>
                                Type:
                                <span className='font-normal capitalize'> {data?.type} Doctor</span>
                            </div>
                        </div>
                        <div className='font-bold mt-2 flex gap-4'>
                            <div className='w-6 h-6'>
                                <PhoneDetailIcon />
                            </div>
                            <div>
                                Tel:
                                <span className='font-normal'> {ReactHtmlParser(data?.tel)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Description */}
            <div className='flex md:p-[6px] px-4 gap-[6px] md:flex-row flex-col'>
                <div className='md:min-w-[62%] md:w-[62%] w-full px-5 py-5 bg-white'>
                    <div className='mb-5'> {ReactHtmlParser(data?.description)}</div>

                    {renderMedicalSchool()}
                    {renderFellowships()}
                    {renderLanguages()}
                </div>

                <div className='md:w-[455px] w-full'>{renderAppointment()}</div>
            </div>
        </div>
    );
};

DoctorDetailsLayout.propTypes = {
    data: PropTypes.object
};

export default DoctorDetailsLayout;
