import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Modal } from 'flowbite-react';
import BaseResumeForm from './BaseResumeForm';
import { convertTimeUTCToLocal } from 'utils';

const { REACT_APP_DOMAIN_MEDIA } = process.env;

const BaseCareerCard = ({
    data = {},
    title = 'Job Title',
    isShowLocation = true,
    closingDate = 'Closing Date'
}) => {
    const [isShowDetails, setIsShowDetails] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);

    const handleOnShowDetails = () => {
        setIsShowDetails((prevState) => !prevState);
    };

    const renderDetails = () => {
        if (isShowDetails) {
            return (
                <>
                    <div className='md:px-8 px-4 mt-[-20px]'>
                        <div className='w-full h-px bg-[#edf2f7]'></div>
                        <div className='text-[12px] mt-3 font-bold'>Description</div>
                        <div className='mt-1'>
                            {data?.image !== undefined && data?.image.length > 0 && (
                                <>
                                    <img
                                        className='h-full mx-auto'
                                        src={`${REACT_APP_DOMAIN_MEDIA}${data?.image}`}
                                    />
                                </>
                            )}

                            {ReactHtmlParser(data?.details)}

                            {data?.company !== undefined && data?.company.length > 0 && (
                                <>
                                    <div className='font-bold'>Company</div>
                                    <div className='pb-4'>{data?.company}</div>
                                </>
                            )}

                            {data?.price !== undefined && data?.price > 0 && (
                                <>
                                    <div className='font-bold'>Price</div>
                                    <div className='pb-4'>
                                        {data?.price} {data?.currency}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            );
        }
    };

    const renderModal = () => {
        return (
            <Modal show={isShowModal} position='top-center'>
                <Modal.Body>
                    <BaseResumeForm
                        careerId={data?.id}
                        isShowIcon
                        onClose={() => setIsShowModal(false)}
                        onSuccess={() => setIsShowModal(false)}
                        jobTitle={data?.position}
                        className='career-card-modal'
                        classNameTitle='career-card-modal__title'
                    />
                </Modal.Body>
            </Modal>
        );
    };

    const handleOnApply = () => {
        if (isShowLocation) {
            setIsShowModal(true);
        } else {
            window.open(data?.url, '_blank');
        }
    };
    return (
        <div id='career-card'>
            <div
                className={`md:p-5 px-0 pb-0 grid md:grid-cols-${
                    isShowLocation ? '4' : '3'
                } grid-cols-1`}
            >
                {/* Title*/}
                <div className='py-2 px-4 border-r border-[#edf2f7]'>
                    <div className='text-[12px] capitalize '>{title}</div>
                    <div className='text-[16px]'>{ReactHtmlParser(data?.position)}</div>
                </div>

                {/* Locaation */}
                {isShowLocation && (
                    <div className='py-2 px-4 border-r border-[#edf2f7]'>
                        <div className='text-[12px] capitalize '>Location</div>
                        <div className='text-[16px]'>{data?.location}</div>
                    </div>
                )}

                {/* Closing date */}
                <div className='py-2 px-4 border-r border-[#edf2f7]'>
                    <div className='text-[12px] capitalize '>{closingDate}</div>
                    <div className='text-[16px]'>{convertTimeUTCToLocal(data?.closingDate)}</div>
                </div>

                {/* Actions */}
                <div className='flex py-2 px-4'>
                    <div className='careers__btn-details h-fit' onClick={handleOnShowDetails}>
                        {isShowDetails ? 'Hide Details' : 'Show Details'}
                    </div>
                    <div className='careers__btn-apply h-fit' onClick={handleOnApply}>
                        Apply
                    </div>
                </div>
            </div>

            {renderDetails()}

            {renderModal()}
        </div>
    );
};

BaseCareerCard.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    isShowLocation: PropTypes.bool,
    closingDate: PropTypes.string
};

export default BaseCareerCard;
