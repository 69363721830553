import httpClient from '../index';
import CONTACT_US_CONSTANTS from 'api/constants/contact-us';

/**
 * TODO: Get contactus data
 */

const getContactUsData = async () => {
    try {
        const getContactUsDataResponse = await httpClient.get(
            CONTACT_US_CONSTANTS.GET_CONTACT_US_DATA
        );

        return getContactUsDataResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Submit contactus form
 */

const submitContactUsForm = async (data) => {
    try {
        const submitContactUsFormResponse = await httpClient.post(
            CONTACT_US_CONSTANTS.SUBMIT_CONTACT_US_FORM,
            data
        );
        return submitContactUsFormResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get care card program
 */

const getCareCardProgram = async () => {
    try {
        const getCareCardProgramResponse = await httpClient.get(
            CONTACT_US_CONSTANTS.GET_CARE_CARD_PROGRAM
        );

        return getCareCardProgramResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get list careers
 */

const getListCareers = async (params) => {
    try {
        const getListCareersResponse = await httpClient.get(CONTACT_US_CONSTANTS.GET_LIST_CAREERS, {
            params
        });

        return getListCareersResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Submit resume
 */

const submitResume = async (data) => {
    const submitResumeResponse = await httpClient.post(CONTACT_US_CONSTANTS.SUBMIT_RESUME, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return submitResumeResponse;
};

/**
 * TODO: Submit applications form
 */

const submitApplicationsForm = async (data) => {
    const submitApplicationsFormResponse = await httpClient.post(
        CONTACT_US_CONSTANTS.SUBMIT_APPLICATIONS,
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );

    return submitApplicationsFormResponse;
};

export default {
    getContactUsData,
    submitContactUsForm,
    getCareCardProgram,
    getListCareers,
    submitResume,
    submitApplicationsForm
};
