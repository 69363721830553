import React from 'react';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';

// ~ Components
import TheHeader from './TheHeader';
import TheMobileHeader from './TheMobileHeader';
import TheFooter from './TheFooter';

const ContainerLayout = ({ children }) => {
    return (
        <div className='w-screen overflow-x-hidden'>
            <BrowserView>
                <TheHeader />
            </BrowserView>

            <MobileView>
                <TheMobileHeader />
            </MobileView>
            {children}
            <TheFooter />
        </div>
    );
};

ContainerLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default ContainerLayout;
