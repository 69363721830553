import React from 'react';
import PropTypes from 'prop-types';
// import './google_map.css';

const GoogleMap = ({ item }) => {
    const width = item.width
        ? typeof item.width === 'number'
            ? item.width
            : item.width + 'px'
        : '100%';
    const height = item.height
        ? typeof item.height === 'number'
            ? item.height
            : item.height + 'px'
        : '100%';
    return (
        <div key={item.id} className='lcms-content-container'>
            <div>
                <iframe
                    width={width}
                    height={height}
                    src={`https://maps.google.com/maps?q=${encodeURIComponent(
                        item.address
                    )}&height=615&z=14&w=100%&hl=en&ie=UTF8&iwloc=B&output=embed`}
                ></iframe>
            </div>
        </div>
    );
};

GoogleMap.propTypes = {
    item: PropTypes.shape({
        height: PropTypes.string,
        width: PropTypes.string,
        address: PropTypes.string,
        id: PropTypes.number
    })
};

export default GoogleMap;
