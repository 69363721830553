import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'flowbite-react';

import ReactHtmlParser from 'react-html-parser';

const BaseAccordion = ({ items = [], alwaysOpen = true }) => {
    // ? Render items
    const renderItems = () => {
        return items.map((item, index) => {
            return (
                <Accordion.Panel key={index} className='bg-main mt-2'>
                    <Accordion.Title>{item.title}</Accordion.Title>
                    <Accordion.Content className='bg-white'>
                        {ReactHtmlParser(item.content)}
                    </Accordion.Content>
                </Accordion.Panel>
            );
        });
    };
    return (
        <Accordion alwaysOpen={alwaysOpen} className='according' flush={false}>
            <Accordion.Panel className='bg-main mt-2 hidden'></Accordion.Panel>
            {renderItems()}
        </Accordion>
    );
};

BaseAccordion.propTypes = {
    items: PropTypes.array,
    alwaysOpen: PropTypes.bool
};

export default BaseAccordion;
