import csrServices from 'api/csr';

import { call, takeLatest, put, fork } from 'redux-saga/effects';
import csrActionTypes from './csrActionTypes';
import csrActions from './csrActions';

/**
 * TODO: Get csr data handler
 */

function* getCsrDataHandler() {
    try {
        const response = yield call(csrServices.getCsrData);

        if (+response.status === 1) {
            yield put(csrActions.getCsrDataSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get klinik waqaf data handler
 */

function* getKlinikWaqafDataHandler() {
    try {
        const response = yield call(csrServices.getKlinikWaqafData);

        if (+response.status === 1) {
            yield put(csrActions.getKlinikWaqafDataSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get Covid19 Booklet data handler
 */

function* getCovid19BookletDataHandler() {
    try {
        const response = yield call(csrServices.getCovid19BookletData);

        if (+response.status === 1) {
            yield put(csrActions.getCovid19BookletDataSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

function* watchCsrSaga() {
    yield takeLatest(csrActionTypes.GET_CSR_DATA_REQUEST, getCsrDataHandler);
    yield takeLatest(csrActionTypes.GET_KLINIK_WAQAF_DATA_REQUEST, getKlinikWaqafDataHandler);
    yield takeLatest(csrActionTypes.GET_COVID19_BOOKLET_DATA_REQUEST, getCovid19BookletDataHandler);
}

export default function* rootCsrSaga() {
    yield fork(watchCsrSaga);
}
