import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormData } from 'utils';
import { useForm } from 'react-hook-form';
// ~ Components
import { Label, TextInput, Select, Checkbox, FileInput } from 'flowbite-react';
import { IoMdClose } from 'react-icons/io';
import contactUsActions from 'services/contact-us/contactUsActions';
import {
    makeSelectContactUsSubmitSuccess,
    makeSelectorIsFetching
} from 'services/contact-us/contactUsSelector';

const options = ['Social Media', 'Website', 'Friends', 'Others'];
const BaseResumeForm = ({
    isShowIcon = false,
    onClose = () => {},
    submitResume = () => {},
    isSubmitSuccess = false,
    resetSubmitSuccess = () => {},
    isFetching = false,
    careerId = '',
    onSuccess = () => {},
    className = '',
    classNameTitle = '',
    jobTitle = ''
}) => {
    // ? Submit data
    const [submitData, setSubmitData] = useState({});
    const [file, setFile] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();

    // ? Functions
    const handleOnChangeInput = (e, key) => {
        if (key === 'is_subscription') {
            setSubmitData({ ...submitData, [key]: e.target.checked ? 1 : 0 });
        } else {
            setSubmitData({ ...submitData, [key]: e.target.value });
        }
    };

    const renderOtherInput = (label, key) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='other-input'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <TextInput
                    {...register(label, { required: true })}
                    onChange={(e) => handleOnChangeInput(e, key)}
                />
                {errors && errors[label] && (
                    <div className='text-[12px] text-normal text-red-500 -mt-1'>
                        *This field is required
                    </div>
                )}
            </div>
        );
    };

    // ? Render select input
    const renderSelectInput = (label, options = [], key) => {
        return (
            <div id='select'>
                <div className='mb-2 block'>
                    <Label htmlFor='countries' value={label} />
                </div>
                <Select id='option' required={true} onChange={(e) => handleOnChangeInput(e, key)}>
                    <option>Select Option</option>
                    {options.map((option, index) => {
                        if (option.length > 0) {
                            return <option key={index}>{option}</option>;
                        }
                    })}
                </Select>
            </div>
        );
    };

    const handleOnUploadFile = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    const handleOnSubmitButton = () => {
        let formData = getFormData(submitData);
        formData.append('resume', file);
        if (careerId.length > 0) {
            formData.append('career_id', careerId);
        }

        submitResume(formData);
    };

    const renderJobTitle = () => {
        if (jobTitle.length > 0) {
            return (
                <>
                    <div className='px-5 mt-7'>
                        <Label
                            htmlFor='countries'
                            value='Job Title'
                            className='text-[#000] font-[500]'
                        />
                        <div className='text-[18px] text-[#5675D3] font-bold'>{jobTitle}</div>
                    </div>{' '}
                    <div className='w-full h-[1px] bg-[#e2e8f0] my-3 mx-5'></div>
                </>
            );
        }
    };

    useEffect(() => {
        if (isSubmitSuccess) {
            setSubmitData({});
            setFile(null);
            document.getElementById('file').value = '';
            const elements = document.getElementsByTagName('input');
            for (var ii = 0; ii < elements.length; ii++) {
                elements[ii].value = '';
            }
            document.getElementById('select').getElementsByTagName('select')[0].value =
                'Select Option';

            onSuccess();

            setTimeout(() => {
                resetSubmitSuccess();
            }, 300);
        }
    }, [isSubmitSuccess]);

    return (
        <div className={className}>
            <div
                className={`text-white bg-[#5675D3] text-[18px] p-5 font-normal flex justify-between items-center ${classNameTitle}`}
            >
                <div>Application Form</div>
                {isShowIcon && <IoMdClose onClick={() => onClose()} className='cursor-pointer' />}
            </div>

            {renderJobTitle()}

            <div className='px-5 mt-3 flex flex-col gap-3'>
                {renderOtherInput('Job Title', 'job_title')}
                {renderOtherInput('Full Name', 'name')}
                {renderOtherInput('Email', 'email')}
                {renderOtherInput('Contact Number', 'phone_number')}
                {renderSelectInput('How did you hear about us', options, 'referral')}

                <div id='file-upload'>
                    <div className='block  mb-[6px]'>
                        <Label htmlFor='file'>
                            Upload your resume <span className='text-[#FF0000]'>*</span>
                        </Label>
                    </div>
                    <FileInput
                        id='file'
                        {...register('fileInput', { required: true })}
                        helperText='Note: PDF, DOCX, DOC formats are supported. File limit 5MB.'
                        accept='application/pdf,application/msword'
                        onChange={handleOnUploadFile}
                    />
                    {errors && errors['fileInput'] && (
                        <div className='text-[12px] text-normal text-red-500 -mt-1'>
                            *This field is required
                        </div>
                    )}
                </div>

                <div className='flex items-center gap-2'>
                    <Checkbox
                        id='remember'
                        onChange={(e) => handleOnChangeInput(e, 'is_subscription')}
                    />
                    <Label htmlFor='remember'>I want to receive newsletters from KPJ</Label>
                </div>

                <div className='w-fit ml-auto mt-2'>
                    <button
                        disabled={isFetching}
                        onClick={handleSubmit(handleOnSubmitButton)}
                        className='text-white bg-[#000] px-8 py-2 w-fit rounded-xl text-[14px] cursor-pointer ml-auto disabled:opacity-50'
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

BaseResumeForm.propTypes = {
    isShowIcon: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitResume: PropTypes.func,
    isSubmitSuccess: PropTypes.bool,
    resetSubmitSuccess: PropTypes.func,
    isFetching: PropTypes.bool,
    careerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSuccess: PropTypes.func,
    className: PropTypes.string,
    classNameTitle: PropTypes.string,
    jobTitle: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitResume: (payload) => dispatch(contactUsActions.submitResumeRequest(payload)),
        resetSubmitSuccess: () => dispatch(contactUsActions.resetIsSubmitSuccess())
    };
};

const mapStateToProps = (state) => {
    return {
        isSubmitSuccess: makeSelectContactUsSubmitSuccess(state),
        isFetching: makeSelectorIsFetching(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseResumeForm);
