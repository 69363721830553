const { REACT_APP_DOMAIN_MEDIA } = process.env;

const formatNumberWithCommas = (number) => {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
};

// ? Convert time UTC to local time with string month
const convertTimeUTCToLocal = (time) => {
    const date = new Date(time);
    const month = date.toLocaleString('en-us', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
};

// ? Convert object to form ddata
const getFormData = (object) =>
    Object.keys(object).reduce((formData, key) => {
        formData.append(key, object[key]);
        return formData;
    }, new FormData());

// ? Is empty object
const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// ? Transform content
const transformContent = (content) => {
    // ? Get all images tag in content
    const images = content.match(/<img[^>]+>/g);
    const aTags = content.match(/<a[^>]+>/g);

    if (images && images.length > 0) {
        images.forEach((image) => {
            if (!(image.includes('src="https') || image.includes('src="http'))) {
                const newImage = image.replace(/src="/g, `src="${REACT_APP_DOMAIN_MEDIA}`);
                content = content.replace(image, newImage);
            }
        });
    }

    if (aTags && aTags.length > 0) {
        aTags.forEach((aTag) => {
            if (!(aTag.includes('href="https') || aTag.includes('href="http'))) {
                const newATag = aTag.replace(/\/media\//g, `${REACT_APP_DOMAIN_MEDIA}/media/`);
                content = content.replace(aTag, newATag);
            }
        });
    }

    return content;
};

export {
    formatNumberWithCommas,
    convertTimeUTCToLocal,
    getFormData,
    isEmptyObject,
    transformContent
};
