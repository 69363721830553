import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

// ~ Utils
import { isEmptyObject, convertTimeUTCToLocal } from 'utils';
const { REACT_APP_DOMAIN_MEDIA } = process.env;

const NewsDetailsContentLayout = ({ data = {} }) => {
    // ? Render details content
    const renderDetailsContent = () => {
        if (!isEmptyObject(data)) {
            return (
                <>
                    <div className='text-[22px] text-main font-bold'>
                        {ReactHtmlParser(data?.title)}
                    </div>

                    <div className='my-4 font-bold'>{convertTimeUTCToLocal(data?.time)}</div>

                    {data?.image !== undefined && data?.image.length > 0 && (
                        <>
                            <img
                                className='object-contain md:object-scale-down'
                                src={`${REACT_APP_DOMAIN_MEDIA}${data?.image}`}
                            />
                        </>
                    )}
                    {ReactHtmlParser(data?.content)}
                </>
            );
        }
    };

    // ? Functions
    const handleOnFacebookButton = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?t=${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    const handleOnShareWhatsapp = () => {
        window.open(
            `https://api.whatsapp.com/send?text=${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    const handleOnShareLinkedin = () => {
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    const handleOnShareEmail = () => {
        window.open(
            `mailto:?subject=I wanted you to see this site&body=Check out this site ${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    // ? Render share sections
    const renderShareSections = () => {
        return (
            <div className='flex justify-end bg-white pr-4 pb-8'>
                <div
                    data-network='facebook'
                    className='button-social bg-[#3b5998]'
                    onClick={handleOnFacebookButton}
                >
                    <img src='https://platform-cdn.sharethis.com/img/facebook.svg' />
                </div>

                <div
                    data-network='whatsapp'
                    className='button-social bg-[#25d366]'
                    onClick={handleOnShareWhatsapp}
                >
                    <img src='https://platform-cdn.sharethis.com/img/whatsapp.svg' />
                </div>
                <div
                    data-network='linkedin'
                    className='button-social bg-[#0077b5]'
                    onClick={handleOnShareLinkedin}
                >
                    <img src='https://platform-cdn.sharethis.com/img/linkedin.svg' />
                </div>
                <div
                    data-network='email'
                    className='button-social bg-[#7d7d7d]'
                    onClick={handleOnShareEmail}
                >
                    <img src='	https://platform-cdn.sharethis.com/img/email.svg' />
                </div>
                <div data-network='sharethis' className='button-social bg-[#95D03A] st-btn st-last'>
                    <img src='https://platform-cdn.sharethis.com/img/sharethis.svg' />
                </div>
            </div>
        );
    };

    // ? Render back to news button
    const renderBackToNewsButton = () => {
        return (
            <div className='flex justify-start mt-8'>
                <Link to='/news' className='bg-main text-white py-2 px-4 rounded text-[14px]'>
                    Back to News
                </Link>
            </div>
        );
    };

    return (
        <div className='container mx-auto py-5 px-4 md:px-[80px]'>
            <div className='bg-white p-5'>
                {renderDetailsContent()}
                {renderShareSections()}

                {renderBackToNewsButton()}
            </div>
        </div>
    );
};

NewsDetailsContentLayout.propTypes = {
    data: PropTypes.object
};

export default NewsDetailsContentLayout;
