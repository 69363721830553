import React from 'react';

const PhoneDetailIcon = () => {
    return (
        <svg
            width={22}
            height={22}
            fill='#fff'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 22.1 21.32'
        >
            <g id='Layer_2'>
                <g id='Layer_1-2'>
                    <path d='M0,6.17c.08-.31.13-.62.23-.91A5.52,5.52,0,0,1,2.15,2.78,13.05,13.05,0,0,1,7.54.39,15.76,15.76,0,0,1,12.62.08a14.61,14.61,0,0,1,6.82,2.34A6.39,6.39,0,0,1,21.7,4.86a3.57,3.57,0,0,1,.23,2.72,1.81,1.81,0,0,1-1.82,1.29c-.89,0-1.78,0-2.67,0a1.82,1.82,0,0,1-1.78-2.38c0-.12.06-.24.1-.37a10.43,10.43,0,0,0-9.41,0c0,.14.07.28.1.42a1.79,1.79,0,0,1-1.6,2.32,29.9,29.9,0,0,1-3.14,0A1.73,1.73,0,0,1,.13,7.43C.08,7.22,0,7,0,6.82Z' />
                    <path d='M8.68,9.32c0-.29,0-.57,0-.84a.66.66,0,1,1,1.31,0c0,.28,0,.56,0,.86h2.12c0-.28,0-.56,0-.83a.68.68,0,0,1,.54-.71.66.66,0,0,1,.77.65c0,.29,0,.59,0,.9h1.2a.88.88,0,0,1,.71.3c.77.83,1.58,1.64,2.32,2.5a8.52,8.52,0,0,1,2,4.51,33.85,33.85,0,0,1,.11,4s0,0,0,.06a.67.67,0,0,1-.64.6H3a.66.66,0,0,1-.64-.73c0-1.13,0-2.27,0-3.41a8.21,8.21,0,0,1,1.48-4.24A22.58,22.58,0,0,1,5.44,11c.44-.49.91-1,1.36-1.44a.79.79,0,0,1,.64-.27ZM11,18.91A3.46,3.46,0,1,0,7.6,15.45,3.46,3.46,0,0,0,11,18.91Z' />
                    <path d='M13.18,15.46a2.13,2.13,0,1,1-2.12-2.13A2.13,2.13,0,0,1,13.18,15.46Z' />
                </g>
            </g>
        </svg>
    );
};

export default PhoneDetailIcon;
