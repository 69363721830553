const doctorActionTypes = {
    GET_LIST_DOCTORS_REQUEST: 'GET_LIST_DOCTORS_REQUEST',
    GET_LIST_DOCTORS_SUCCESS: 'GET_LIST_DOCTORS_SUCCESS',
    GET_LIST_DOCTORS_FAILURE: 'GET_LIST_DOCTORS_FAILURE',
    GET_DOCTOR_DETAILS_REQUEST: 'GET_DOCTOR_DETAILS_REQUEST',
    GET_DOCTOR_DETAILS_SUCCESS: 'GET_DOCTOR_DETAILS_SUCCESS',
    GET_DOCTOR_DETAILS_FAILURE: 'GET_DOCTOR_DETAILS_FAILURE'
};

export default doctorActionTypes;
