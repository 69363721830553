import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet-async';

import HomepageBanner from 'layouts/homepage/HomepageBanner';
import HomepageSubheader from 'layouts/homepage/HomepageSubheader';
import HomepageWhyUs from 'layouts/homepage/HomepageWhyUs';
import HomepageIntroduce from 'layouts/homepage/HomepageIntroduce';
import HomepageEvents from 'layouts/homepage/HomepageEvents';
import HomepageAwards from 'layouts/homepage/HomepageAwards';
import { makeSelectIsOnlyStatic, makeSelectMetaData } from 'services/meta/metaSelectors';

const Homepage = ({ metaData = {} }) => {
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta name='keywords' content={metaData?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <HomepageBanner />

                <HomepageSubheader />

                <HomepageWhyUs />

                {<HomepageIntroduce />}

                <HomepageEvents />

                <HomepageAwards />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isOnlyStatic: makeSelectIsOnlyStatic(state),
        metaData: makeSelectMetaData(state)
    };
};

Homepage.propTypes = {
    isOnlyStatic: PropTypes.any,
    metaData: PropTypes.any
};

export default connect(mapStateToProps)(Homepage);
