import healthTourismReducer from './healthTourismReducer';
import rootHealthTourismSaga from './healthTourismSagas';

import store from 'index';

const key = 'healthTourism';

export const useHealthTourismSlice = () => {
    store.injectReducer(key, healthTourismReducer);
    store.injectSaga(key, rootHealthTourismSaga);
};
