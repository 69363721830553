import { createSelector } from '@reduxjs/toolkit';
import ourBusinessesInitState from './ourBusinessesInitState';

const selectGlobal = (state) => {
    return state.ourBusinesses || ourBusinessesInitState;
};

// ? Make selectors for our businesses data
const makeSelectOurBusinessesData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.ourBusinessesData;
});

// ? Make selectors for list medicines
const makeSelectListMedicinesData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.medicinesData;
});

// ? Make selector for tender data
const makeSelectTenderData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.tenderData;
});

// ? Make selector for search data
const makeSelectSearchData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.searchData;
});

// ? Make selector for here for you data
const makeSelectHereForYouData = createSelector(selectGlobal, (globlaState) => {
    return globlaState.hereForYouData;
});

export {
    makeSelectOurBusinessesData,
    makeSelectListMedicinesData,
    makeSelectTenderData,
    makeSelectSearchData,
    makeSelectHereForYouData
};
