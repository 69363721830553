import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import BaseAccordion from 'components/BaseAccordion';

const HereForYouLayout = ({ content = '', sections = [] }) => {
    // ? Render sections
    const renderSections = () => {
        return sections.map((section, index) => {
            if (section.isHasItems) {
                return (
                    <div key={index} className='mb-5'>
                        {ReactHtmlParser(section.content)}

                        <div className='mt-5'>
                            <BaseAccordion items={section.items} />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div key={index} className='mb-5'>
                        {ReactHtmlParser(section.content)}
                    </div>
                );
            }
        });
    };
    return (
        <div className='container mx-auto py-5 px-4 md:px-[80px]'>
            <div className='p-5 bg-white'>
                {ReactHtmlParser(content)}

                {renderSections()}
            </div>
        </div>
    );
};

HereForYouLayout.propTypes = {
    content: PropTypes.string,
    sections: PropTypes.array
};

export default HereForYouLayout;
