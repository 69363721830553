import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'flowbite-react';

// ? Utils
import { formatNumberWithCommas } from 'utils';
import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_DOMAIN_MEDIA } = process.env;
const PackagesDetailsLayout = ({ data }) => {
    const [isShowModal, setIsShowModal] = useState(false);
    // ? Functions
    const handleOnFacebookButton = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?t=${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    const handleOnShareWhatsapp = () => {
        window.open(
            `https://api.whatsapp.com/send?text=${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    const handleOnShareLinkedin = () => {
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    const handleOnShareEmail = () => {
        window.open(
            `mailto:?subject=I wanted you to see this site&body=Check out this site ${window.location.href}`,
            '_blank',
            'width=600,height=400'
        );
    };

    // ? Render image
    const renderImage = () => {
        if (data?.image) {
            return (
                <img
                    src={`${REACT_APP_DOMAIN_MEDIA}/${data?.image}`}
                    className='w-full max-h-[400px] object-cover'
                    onClick={() => setIsShowModal(true)}
                />
            );
        }
    };

    const renderThumbnail = () => {
        if (data?.imageThumb) {
            return (
                <img
                    src={`${REACT_APP_DOMAIN_MEDIA}/${data?.imageThumb}`}
                    className='w-auto max-h-[80px] cursor-pointer object-cover'
                />
            );
        }
    };

    // ? Render modal
    const renderModal = () => {
        return (
            <Modal show={isShowModal} onClose={() => setIsShowModal(false)}>
                <Modal.Header></Modal.Header>

                <img
                    src={`${REACT_APP_DOMAIN_MEDIA}/${data?.image}`}
                    className='max-w-[700px] w-full object-contain'
                />
            </Modal>
        );
    };

    return (
        <div className='container mx-auto package-detail mt-[24px]'>
            <div className='p-5 flex md:flex-row flex-col-reverse gap-4 bg-white'>
                <div className='bg-[#f7fafc] p-5 md:w-[40%] w-full'>
                    {renderImage()}
                    <div className='flex gap-5 mt-4'>{renderThumbnail()}</div>
                </div>

                <div className='md:w-[60%] w-full flex flex-col gap-5 p-[2.5rem]'>
                    <div className='text-[2rem] font-[400]'>{data?.title}</div>
                    <div className='text-[22px] text-[rgba(31,96,158,1)] font-bold'>
                        MYR {formatNumberWithCommas(data?.price)}
                    </div>
                    <div className='h-px w-full bg-[rgba(85,85,85,0.6)]'></div>

                    <div>{ReactHtmlParser(data?.content)}</div>
                </div>
            </div>

            <div className='flex justify-end bg-white pr-4 pb-8'>
                <div
                    data-network='facebook'
                    className='button-social bg-[#3b5998]'
                    onClick={handleOnFacebookButton}
                >
                    <img src='https://platform-cdn.sharethis.com/img/facebook.svg' />
                </div>

                <div
                    data-network='whatsapp'
                    className='button-social bg-[#25d366]'
                    onClick={handleOnShareWhatsapp}
                >
                    <img src='https://platform-cdn.sharethis.com/img/whatsapp.svg' />
                </div>
                <div
                    data-network='linkedin'
                    className='button-social bg-[#0077b5]'
                    onClick={handleOnShareLinkedin}
                >
                    <img src='https://platform-cdn.sharethis.com/img/linkedin.svg' />
                </div>
                <div
                    data-network='email'
                    className='button-social bg-[#7d7d7d]'
                    onClick={handleOnShareEmail}
                >
                    <img src='	https://platform-cdn.sharethis.com/img/email.svg' />
                </div>
                <div data-network='sharethis' className='button-social bg-[#95D03A] st-btn st-last'>
                    <img src='https://platform-cdn.sharethis.com/img/sharethis.svg' />
                </div>
            </div>

            {renderModal()}
        </div>
    );
};

PackagesDetailsLayout.propTypes = {
    data: PropTypes.object
};

export default PackagesDetailsLayout;
