import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// ? Components
import CsrHeaderSection from 'layouts/csr/CsrHeaderSection';
import CsrLayout from 'layouts/csr/CsrLayout';

// ? Services
import { useCsrSlice } from 'services/csr';
import { connect } from 'react-redux';
import csrActions from 'services/csr/csrActions';
import { makeSelectCsrData } from 'services/csr/csrSelector';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { isEmptyObject } from 'utils';

const CsrPage = ({
    getCsrData = () => {},
    csrData = {},
    metaData = {},
    isAuthenticated = false
}) => {
    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useCsrSlice();
            getCsrData();
        }
    }, [isAuthenticated]);

    // ? Render Meta Data
    const renderMetaData = () => {
        if (!isEmptyObject(csrData)) {
            const metaDataCsr = csrData?.metaData;

            return (
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>{metaDataCsr?.title !== '' ? metaDataCsr.title : metaData.title}</title>
                    <meta
                        name='description'
                        content={
                            metaDataCsr?.description !== ''
                                ? metaDataCsr?.description
                                : metaData.description
                        }
                    />
                    <meta
                        name='keywords'
                        content={
                            metaDataCsr?.keywords !== '' ? metaDataCsr.keywords : metaData.keywords
                        }
                    />
                </Helmet>
            );
        }
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer'>
                <CsrHeaderSection data={csrData?.headerSection} />
                <CsrLayout items={csrData?.sections} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCsrData: () => dispatch(csrActions.getCsrDataRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        csrData: makeSelectCsrData(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

CsrPage.propTypes = {
    getCsrData: PropTypes.func,
    csrData: PropTypes.object,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(CsrPage);
