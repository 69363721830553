import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import { Select, Button, TextInput } from 'flowbite-react';
import SubheaderImagePath from 'assets/common/subheader-doctors.png';

import metaActions from 'services/meta/metaActions';
import { makeSelectListHospitals } from 'services/hospitals/hospitalSelector';
import { makeSelectSpecialities } from 'services/meta/metaSelectors';

import ReactHtmlParser from 'react-html-parser';

const findDoctorSectionData = {
    title: 'Our Doctors',
    desc: 'Our find a doctor tool assists you in choosing from our diverse pool of health specialists Discover better health and wellness by using our doctor ratings and reviews to make your choice. We are dedicated to being the preferred provider of care, with innovative use of technology, experienced doctors and well-trained staff who collaborate to offer the best diagnosis and treatment plans.'
};

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FindDoctorHeaderSection = ({
    hospitals = [],
    specialties = [],
    onSearch = () => {},
    getAllSpecialities = () => {}
}) => {
    let query = useQuery();

    const hospitalIdInit = query.get('hospital_id');
    const specialtyIdInit = query.get('specialty_id');
    const keywordInit = query.get('keyword');
    // ? State
    const [hospitalId, setHospitalId] = useState(hospitalIdInit ? hospitalIdInit : 0);
    const [specialtyId, setSpecialtyId] = useState(specialtyIdInit ? specialtyIdInit : 0);
    const [keyword, setKeyword] = useState(keywordInit ? keywordInit : '');
    // ? Render options
    const renderOptions = (items) => {
        if (items.length > 0) {
            return items.map((item, idx) => (
                <option key={idx} value={item.id}>
                    {ReactHtmlParser(item.name)}
                </option>
            ));
        } else {
            return <option>No data</option>;
        }
    };

    const renderHospitalOptions = (items) => {
        if (items.length > 0) {
            return items.map((item, idx) => {
                // const hiddenIDs = [29, 41, 44];
                if (
                    item.id != 4 &&
                    item.id != 10 &&
                    item.id != 40 &&
                    item.id != 29 &&
                    item.id != 41 &&
                    item.id != 44
                ) {
                    return (
                        <option key={idx} value={item.id}>
                            {ReactHtmlParser(item.name)}
                        </option>
                    );
                }
            });
        } else {
            return <option>No data</option>;
        }
    };

    const handleOnSelectHospital = (e) => {
        getAllSpecialities({
            hospital_id: e.target.value > 0 ? e.target.value : ''
        });
        setHospitalId(e.target.value);
    };

    const handleOnSelectSpecialty = (e) => {
        setSpecialtyId(e.target.value);
    };

    const handleOnChangeInput = (e) => {
        setKeyword(e.target.value);
    };

    const handleOnApplyFilter = () => {
        const queryParams = {
            hospital_id: hospitalId,
            specialty_id: specialtyId,
            keyword
        };
        onSearch(queryParams);
    };

    useEffect(() => {
        getAllSpecialities({
            hospital_id: hospitalIdInit ? hospitalIdInit : ''
        });
    }, [hospitalIdInit]);

    return (
        <BaseHeaderWithContentSection
            title={findDoctorSectionData.title}
            desc={findDoctorSectionData.desc}
            imagePath={SubheaderImagePath}
            className='md:h-[320px] h-fit'
        >
            <div className='flex gap-3 mt-7 md:flex-row flex-col'>
                <Select
                    className='md:w-[200px] w-full filter-header'
                    value={hospitalId}
                    onChange={handleOnSelectHospital}
                >
                    <option value={0}>Any hospital</option>
                    {renderHospitalOptions(hospitals)}
                </Select>
                <Select
                    className='md:w-[200px] w-full filter-header'
                    value={specialtyId}
                    onChange={handleOnSelectSpecialty}
                >
                    <option value={0}>Any specialty</option>
                    {renderOptions(specialties)}
                </Select>

                <TextInput
                    placeholder='Doctor’s Name or Specialty or Subspecialties'
                    value={keyword}
                    onChange={handleOnChangeInput}
                    className='w-[300px] filter-header text-[14px]'
                />
                <Button className='px-2' color='dark' onClick={handleOnApplyFilter}>
                    Search
                </Button>
            </div>
        </BaseHeaderWithContentSection>
    );
};

const mapStateToProps = (state) => {
    return {
        hospitals: makeSelectListHospitals(state),
        specialties: makeSelectSpecialities(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSpecialities: (payload) => dispatch(metaActions.getAllSpecialities(payload))
    };
};

FindDoctorHeaderSection.propTypes = {
    hospitals: PropTypes.array,
    specialties: PropTypes.array,
    onSearch: PropTypes.func,
    getAllSpecialities: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FindDoctorHeaderSection);
