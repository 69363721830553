import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
// import './tab.css';

const Tab = ({ item }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div id='tabcss' className='plexcms-container'>
            <div className='lcms-span-container'>
                <div className='lcms-content-container'>
                    <div className='lcms-sections' id='link-tabs'>
                        <div className='plexcms-container'>
                            <div className='lcms-span-container'>
                                <div className='lcms-content-container'>
                                    <div className='lcms-richtext-contents centered-title'>
                                        {item.items.map((_item, index) => {
                                            const tabClass = index === activeTab ? 'current' : '';
                                            return (
                                                <a
                                                key={_item.id}
                                                className={`taby-title ${tabClass}`}
                                                href='javascript:void(0)'
                                                data-href={`#tab-${index + 1}`}
                                                onClick={() => handleTabClick(index)}
                                            >
                                                {_item.title}
   
                                            </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='plexcms-container'>
                        <div className='lcms-span-container'>
                            <div className='lcms-content-container'>
                                {item.items.map((_item, index) => {
                                    const tabSectionClass =
                                        index === activeTab ? 'current' : 'hidden';
                                    return (
                                        <div
                                            key={_item.id}
                                            id={`tab-${index + 1}`}
                                            className={`tab_container lcms-span-container lg:w-full w-full sm:w-full md:w-full lg:inherit inherit sm:inherit md:inherit ${tabSectionClass}`}
                                        >
                                            <div className='lcms-content-container _sub_container'>
                                                <div className='lcms-sections'>
                                                    <div className='plexcms-container'>
                                                        <div className='lcms-span-container'>
                                                            <div className='lcms-content-container _fix_width'>
                                                                <div
                                                                    className='lcms-sections'
                                                                    id='about-content'
                                                                >
                                                                    {ReactHtmlParser(_item.content)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Tab.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        image_thumb: PropTypes.string,
        price: PropTypes.number,
      })
    ),
    item_per_row: PropTypes.number,
    id: PropTypes.number,
  }),
};

export default Tab;
