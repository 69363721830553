import React from 'react';
import PropTypes from 'prop-types';

// ? Components
import HealthTourismHeaderImage from 'assets/common/subheader-health.png';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';

const KlinikWaqafHeaderSection = ({ data = {} }) => {
    return (
        <BaseHeaderWithContentSection title={data?.title} imagePath={HealthTourismHeaderImage} />
    );
};

KlinikWaqafHeaderSection.propTypes = {
    data: PropTypes.object
};

export default KlinikWaqafHeaderSection;
