import { createSelector } from '@reduxjs/toolkit';
import metaInitState from './metaInitState';

/***
 * TODO: Global selector for user profile
 */

const selectGlobal = (state) => {
    return state.meta || metaInitState;
};

// ? Make selector the header data
const makeSelectHeaderData = createSelector(selectGlobal, (globalState) => {
    return globalState.headerData;
});

// ? Make selector the banners
const makeSelectBanners = createSelector(selectGlobal, (globalState) => {
    return globalState.banners;
});

// ? Make selector the sub header data
const makeSelectSubHeaderData = createSelector(selectGlobal, (globalState) => {
    return globalState.subHeaderData;
});

// ? Make selector the is only static
const makeSelectIsOnlyStatic = createSelector(selectGlobal, (globalState) => {
    return globalState.isOnlyStatic;
});

// ? Make selector the events
const makeSelectEvents = createSelector(selectGlobal, (globalState) => {
    return globalState.events;
});

// ? Make selector the mobile events
const makeSelectMobileEvents = createSelector(selectGlobal, (globalState) => {
    return globalState.mobileEvents;
});

// ? Make selector the footer
const makeSelectFooterData = createSelector(selectGlobal, (globalState) => {
    return globalState.footerData;
});

// ? Make selector the states
const makeSelectStates = createSelector(selectGlobal, (globalState) => {
    return globalState.states;
});

// ? Make selector the meta data
const makeSelectMetaData = createSelector(selectGlobal, (globalState) => {
    return globalState.metaData;
});

// ? Make selector the Specialties
const makeSelectSpecialities = createSelector(selectGlobal, (globalState) => {
    return globalState.specialties;
});

// ? Make selector page data
const makeSelectPageData = createSelector(selectGlobal, (globalState) => {
    return globalState.pageData;
});

// ? Make selector the is authenticated
const makeSelectIsAuthenticated = createSelector(selectGlobal, (globalState) => {
    return globalState.isAuthenticated;
});

export {
    makeSelectHeaderData,
    makeSelectBanners,
    makeSelectSubHeaderData,
    makeSelectIsOnlyStatic,
    makeSelectEvents,
    makeSelectMobileEvents,
    makeSelectFooterData,
    makeSelectStates,
    makeSelectMetaData,
    makeSelectSpecialities,
    makeSelectPageData,
    makeSelectIsAuthenticated
};
