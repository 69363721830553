import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

// ~ Services
import { connect } from 'react-redux';
import { makeSelectOurServicesSubheader } from 'services/our-services/ourServicesSelector';

import OurSerivesFooterImagePath from 'assets/common/services-footer.png';

const OurServicesFooter = ({ subheaderData = {}, currentIndex = 0 }) => {
    const navigate = useNavigate();

    const handleOnClickLeft = () => {
        if (currentIndex > 0) {
            navigate(`/${subheaderData.options[currentIndex - 1].slug}`);
        }
    };

    const handleOnClickRight = () => {
        if (currentIndex < subheaderData.options.length - 1) {
            navigate(`/${subheaderData.options[currentIndex + 1].slug}`);
        }
    };

    // ? Render left text
    const renderLeftText = () => {
        if (subheaderData.options && subheaderData.options.length > 0) {
            if (currentIndex > 0) {
                const options = subheaderData.options;
                return options[currentIndex - 1].title;
            }
        } else {
            return '';
        }
    };

    const renderTextRight = () => {
        if (subheaderData.options && subheaderData.options.length > 0) {
            const options = subheaderData.options;
            if (currentIndex < options.length - 1) {
                return options[currentIndex + 1].title;
            } else {
                return '';
            }
        }
    };

    const renderIconLeft = () => {
        if (subheaderData.options && subheaderData.options.length > 0) {
            if (currentIndex > 0) {
                return <FiArrowLeft className='text-[18px]' />;
            }
        }
    };

    const renderIconRight = () => {
        if (subheaderData.options && subheaderData.options.length > 0) {
            const options = subheaderData.options;
            if (currentIndex < options.length - 1) {
                return <FiArrowRight className='text-[18px] mt-[2px]' />;
            }
        }
    };
    return (
        <div className='container mx-auto mt-5'>
            <div className='h-[120px] w-full flex'>
                <div
                    className={`md:min-w-[519.24px] md:w-[519.24px] w-[50%] bg-[rgb(233,128,118)] flex items-center ${
                        renderLeftText() === '' || !renderLeftText()
                            ? 'cursor-not-allowed opacity-70'
                            : ''
                    }`}
                >
                    <div onClick={handleOnClickLeft} className='flex gap-3 items-center text-white'>
                        <div className='md:pl-5 pl-2'>{renderIconLeft()}</div>
                        <div className='md:text-[18px] text-[16px] cursor-pointer'>
                            {renderLeftText()}
                        </div>
                    </div>
                </div>

                <img
                    src={OurSerivesFooterImagePath}
                    className='w-[178.14px] min-w-[178.14px] md:block hidden'
                />
                <div
                    className={`md:w-[260.36px] w-[50%] bg-[#5775d3] flex items-center justify-end ${
                        renderTextRight() === '' ? 'cursor-not-allowed opacity-70' : ''
                    }`}
                >
                    <div
                        onClick={handleOnClickRight}
                        className='flex gap-3 items-center text-white'
                    >
                        <div className={`md:text-[18px] text-[16px] cursor-pointer pl-4 md:pl-2`}>
                            {renderTextRight()}
                        </div>
                        <div className='md:pr-5 pr-2'>{renderIconRight()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        subheaderData: makeSelectOurServicesSubheader(state)
    };
};

OurServicesFooter.propTypes = {
    currentIndex: PropTypes.number,
    subheaderData: PropTypes.object
};

export default connect(mapStateToProps)(OurServicesFooter);
