import healthTourismInitState from './healthTourismInitState';
import healthTourismActionTypes from './healthTourismActionTypes';

import { getHealthTourismHandler } from './healthTourismReducerHandler';

const healthTourismReducer = (state = healthTourismInitState, action) => {
    switch (action.type) {
        case healthTourismActionTypes.GET_HEALTH_TOURISM_PAGE_SUCCESS:
            return getHealthTourismHandler(state, action);
        default:
            return state;
    }
};

export default healthTourismReducer;
