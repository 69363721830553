import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// ~ Components
import CareCardProgramHeaderSection from 'layouts/card-care-program/CardCareProgramHeaderSection';
import CardCareProgramLayout from 'layouts/card-care-program/CardCareProgramLayout';
// ~ Services
import { makeSelectMetaData } from 'services/meta/metaSelectors';
import { useContactUsSlice } from 'services/contact-us';
import contactUsActions from 'services/contact-us/contactUsActions';
import { makeSelectCareCardProgramData } from 'services/contact-us/contactUsSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { isEmptyObject } from 'utils';

const CareCardProgram = ({
    getCareCardProgram = () => {},
    careCardProgramData = {},
    metaData = {},
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            useContactUsSlice();
            getCareCardProgram();
        }
    }, [isAuthenticated]);

    // ? Render metadata
    const renderMetaData = () => {
        if (!isEmptyObject(careCardProgramData)) {
            const metaDataCareCardProgram = careCardProgramData?.metaData;
            return (
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {metaDataCareCardProgram?.title !== ''
                            ? metaDataCareCardProgram.title
                            : metaData.title}
                    </title>
                    <meta
                        name='description'
                        content={
                            metaDataCareCardProgram?.description !== ''
                                ? metaDataCareCardProgram?.description
                                : metaData.description
                        }
                    />
                    <meta
                        name='keywords'
                        content={
                            metaDataCareCardProgram?.keywords !== ''
                                ? metaDataCareCardProgram.keywords
                                : metaData.keywords
                        }
                    />
                </Helmet>
            );
        }
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer'>
                <CareCardProgramHeaderSection data={careCardProgramData?.headerSection} />
                <CardCareProgramLayout sections={careCardProgramData?.sections} />
            </div>
        </>
    );
};

const mapDisaptchToProps = (dispatch) => {
    return {
        getCareCardProgram: () => dispatch(contactUsActions.getCareCardProgramRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        careCardProgramData: makeSelectCareCardProgramData(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

CareCardProgram.propTypes = {
    getCareCardProgram: PropTypes.func,
    careCardProgramData: PropTypes.object,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDisaptchToProps)(CareCardProgram);
