import { listDoctorsDataTransformer } from 'transformer/doctor';
import { camelize } from 'transformer/common';
/**
 * TODO: Get list doctors
 */

const getListDoctorsHandler = (state, { payload }) => {
    return {
        ...state,
        doctors: listDoctorsDataTransformer(payload?.items),
        pagination: camelize(payload?.paginator)
    };
};

/**
 * TODO: Get doctor details
 */

const getDoctorDetailsHandler = (state, { payload }) => {
    return {
        ...state,
        doctorDetailsData: camelize(payload)
    };
};

export { getListDoctorsHandler, getDoctorDetailsHandler };
