import csrActionTypes from './csrActionTypes';

const getCsrDataRequest = () => ({
    type: csrActionTypes.GET_CSR_DATA_REQUEST
});

const getCsrDataSuccess = (payload) => ({
    type: csrActionTypes.GET_CSR_DATA_SUCCESS,
    payload
});

const getCsrDataFailure = (error) => ({
    type: csrActionTypes.GET_CSR_DATA_FAILURE,
    payload: error
});

// ? Get klinik waqaf data
const getKlinikWaqafDataRequest = () => ({
    type: csrActionTypes.GET_KLINIK_WAQAF_DATA_REQUEST
});

const getKlinikWaqafDataSuccess = (payload) => ({
    type: csrActionTypes.GET_KLINIK_WAQAF_DATA_SUCCESS,
    payload
});

const getKlinikWaqafDataFailure = (error) => ({
    type: csrActionTypes.GET_KLINIK_WAQAF_DATA_FAILURE,
    payload: error
});

// ? Get covid19 booklet data
const getCovid19BookletDataRequest = () => ({
    type: csrActionTypes.GET_COVID19_BOOKLET_DATA_REQUEST
});

const getCovid19BookletDataSuccess = (payload) => ({
    type: csrActionTypes.GET_COVID19_BOOKLET_DATA_SUCCESS,
    payload
});

const getCovid19BookletDataFailure = (error) => ({
    type: csrActionTypes.GET_COVID19_BOOKLET_DATA_FAILURE,
    payload: error
});

export default {
    getCsrDataRequest,
    getCsrDataSuccess,
    getCsrDataFailure,
    getKlinikWaqafDataRequest,
    getKlinikWaqafDataSuccess,
    getKlinikWaqafDataFailure,
    getCovid19BookletDataRequest,
    getCovid19BookletDataSuccess,
    getCovid19BookletDataFailure
};
