import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// import './list_doctor.css';

// ~ slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    className: '',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoPlay: true
};

const Doctor = ({ item }) => {
    var doctorItems;
    doctorItems = item.items.map((_item) => {
        var genderClass = _item.gender.toLowerCase() === 'male' ? 'mdoc' : 'fdoc';
        var hideAppt = '';
        var specialtiesToHide = ['clinical radiology', 'anaesthesiology', 'accident & emergency'];
        if (specialtiesToHide.includes(_item.specialty.toLowerCase())) {
            hideAppt = 'hidden';
        }
        return (
            <div key={_item.id} className='doctor_section item'>
                <div
                    className={`mt-3 _doctor_image ${genderClass}`}
                    style={_item.image ? { backgroundImage: `url(${_item.image})` } : null}
                ></div>
                <div className='mt-3 _doctor_name_section'>
                    <div className='name'>{_item.name}</div>
                </div>
                <div className='_qualification_section'>{ReactHtmlParser(_item.qualification)}</div>
                <div>
                    <div>{ReactHtmlParser(_item.specialty)}</div>
                </div>
                <div className={`mt-3 ${hideAppt}`}>
                    <a href={`/doctor/${_item.slug}`}>Book an appointment</a>
                </div>
            </div>
        );
    });
    return (
        <div className='lcms-content-container _container'>
            <div className='lcms-sections' id='tab-1'>
                <div className='plexcms-container'>
                    <div className='lcms-span-container lg:w-full sm:w-full md:w-full lg:inherit sm:inherit md:inherit'>
                        <div className='lcms-content-container _sub_container'>
                            <div
                                className={`hcms-services-list-contents row owl-carousel owl-carousel-${item.id} d-md-none m-0`}
                            >
                                <Slider {...settings}>{doctorItems}</Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Doctor.propTypes = {
    item: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
                slug: PropTypes.string,
                content: PropTypes.string,
                image: PropTypes.string,
                image_thumb: PropTypes.string,
                price: PropTypes.number
            })
        ),
        item_per_row: PropTypes.number,
        id: PropTypes.number
    })
};

export default Doctor;
