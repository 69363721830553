import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';
import BaseDoctorCard from 'components/BaseDoctorCard';
import ReactPaginate from 'react-paginate';
import {
    makeSelectListDoctors,
    makeSelectListDoctorsPaginator
} from 'services/doctor/doctorSelector';

const FindDoctorLayout = ({ doctors = [], pagination = {}, onChange = () => {} }) => {
    // ? Render doctor cards
    const renderDoctorCards = () => {
        return doctors.map((doctor, index) => {
            return (
                <BaseDoctorCard
                    key={index}
                    avatar={doctor.photo}
                    certificates={doctor.qualification}
                    specialty={doctor.specialty}
                    subspecialties={doctor.subspecialties}
                    name={doctor.name}
                    position={doctor.type}
                    desc={doctor.kkliuNo}
                    phone={doctor.tel}
                    slug={doctor.slug}
                />
            );
        });
    };

    const handleOnChangePagination = (data) => {
        onChange(data.selected + 1);
    };

    // ? Render pagination
    const renderPagination = () => {
        return (
            <>
                <BrowserView>
                    <div className='pagination'>
                        <ReactPaginate
                            pageCount={pagination?.totalPages || 1}
                            pageRangeDisplayed={2}
                            className='flex justify-center'
                            pageClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            pageLinkClassName='w-full h-full flex justify-center items-center'
                            previousClassName='ml-0 rounded-l-lg border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            previousLinkClassName='w-full h-full flex justify-center items-center py-2 px-3 '
                            nextClassName='rounded-r-lg border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            nextLinkClassName='w-full h-full flex justify-center items-center py-2 px-3 '
                            breakClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            breakLinkClassName='w-full h-full flex justify-center items-center'
                            activeClassName='bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                            marginPagesDisplayed={1}
                            onPageChange={handleOnChangePagination}
                        />
                    </div>
                </BrowserView>

                <MobileView>
                    <div className='pagination pagination-mobile'>
                        <ReactPaginate
                            pageCount={pagination?.totalPages || 1}
                            pageRangeDisplayed={1}
                            className='flex justify-center'
                            pageClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            pageLinkClassName='w-full h-full flex justify-center items-center'
                            previousClassName='hidden'
                            previousLinkClassName='hidden'
                            nextClassName='hidden'
                            nextLinkClassName='hidden'
                            breakClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            breakLinkClassName='w-full h-full flex justify-center items-center'
                            activeClassName='bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                            marginPagesDisplayed={1.5}
                            onPageChange={handleOnChangePagination}
                        />
                    </div>
                </MobileView>
            </>
        );
    };
    return (
        <div>
            <div className='grid md:grid-cols-3 grid-cols-1 grid-auto-rows container mx-auto py-5 gap-5 md:px-[80px] px-4'>
                {renderDoctorCards()}
            </div>
            {renderPagination()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        doctors: makeSelectListDoctors(state),
        pagination: makeSelectListDoctorsPaginator(state)
    };
};

FindDoctorLayout.propTypes = {
    doctors: PropTypes.array,
    pagination: PropTypes.object,
    onChange: PropTypes.func
};

export default connect(mapStateToProps)(FindDoctorLayout);
