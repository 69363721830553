import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// ~ Subcomponents
import MedicinesAccordion from './MedicinesAccordion';

const MedicinesLayout = ({ sections = [] }) => {
    // ? Render multiples content html
    const renderMultiplesContentHtml = (contents) => {
        if (contents.length > 0) {
            return contents.map((content, idx) => {
                if (content.type === 'accordion') {
                    return (
                        <div key={idx} className='w-full col-span-1 mb-2'>
                            <MedicinesAccordion items={content.items} />
                        </div>
                    );
                }
            });
        }
    };

    // ? Render single content
    const renderSingleContent = (content) => {
        if (content.length > 0) {
            return content.map((item, idx) => {
                return <Fragment key={idx}>{ReactHtmlParser(item)}</Fragment>;
            });
        }
    };

    // ? Render all sections
    const renderAllSections = () => {
        if (sections.length > 0) {
            return sections.map((section, idx) => {
                const isFaq = section.column === '2' && section.contentHtml.length > 0;
                return (
                    <div
                        key={idx}
                        className={`grid grid-cols-1 gap-5 md:grid-cols-${section.column} ${
                            isFaq ? 'faq-section' : ''
                        }`}
                    >
                        {renderSingleContent(sections[idx]?.content)}
                        {renderMultiplesContentHtml(section?.contentHtml)}
                    </div>
                );
            });
        }
    };

    const renderMedicinesLayout = () => {
        if (sections.length > 0) {
            return <>{renderAllSections()}</>;
        }
    };
    return <div>{renderMedicinesLayout()}</div>;
};

MedicinesLayout.propTypes = {
    sections: PropTypes.array
};

export default MedicinesLayout;
