import React from 'react';
import PropTypes from 'prop-types';

// ~ Components
import BaseCsrCard from 'components/BaseCsrCard';

const CsrLayout = ({ items = [] }) => {
    // ? Render cards
    const renderCards = () => {
        if (items.length > 0) {
            return items.map((item, index) => {
                return (
                    <BaseCsrCard
                        title={item?.title}
                        imagePath={item?.image}
                        date={item?.date?.date}
                        slug={item?.slug}
                        key={index}
                    />
                );
            });
        }
    };
    return (
        <div className='container mx-auto pt-5 px-4 md:px-[80px] grid md:grid-cols-3 grid-cols-1 gap-5'>
            {renderCards()}
        </div>
    );
};

CsrLayout.propTypes = {
    items: PropTypes.array
};

export default CsrLayout;
