import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ~ Components
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import Slider from 'react-slick';

import { BrowserView, MobileView } from 'react-device-detect';

// ~ Services
import { makeSelectBanners } from 'services/meta/metaSelectors';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { REACT_APP_DOMAIN_MEDIA } = process.env;

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                left: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                background: '#c9c9c9'
            }}
            onClick={onClick}
        >
            <GrFormPrevious
                fontSize='28px'
                color='rgba(0,0,0,0.9)'
                style={{
                    opacity: 0.8
                }}
            />
        </div>
    );
};

PrevArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                right: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                background: '#c9c9c9'
            }}
            onClick={onClick}
        >
            <GrFormNext
                fontSize='28px'
                color='rgba(0,0,0,0.9)'
                style={{
                    opacity: 0.8
                }}
            />
        </div>
    );
};

NextArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

const HomepageBanner = ({ banners = [] }) => {
    const settings = {
        className: '',
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplaySpeed: 8000,
        speed: 1000
    };
    // ? Render carousel items
    const renderCarouselItems = () => {
        return banners.map((item) => {
            if (item.url === '') {
                return (
                    <a key={item.id}>
                        <img
                            src={`${REACT_APP_DOMAIN_MEDIA}${item.image}`}
                            alt='...'
                            className='object-cover w-full md:h-[calc(100vw/2.8)]'
                        />
                    </a>
                );
            } else {
                return (
                    <a href={item.url} key={item.id}>
                        <img
                            src={`${REACT_APP_DOMAIN_MEDIA}${item.image}`}
                            alt='...'
                            className='object-cover w-full md:h-[calc(100vw/2.8)]'
                        />
                    </a>
                );
            }
        });
    };

    // ? Render carousel items mobile
    const renderCarouselItemsMobile = () => {
        return banners.map((item) => {
            if (item.imageMobile) {
                return (
                    <a href={item.url} key={item.id} className='md:h-auto h-[600px]'>
                        <img
                            src={`${REACT_APP_DOMAIN_MEDIA}${item.imageMobile}`}
                            alt='...'
                            className='object-cover w-full md:h-auto h-full'
                        />
                    </a>
                );
            } else {
                return (
                    <a href={item.url} key={item.id} className='md:h-auto h-[600px] w-[100vw]'>
                        <img
                            src={`${REACT_APP_DOMAIN_MEDIA}${item.image}`}
                            alt='...'
                            className='object-cover w-full md:h-auto h-full'
                        />
                    </a>
                );
            }
        });
    };

    return (
        <div className='relative overflow-hidden md:h-[calc(100vw/2.8)] h-[600px]'>
            <BrowserView className='h-full'>
                <Slider {...settings}>{renderCarouselItems()}</Slider>
            </BrowserView>

            <MobileView className='h-full' id='homepage-banner'>
                <Slider {...settings}>{renderCarouselItemsMobile()}</Slider>
            </MobileView>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        banners: makeSelectBanners(state)
    };
};

HomepageBanner.propTypes = {
    banners: PropTypes.array
};

export default connect(mapStateToProps)(HomepageBanner);
