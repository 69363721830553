import { listNewsDataTransformer, newsDetailsDataTransformer } from 'transformer/news';

/**
 * TODO: Get list news handler
 */

const getListNewsHandler = (state, { payload }) => {
    return {
        ...state,
        newsData: listNewsDataTransformer(payload)
    };
};

/**
 * TODO: Get news details handler
 */

const getNewsDetailsHandler = (state, { payload }) => {
    return {
        ...state,
        newsDetailsData: newsDetailsDataTransformer(payload)
    };
};

export { getListNewsHandler, getNewsDetailsHandler };
