import React from 'react';
import PropTypes from 'prop-types';

import FooterLogoImagePath from 'assets/common/footer-logo.png';

const FooterCopyright = ({ data = {} }) => {
    return (
        <div className='container mx-auto flex md:flex-row flex-col justify-between py-3'>
            <div className='flex items-center md:px-0 px-2'>
                <img src={FooterLogoImagePath} className='w-[40px]' />
                <div className='text-body-4'>
                    Copyright © KPJ Healthcare Berhad. 199201015575 (247079-M) All Rights Reserved.
                </div>
            </div>

            <div className='flex gap-5 text-body-4 md:px-0 px-4 md:mt-0 mt-1 md:pl-0 md:justify-start justify-start pl-[48px]'>
                <div>
                    <strong>KKLIU</strong>
                    <div>{data?.kkliuNo}</div>
                </div>
                <a href={data?.pdpa}>
                    <strong>PDPA</strong>
                    <div>PDPA NOTICE</div>
                </a>
            </div>
        </div>
    );
};

FooterCopyright.propTypes = {
    data: PropTypes.object
};

export default FooterCopyright;
