import React from 'react';
import PropTypes from 'prop-types';

import DefaultImagePath from 'assets/common/default-event.png';
import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_DOMAIN_MEDIA } = process.env;
const BaseSearchCard = ({ data = {} }) => {
    // ? Render image
    const renderImage = () => {
        if (data.image) {
            return <img src={`${REACT_APP_DOMAIN_MEDIA}${data?.image}`} className='w-full' />;
        } else {
            return <img src={DefaultImagePath} className='w-full' />;
        }
    };

    const renderLink = () => {
        const pages = [
            'our-services',
            'centre-for-sight',
            'senior-living-care',
            'laboratory-services',
            'dental-specialist-centre',
            'rehabilitation-centre',
            'bariatric-surgery',
            'our-businesses',
            'kpjtelemedicineservices2',
            'kpj-cares-card-program',
            'csr',
            'klinik-wakaf-an-nur',
            'contact-us',
            'health-tourism'
        ];
        if (data.type) {
            switch (data.type) {
                case 'news':
                    return `/news/${data?.slug}`;
                case 'package':
                    return `/packages/${data?.slug}`;
                case 'doctor':
                    return `/doctor/${data?.slug}`;
                case 'page':
                    if (pages.includes(data.slug)) {
                        return `/${data?.slug}`;
                    } else {
                        return `/${data?.slug}`;
                    }
                case 'hospital':
                    return `/our-hospitals/#${data?.slug}`;
                default:
                    return `/${data?.slug}`;
            }
        } else {
            return `/${data?.slug}`;
        }
    };

    return (
        <div className='flex md:flex-row flex-col md:items-start items-center w-full border-b-[3px] border-main pb-5 gap-8'>
            <div className='w-[200px] min-w-[200px]'>
                <div className='text-[16px] font-bold md:text-start text-center'>{data?.title}</div>
                {renderImage()}
            </div>

            <div className='self-center'>
                <div>{ReactHtmlParser(data?.textvalue)}</div>
                <a href={renderLink()}>
                    <div className='py-[5px] px-5 bg-main w-fit text-white rounded-[12px] mt-2 text-[14px]'>
                        Read more
                    </div>
                </a>
            </div>
        </div>
    );
};

BaseSearchCard.propTypes = {
    data: PropTypes.object
};

export default BaseSearchCard;
