import healthTourismActionTypes from './healthTourismActionTypes';

const getHealthTourismPageRequest = () => ({
    type: healthTourismActionTypes.GET_HEALTH_TOURISM_PAGE_REQUEST
});

const getHealthTourismPageSuccess = (payload) => ({
    type: healthTourismActionTypes.GET_HEALTH_TOURISM_PAGE_SUCCESS,
    payload
});

const getHealthTourismPageFailure = (error) => ({
    type: healthTourismActionTypes.GET_HEALTH_TOURISM_PAGE_FAILURE,
    payload: error
});

export default {
    getHealthTourismPageRequest,
    getHealthTourismPageSuccess,
    getHealthTourismPageFailure
};
