import httpClient from '../index';
import HEALTH_TOURISM_CONSTANTS from 'api/constants/health-tourism';
/**
 * TODO: Get health tourism page
 */

const getHealthTourismPage = async () => {
    try {
        const getHealthTourismPageResponse = await httpClient.get(
            HEALTH_TOURISM_CONSTANTS.GET_HEALTH_TOURISM_PAGE
        );

        return getHealthTourismPageResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getHealthTourismPage
};
