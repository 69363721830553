import React from 'react';
import PropTypes from 'prop-types';

import { convertTimeUTCToLocal } from 'utils';
import CsrCardDefaultImage from 'assets/common/background-event.png';
import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_DOMAIN_MEDIA, REACT_APP_DOMAIN } = process.env;

const BaseCsrCard = ({ imagePath = '', title = '', date = '', slug = '' }) => {
    // ? Render image path
    const renderImagePath = () => {
        if (imagePath) {
            return `${REACT_APP_DOMAIN_MEDIA}${imagePath}`;
        }
        return CsrCardDefaultImage;
    };
    return (
        <a href={`${REACT_APP_DOMAIN}news/${slug}`}>
            <div className='w-full p-3 border border-main min-h-[250px] hover:opacity-90 cursor-pointer relative h-full'>
                <div className='h-[150px]'>
                    <img src={renderImagePath()} className='h-full w-full object-cover' />
                </div>

                <div className='text-[18px] font-bold p-2'>{ReactHtmlParser(title)}</div>

                <div className='absolute top-0 left-0 p-1 border-l-[5px] border-main bg-white text-[12px]'>
                    {convertTimeUTCToLocal(date)}
                </div>
            </div>
        </a>
    );
};

BaseCsrCard.propTypes = {
    imagePath: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    slug: PropTypes.string
};

export default BaseCsrCard;
