import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

const BaseHeaderWithContentSection = ({ title, desc, children, className = '', imagePath }) => {
    return (
        <div className={`${className} relative md:h-[320px] h-fit overflow-hidden w-full`}>
            <div className='absolute top-0 left-0 h-full w-full'>
                <img className='h-full w-full object-cover' src={imagePath} />
            </div>

            <div className='relative py-8 flex flex-col gap-3 items-center max-w-[1100px] md:px-[80px] px-4 mx-auto'>
                <div className='text-[28px] font-bold'>{ReactHtmlParser(title)}</div>
                <div className='bg-main w-[52px] h-[2px] mt-[-4px]' />

                <div className='mt-3 mx-auto text-center'>{ReactHtmlParser(desc)}</div>

                {children}
            </div>
        </div>
    );
};

BaseHeaderWithContentSection.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    imagePath: PropTypes.any
};
export default BaseHeaderWithContentSection;
