import React from 'react';

import Homepage from 'pages/homepage';
import OurHospitals from 'pages/our-hospitals';
import FindDoctor from 'pages/find-doctor';
import OurServices from 'pages/our-services';
import HealthTourism from 'pages/health-tourism';
import ContactUs from 'pages/contact-us';
import CenterForSight from 'pages/center-for-sight';
import SeniorLivingCare from 'pages/senior-living-care';
import LaboratoryServices from 'pages/laboratory-services';
import DentalSpecialistCentre from 'pages/dental-specialist-centre';
import RehabilitationCentre from 'pages/rehabilitation-centre';
import BariatricSurgery from 'pages/bariatric-surgery';
import PackageDetails from 'pages/packages/details';
import DoctorDetails from 'pages/find-doctor/details';
import PageNotFound from 'pages/404';
import OurBusinesses from 'pages/our-businesses';
import MedicinesServices from 'pages/medicines';
import CareCardProgram from 'pages/care-card-program';
import CsrPage from 'pages/csr';
import KlinikWakaf from 'pages/klinik-wakaf';
import Careers from 'pages/careers';
import Tender from 'pages/tender';
import SearchPage from 'pages/search';
import Covid19Booklet from 'pages/covid19-booklet';
import PageSlug from 'pages/page/slug';
import NewsPage from 'pages/news';
import NewsDetails from 'pages/news/details';
import Applications from 'pages/applications';
import HereForYou from 'pages/hereforyou';

const MainRoutes = [
    {
        path: '/',
        element: <Homepage />
    },
    {
        path: '/our-hospitals',
        element: <OurHospitals />
    },
    {
        path: '/find-doctor',
        element: <FindDoctor />
    },
    {
        path: '/our-services',
        element: <OurServices />
    },
    {
        path: '/health-tourism',
        element: <HealthTourism />
    },
    {
        path: '/contact-us',
        element: <ContactUs />
    },
    {
        path: '/centre-for-sight',
        element: <CenterForSight />
    },
    {
        path: '/senior-living-care',
        element: <SeniorLivingCare />
    },
    {
        path: '/laboratory-services',
        element: <LaboratoryServices />
    },
    {
        path: '/dental-specialist-centre',
        element: <DentalSpecialistCentre />
    },
    {
        path: '/rehabilitation-centre',
        element: <RehabilitationCentre />
    },
    {
        path: '/bariatric-surgery',
        element: <BariatricSurgery />
    },
    {
        path: '/packages/:slug',
        element: <PackageDetails />
    },
    {
        path: '/doctor/:slug',
        element: <DoctorDetails />
    },
    {
        path: '/our-businesses',
        element: <OurBusinesses />
    },
    {
        path: '/kpjtelemedicineservices2',
        element: <MedicinesServices />
    },
    {
        path: '/kpj-cares-card-program',
        element: <CareCardProgram />
    },
    {
        path: '/csr',
        element: <CsrPage />
    },
    {
        path: '/klinik-wakaf-an-nur',
        element: <KlinikWakaf />
    },
    {
        path: '/careers',
        element: <Careers />
    },
    {
        path: '/tender',
        element: <Tender />
    },
    {
        path: '/kpjcovid19booklet',
        element: <Covid19Booklet />
    },
    {
        path: '/search',
        element: <SearchPage />
    },
    {
        path: '/page/:slug',
        element: <PageSlug />
    },
    {
        path: '/news',
        element: <NewsPage />
    },
    {
        path: '/news/:slug',
        element: <NewsDetails />
    },
    {
        path: '/applications',
        element: <Applications />
    },
    {
        path: '/404',
        element: <PageNotFound />
    },
    {
        path: '/:slug',
        element: <PageSlug />
    },
    {
        path: '/hereforyou',
        element: <HereForYou />
    },
    {
        path: '*',
        element: <PageNotFound />
    }
];

export default MainRoutes;
