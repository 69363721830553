import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const News = ({ item }) => {
  if (item.items.length === 0) {
    return <h2 className='text-2xl font-bold text-center mt-10'>No Latest News</h2>;
  }

  return (
    <div className='container mx-auto'
    style={{width:'150%',marginRight:'-10%',marginLeft:'-10%'}}>
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3'>
        {item.items.map((_item) => {
          const date = new Date(_item.date.date);
          const formattedDate = date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          });

          return (
            <div key={_item.id} className='p-1 flex w-600'>
              <div className='bg-white overflow-hidden border border-indigo-500 flex flex-col h-full'>
                <div className='relative'>
                  <div className='absolute top-0 left-0 bg-indigo-500 w-4 h-5'></div>
                  <p className='text-black-300 text-[14px] absolute top-0 left-2 bg-white'>
                    {formattedDate}
                  </p>
                </div>
                <div className='aspect-w-3 aspect-h-2 bg-cover bg-center p-2 flex-shrink-0'>
                  {_item.image ? (
                    <img
                      src={`https://media.kpjhealth.com.my/${_item.image}`}
                      alt={_item.title}
                      className='w-full h-full object-cover'
                      style={{ width: '100%', height: '100%',marginBottom:'25px' }}
                    />
                  ) : (
                    <div className='w-full h-full bg-black bg-opacity-50'></div>
                  )}
                </div>
                <div className='p-4 flex-grow'>
                  <a className='block mt-2 text-lg font-semibold text-gray-700'
                   href={`news/${_item.slug}`}>
                    {ReactHtmlParser(_item.title)}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

News.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        image_thumb: PropTypes.string,
        price: PropTypes.number,
        date: PropTypes.object,
      })
    ),
    item_per_row: PropTypes.number,
  }),
};

export default News;
