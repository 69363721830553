import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import OurServicesDetailsLayout from 'layouts/our-services/OurServicesDetailsLayout';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';

import ourServicesActions from 'services/our-services/ourServicesActions';
import {
    makeSelectSeniorLivingCare,
    makeSelectListServices
} from 'services/our-services/ourServicesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

import { Helmet } from 'react-helmet-async';

const SeniorLivingCare = ({
    seniorLivingCare = {},
    getSeniorLivingCare = () => {},
    sections = [],
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            getSeniorLivingCare();
        }
    }, [sections, isAuthenticated]);
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{seniorLivingCare?.meta?.title}</title>
                <meta name='description' content={seniorLivingCare?.meta?.description} />
                <meta name='keywords' content={seniorLivingCare?.meta?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <OurServicesHeaderSection defaultCheckedTitle='senior-living-care' />
                <OurServicesDetailsLayout data={seniorLivingCare.layout} />
                <OurServicesFooter currentIndex={2} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListServices: () => dispatch(ourServicesActions.listOurServices()),
        getSeniorLivingCare: () => dispatch(ourServicesActions.getSeniorLivingCare())
    };
};

const mapStateToProps = (state) => {
    return {
        seniorLivingCare: makeSelectSeniorLivingCare(state),
        sections: makeSelectListServices(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

SeniorLivingCare.propTypes = {
    getSeniorLivingCare: PropTypes.func,
    seniorLivingCare: PropTypes.object,
    sections: PropTypes.array,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(SeniorLivingCare);
