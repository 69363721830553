// * Third-party node modules
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = () => {
    return (
        <ToastContainer
            limit={3}
            autoClose={3000}
            hideProgressBar
            draggable={false}
            position='bottom-left'
            className='!bottom-[40px]'
            toastClassName={() =>
                'rounded-none p-1 flex bg-white min-w-[200px] max-w-[280px] mb-4 shadow justify-between text-[#000]'
            }
            bodyClassName={() => 'text-[13px] text-secondary/90 flex py-2 pl-2 whitespace-pre-line'}
        />
    );
};
export default Toast;
