import { camelize } from 'transformer/common';
import {
    contactUsDataTransformer,
    careCardDataTransformer,
    listCareersDataTransformer
} from 'transformer/contact-us';
import { toast } from 'react-toastify';

/**
 * TODO: Get contact us data
 */

const getContactUsDataHandler = (state, { payload }) => {
    const dataTransformed = contactUsDataTransformer(camelize(payload));
    return {
        ...state,
        contactUsLocation: dataTransformed.location,
        contactUsForm: dataTransformed.form
    };
};
/**
 * TODO: Submit contact us form
 */

const submitContactUsFormHandler = (state, { payload }) => {
    toast.success(
        'Your inquiry has been received. We will be in touch shortly. Alternatively, please call us at our hotline for immediate response. Thank You.'
    );
    return {
        ...state,
        message: payload.msg,
        isSubmitSuccess: true,
        isFetching: false
    };
};

/**
 * TODO: Get care card program
 */

const getCareCardProgramHandler = (state, { payload }) => {
    return {
        ...state,
        careCardProgramData: careCardDataTransformer(payload)
    };
};

/**
 * TODO: Get list careers
 */

const getListCareersHandler = (state, { payload }) => {
    return {
        ...state,
        listCareers: listCareersDataTransformer(payload)?.items
    };
};

/**
 * TODO: Submit resume
 */

const startSubmittingResume = (state) => {
    return {
        ...state,
        isFetching: true
    };
};

const submitResumeHandler = (state, { payload }) => {
    toast.success(
        'Your submission is received. We will be in touch when a job opening is available. Thank you.'
    );
    return {
        ...state,
        message: payload.msg,
        isSubmitSuccess: true,
        isFetching: false
    };
};

const submitResumeError = (state, { payload }) => {
    toast.error('Sorry, something went wrong. Please check your input');

    return {
        ...state,
        message: payload.msg,
        isSubmitSuccess: false,
        isFetching: false
    };
};

/**
 * TODO: Reset is submit success
 */

const resetIsSubmitSuccessHandler = (state) => {
    return {
        ...state,
        isSubmitSuccess: false,
        isFetching: false
    };
};

/**
 * TODO: Submit applications form
 */

const submitApplicationsFormHandler = (state, { payload }) => {
    toast.success(
        'Thank you for your application. Your submission has been received and is under review.'
    );

    return {
        ...state,
        message: payload.msg,
        isSubmitSuccess: true,
        isFetching: false
    };
};

const submitApplicationsFormError = (state, { payload }) => {
    toast.error(
        'Something wrong, cannot submit your application at this time. Please try again or contact the admin.(E1)'
    );

    return {
        ...state,
        message: payload.msg,
        isSubmitSuccess: false,
        isFetching: false
    };
};

export {
    getContactUsDataHandler,
    submitContactUsFormHandler,
    getCareCardProgramHandler,
    getListCareersHandler,
    submitResumeHandler,
    resetIsSubmitSuccessHandler,
    submitResumeError,
    startSubmittingResume,
    submitApplicationsFormHandler,
    submitApplicationsFormError
};
