import React from 'react';
import PropTypes from 'prop-types';

import BaseAccordion from 'components/BaseAccordion';
import ReactHtmlParser from 'react-html-parser';

const BaseServiceCardDetails = ({ data = {} }) => {
    // ? Render contents
    const renderContents = (contents = []) => {
        if (contents.length > 0) {
            return contents.map((cnt, idx) => {
                if (cnt.items && cnt.items.length > 0) {
                    return (
                        <div key={idx}>
                            {ReactHtmlParser(cnt.content)}
                            <div className='md:w-[700px] mt-5'>
                                <BaseAccordion items={cnt.items} />
                            </div>
                        </div>
                    );
                } else {
                    return <div key={idx}>{ReactHtmlParser(cnt.content)}</div>;
                }
            });
        }
    };

    // ? Render sections
    const renderSections = () => {
        if (data?.sections && data?.sections.length > 0) {
            return data?.sections.map((section, idx) => {
                return (
                    <div key={idx} className='md:w-full mt-5'>
                        {renderContents(section.contents)}
                    </div>
                );
            });
        }
    };
    return (
        <div id='services-card'>
            {ReactHtmlParser(data?.content)}

            {renderSections()}
        </div>
    );
};

BaseServiceCardDetails.propTypes = {
    data: PropTypes.object
};

export default BaseServiceCardDetails;
