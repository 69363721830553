import React from 'react';

// ? Components
import SubheaderImagePath from 'assets/common/subheader-doctors.png';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';

const CareersHeaderSection = () => {
    return <BaseHeaderWithContentSection imagePath={SubheaderImagePath} title='Careers' />;
};

export default CareersHeaderSection;
