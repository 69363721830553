const newsActionTypes = {
    GET_LIST_NEWS_REQUEST: 'GET_LIST_NEWS_REQUEST',
    GET_LIST_NEWS_SUCCESS: 'GET_LIST_NEWS_SUCCESS',
    GET_LIST_NEWS_FAILURE: 'GET_LIST_NEWS_FAILURE',

    // ? Get news details
    GET_NEWS_DETAILS_REQUEST: 'GET_NEWS_DETAILS_REQUEST',
    GET_NEWS_DETAILS_SUCCESS: 'GET_NEWS_DETAILS_SUCCESS',
    GET_NEWS_DETAILS_FAILURE: 'GET_NEWS_DETAILS_FAILURE'
};

export default newsActionTypes;
