import contactUsActionTypes from './contactUsActionTypes';
import contactUsInitState from './contactUsInitState';

import {
    getContactUsDataHandler,
    submitContactUsFormHandler,
    getCareCardProgramHandler,
    getListCareersHandler,
    submitResumeHandler,
    resetIsSubmitSuccessHandler,
    submitResumeError,
    startSubmittingResume,
    submitApplicationsFormHandler,
    submitApplicationsFormError
} from './contactUsReducerHandler';

const contactUsReducer = (state = contactUsInitState, action) => {
    switch (action.type) {
        case contactUsActionTypes.GET_CONTACT_US_DATA_SUCCESS:
            return getContactUsDataHandler(state, action);
        case contactUsActionTypes.SUBMIT_CONTACT_US_FORM_SUCCESS:
            return submitContactUsFormHandler(state, action);
        case contactUsActionTypes.GET_CARE_CARD_PROGRAM_SUCCESS:
            return getCareCardProgramHandler(state, action);
        case contactUsActionTypes.GET_LIST_CAREERS_SUCCESS:
            return getListCareersHandler(state, action);
        case contactUsActionTypes.SUBMIT_RESUME_SUCCESS:
            return submitResumeHandler(state, action);
        case contactUsActionTypes.RESET_IS_SUBMIT_SUCCESS:
            return resetIsSubmitSuccessHandler(state, action);
        case contactUsActionTypes.SUBMIT_RESUME_FAILURE:
            return submitResumeError(state, action);
        case contactUsActionTypes.START_SUBMITTING_RESUME:
            return startSubmittingResume(state);
        case contactUsActionTypes.SUBMIT_APPLICATIONS_SUCCESS:
            return submitApplicationsFormHandler(state, action);
        case contactUsActionTypes.SUBMIT_APPLICATIONS_FAILURE:
            return submitApplicationsFormError(state, action);
        default:
            return state;
    }
};

export default contactUsReducer;
