const metaInitState = {
    headerData: {},
    footerData: {},
    isOnlyStatic: 0,
    banners: [],
    subHeaderData: [],
    events: [],
    states: [],
    specialties: [],
    pageData: {}
};

export default metaInitState;
