import {
    ourBusinessesDataTransformer,
    medicinesServicesDataTransformer,
    tenderServicesDataTransformer,
    searchDataTransformer,
    hereForYouDataTransformer
} from 'transformer/our-businesses';

/**
 * TODO: Get list our businesses handler
 */

const getListOurBusinessesHandler = (state, { payload }) => {
    return {
        ...state,
        ourBusinessesData: ourBusinessesDataTransformer(payload)
    };
};

/**
 * TODO: Get list medicines handler
 */

const getListMedicinesHandler = (state, { payload }) => {
    return {
        ...state,
        medicinesData: medicinesServicesDataTransformer(payload)
    };
};

/**
 * TODO: Get list tender
 */

const getListTenderHandler = (state, { payload }) => {
    return {
        ...state,
        tenderData: tenderServicesDataTransformer(payload)
    };
};

/**
 * TODO: Search data handler
 */

const searchDataHandler = (state, { payload }) => {
    return {
        ...state,
        searchData: searchDataTransformer(payload)
    };
};

/**
 * TODO: Get here for you handler
 */

const getHereForYouHandler = (state, { payload }) => {
    return {
        ...state,
        hereForYouData: hereForYouDataTransformer(payload)
    };
};

export {
    getListOurBusinessesHandler,
    getListMedicinesHandler,
    getListTenderHandler,
    searchDataHandler,
    getHereForYouHandler
};
