import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeSelectIsAuthenticated, makeSelectMetaData } from 'services/meta/metaSelectors';

import FindDoctorHeaderSection from 'layouts/find-doctor/FindDoctorHeaderSection';
import FindDoctorLayout from 'layouts/find-doctor/FindDoctorLayout';
import doctorActions from 'services/doctor/doctorActions';
import hospitalActions from 'services/hospitals/hospitalActions';
import { useDoctorSlice } from 'services/doctor';
import { useHospitalSlice } from 'services/hospitals';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FindDoctor = ({
    getListDoctors = () => {},
    metaData = {},
    getListHospitals = () => {},
    isAuthenticated = false
}) => {
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState({});
    let query = useQuery();

    useEffect(() => {
        if (isAuthenticated) {
            // ? Get query params
            const keyword = query.get('keyword');
            const specialty_id = query.get('specialty_id');
            const hospital_id = query.get('hospital_id');

            useDoctorSlice();
            useHospitalSlice();
            getListDoctors({ page: 1, limit: 12, keyword, specialty_id, hospital_id });
            getListHospitals();
        }
    }, [isAuthenticated]);

    // ? Handle on change pagination
    const handleOnChangePagination = (currentPage) => {
        getListDoctors({ page: currentPage, limit: 12, ...queryParams });

        // ? Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleOnSearch = (params) => {
        setQueryParams(params);

        let searchValue = '';
        if (params?.keyword) {
            searchValue.length === 0
                ? (searchValue += `keyword=${params?.keyword}`)
                : (searchValue += `&keyword=${params?.keyword}`);
        }
        if (params?.specialty_id) {
            searchValue.length === 0
                ? (searchValue += `specialty_id=${params?.specialty_id}`)
                : (searchValue += `&specialty_id=${params?.specialty_id}`);
        }
        if (params?.hospital_id) {
            searchValue.length === 0
                ? (searchValue += `hospital_id=${params?.hospital_id}`)
                : (searchValue += `&hospital_id=${params?.hospital_id}`);
        }

        navigate({
            pathname: '/find-doctor',
            search: `?${searchValue}`
        });
        getListDoctors({ ...params, page: 1, limit: 12 });

        // ? Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta name='keywords' content={metaData?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <FindDoctorHeaderSection onSearch={handleOnSearch} />

                <FindDoctorLayout onChange={handleOnChangePagination} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListDoctors: (params) => dispatch(doctorActions.getListDoctors(params)),
        getListHospitals: () => dispatch(hospitalActions.getListHospitals())
    };
};

const mapStateToProps = (state) => {
    return {
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

FindDoctor.propTypes = {
    getListDoctors: PropTypes.func,
    getListHospitals: PropTypes.func,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(FindDoctor);
