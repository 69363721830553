import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';

import { Label, TextInput, FileInput, Select } from 'flowbite-react';

// ~ Services
import { connect } from 'react-redux';

import { useContactUsSlice } from 'services/contact-us';

import { useHospitalSlice } from 'services/hospitals';
import hospitalActions from 'services/hospitals/hospitalActions';
import contactUsActions from 'services/contact-us/contactUsActions';
import { makeSelectListHospitals } from 'services/hospitals/hospitalSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import {
    makeSelectContactUsSubmitSuccess,
    makeSelectorIsFetching
} from 'services/contact-us/contactUsSelector';

import { getFormData } from 'utils';

const { REACT_APP_CAPCHA_V3 } = process.env;

// ? Constants
const applicantInfoInputs = [
    {
        label: 'Full Name',
        key: 'name',
        placeholder: 'e.g Ahmadi'
    },
    {
        label: 'Email',
        key: 'email',
        placeholder: 'e.g mail@gmail.com'
    },
    {
        label: 'Contact Number',
        key: 'phone_number',
        placeholder: 'e.g 012xxx'
    }
];

const uploadSupportingDocuments = [
    {
        title: 'Personal Details',
        inputs: [
            {
                label: 'Resume',
                key: 'resume',
                keyError: 'resume',
                isRequired: true,
                linkDetails:
                    'https://media.kpjhealth.com.my/media/hospital/hosp-29/career/KPJ_Resume_Form_1667873848.doc'
            },
            {
                label: 'Recommendation form',
                key: 'r_form',
                keyError: 'form',
                isRequired: true,
                linkDetails:
                    'https://media.kpjhealth.com.my/media/hospital/hosp-29/career/Recommendation_Form_1667873848.docx'
            },
            {
                label: '',
                key: 'r_form_2',
                keyError: 'form2',
                isRequired: true
            }
        ]
    },
    {
        title: 'Education',
        inputs: [
            {
                label: "Bachelor's Degree Certificate",
                key: 'bachelor',
                keyError: 'bachelor',
                isRequired: true
            },
            {
                label: "Master's Degree/Postgraduate Degree Certificate",
                key: 'master',
                isRequired: true
            }
        ]
    },
    {
        title: 'Qualification',
        inputs: [
            {
                label: 'MMC Full Registration Certificate',
                key: 'mmc',
                isRequired: true
            },
            {
                label: 'Latest Annual Practicing Certificate',
                key: 'practice',
                isRequired: true
            },
            {
                label: 'NSR Certificate',
                key: 'nsr',
                isRequired: true
            },
            {
                label: 'Fellowship Certificate (if any)',
                key: 'fellowship'
            },
            {
                label: 'Medical Indemnity (if any)',
                key: 'indemnity'
            }
        ]
    }
];

const ApplicationsForm = ({
    hospitals = [],
    getListHospitals = () => {},
    isAuthenticated = false,
    resetSubmitSuccess = () => {},
    isFetching = false,
    submitApplicationForm = () => {},
    isSubmitSuccess = false
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();

    const [submitData, setSubmitData] = useState({});
    const [preferredHospitalData, setPreferredHospitalData] = useState('0');
    const [hospitalData, setHospitalData] = useState('0');
    const [hospitalData1, setHospitalData1] = useState('0');

    useEffect(() => {
        if (isAuthenticated) {
            useContactUsSlice();
            useHospitalSlice();
            getListHospitals();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isSubmitSuccess) {
            setSubmitData({});
            setPreferredHospitalData('0');
            setHospitalData('0');
            setHospitalData1('0');

            // ? Reset all input value
            const inputs = document.querySelectorAll('input');
            inputs.forEach((input) => {
                input.value = '';
            });

            // ? Reset all select value
            const selects = document.querySelectorAll('select');
            selects.forEach((select) => {
                select.value = '0';
            });

            // ? Reset all file input value
            const fileInputs = document.querySelectorAll('input[type="file"]');
            fileInputs.forEach((fileInput) => {
                fileInput.value = '';
            });

            setTimeout(() => {
                resetSubmitSuccess();
            }, 300);
        }
    }, [isSubmitSuccess]);

    const handleOnChangeValue = (e, key) => {
        setSubmitData({ ...submitData, [key]: e.target.value });
    };

    const handleOnChangeValueSelect = (e, key) => {
        if (key === 'preferred_hospital') {
            setPreferredHospitalData(e.target.value);
        }

        if (key === 'hospital') {
            setHospitalData(e.target.value);
        }

        if (key === 'hospital_1') {
            setHospitalData1(e.target.value);
        }
    };

    const handleOnChangeFile = (e, key) => {
        const file = e.target.files[0];
        setSubmitData({ ...submitData, [key]: file });
    };

    // ! Applicant info

    // ? Render input
    const renderInput = (label, key, placeholder) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label className='text-[14px]'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <TextInput
                    placeholder={placeholder}
                    {...register(label, { required: true })}
                    className='text-[14px]'
                    onChange={(e) => handleOnChangeValue(e, key)}
                />

                {errors && errors[label] && (
                    <div className='text-[12px] text-normal text-red-500 -mt-1'>
                        *This field is required
                    </div>
                )}
            </div>
        );
    };
    // ? Applicant info
    const renderApplicantInfo = () => {
        return (
            <>
                <div className='text-[16px] font-bold'>Applicant&apos;s Info</div>

                <div className='w-full h-[1px] my-2 bg-[#555555]'></div>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-5'>
                    {applicantInfoInputs.map((input, index) => {
                        return (
                            <div key={index} className='flex flex-col gap-2'>
                                {renderInput(input.label, input.key, input.placeholder)}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    // ! File document

    // ? Render single file upload
    const renderSingleFileUpload = (label, key, isRequired = false, linkDetails = '') => {
        return (
            <div className='flex md:items-center items-start space-between flex-col md:flex-row'>
                <Label htmlFor={key} className='md:w-[50%] w-full text-[14px]'>
                    {label}
                    {isRequired && <span className='text-[#FF0000]'>* </span>}
                    {linkDetails.length > 0 && (
                        <a href={linkDetails} target='_blank' rel='noreferrer'>
                            <span className='font-bold cursor-pointer'>
                                [Please use form provided <span className='text-main'>here</span>]
                            </span>
                        </a>
                    )}
                </Label>
                <div>
                    <FileInput
                        sizing='sm'
                        id={key}
                        accept='application/pdf,application/msword,image/*'
                        {...register(key, { required: isRequired })}
                        onChange={(e) => handleOnChangeFile(e, key)}
                        name={key}
                        title='Choose file'
                    />

                    {isRequired && errors && errors[key] && (
                        <div className='text-[12px] text-normal text-red-500 mt-0.5'>
                            *This field is required
                        </div>
                    )}
                </div>
            </div>
        );
    };

    // ? Render section file upload
    const renderFileUpload = (label, items) => {
        return (
            <>
                <div className='font-bold'>{label}</div>

                <div className='flex flex-col gap-2 md:mt-2 mt-2'>
                    {items.map((item, index) => {
                        return (
                            <div key={index}>
                                {renderSingleFileUpload(
                                    item.label,
                                    item.key,
                                    item.isRequired,
                                    item.linkDetails
                                )}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    // ? File document
    const renderFileDocument = () => {
        return (
            <>
                <div className='text-[16px] font-bold'>Upload your supporting documents</div>

                <div className='w-full h-[1px] mt-2 mb-1 bg-[#555555]'></div>

                <div className='text-[12px] font-bold italic'>
                    Note: PDF, DOCX, DOC, JPG, JPEG, PNG formats are supported. File limit 5MB.
                </div>

                <div className='flex flex-col gap-2'>
                    {uploadSupportingDocuments.map((item, index) => {
                        return (
                            <div key={index} className='mt-4'>
                                {renderFileUpload(item.title, item.inputs)}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    // ! Preferred hospital
    const renderHospitalOptions = (items) => {
        if (items.length > 0) {
            return items.map((item, idx) => {
                // const hiddenIDs = [29, 41, 44];
                if (
                    item.id != 4 &&
                    item.id != 10 &&
                    item.id != 40 &&
                    item.id != 29 &&
                    item.id != 41 &&
                    item.id != 44
                ) {
                    return (
                        <option key={idx} value={item.id}>
                            {ReactHtmlParser(item.name)}
                        </option>
                    );
                }
            });
        } else {
            return <option>No data</option>;
        }
    };

    const renderPreferredHospital = () => {
        return (
            <>
                <div className='text-[16px] font-bold'>Select your preferred hospital</div>

                <div className='w-full h-[1px] mt-2 mb-1 bg-[#555555]'></div>

                <div id='applications-select' className='flex flex-col gap-2 mt-2'>
                    <div className='flex md:flex-row flex-col md:items-center items-start space-between'>
                        <Label className='md:w-[50%] w-full text-[14px]'>
                            Preferred Hospital
                            {<span className='text-[#FF0000]'>*</span>}
                        </Label>

                        <div className='md:w-[50%] w-full'>
                            <Select
                                className='text-[14px] w-full'
                                sizing='sm'
                                {...register('select', {
                                    required: true,
                                    validate: (value) => +value > 0
                                })}
                                value={preferredHospitalData}
                                onChange={(e) => handleOnChangeValueSelect(e, 'preferred_hospital')}
                            >
                                <option value={null}>-</option>
                                {renderHospitalOptions(hospitals)}
                            </Select>

                            {errors && errors['select'] && (
                                <div className='text-[12px] text-normal text-red-500 mt-0.5'>
                                    *This field is required
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='flex md:flex-row flex-col md:items-center items-start space-between'>
                        <Label className='md:w-[50%] w-full text-[14px]'>
                            Select Your Second Preferred Hospital (if any)
                        </Label>
                        <Select
                            className='text-[14px] md:w-[50%] w-full'
                            sizing='sm'
                            value={hospitalData}
                            onChange={(e) => handleOnChangeValueSelect(e, 'hospital')}
                        >
                            <option value=''>-</option>
                            {renderHospitalOptions(hospitals)}
                        </Select>
                    </div>

                    <div className='flex md:flex-row flex-col md:items-center items-start space-between'>
                        <Label className='md:w-[50%] w-full text-[14px]'>
                            Select Your Second Preferred Hospital (if any)
                        </Label>
                        <Select
                            className='text-[14px] md:w-[50%] w-full'
                            sizing='sm'
                            value={hospitalData1}
                            onChange={(e) => handleOnChangeValueSelect(e, 'hospital_1')}
                        >
                            <option value={0}>-</option>
                            {renderHospitalOptions(hospitals)}
                        </Select>
                    </div>
                </div>
            </>
        );
    };

    // ? Handle on submit form
    const handleOnSubmitForm = () => {
        window.grecaptcha.ready(() => {
            try {
                window.grecaptcha.execute(REACT_APP_CAPCHA_V3, { action: 'submit' }).then(() => {
                    toast.success('Please hold on, we are processing your application.');

                    let hospitalId = '';
                    if (+hospitalData > 0 && +hospitalData1 > 0) {
                        hospitalId = `${hospitalData},${hospitalData1}`;
                    } else if (+hospitalData > 0) {
                        hospitalId = hospitalData;
                    } else if (+hospitalData1 > 0) {
                        hospitalId = hospitalData1;
                    }

                    const requestBody = {
                        ...submitData,
                        preferred_hospital:
                            hospitalId.length > 0
                                ? `${preferredHospitalData},${hospitalId}`
                                : preferredHospitalData,
                        hospital:
                            hospitalId.length > 0
                                ? `${preferredHospitalData},${hospitalId}`
                                : preferredHospitalData
                    };

                    let formData = getFormData(requestBody);
                    submitApplicationForm(formData);
                });
            } catch (error) {
                toast.error('Something went wrong!');
            }
        });
    };

    return (
        <div id='applications' className='container mx-auto pt-5 px-4 md:px-[80px]'>
            <div className='bg-white p-5'>
                {/* Header */}
                <div className='p-4 bg-main text-white'>
                    <div className='text-[18px]'>Application Form</div>
                </div>
                {/* Body */}
                <div className='p-4'>
                    {renderApplicantInfo()}

                    <div className='mt-5'>{renderFileDocument()}</div>

                    <div className='mt-5'>{renderPreferredHospital()}</div>

                    <div className='w-fit ml-auto mt-5'>
                        <button
                            disabled={isFetching}
                            className='text-white bg-[#000] px-8 py-2 w-fit rounded-xl text-[14px] cursor-pointer ml-auto disabled:opacity-60'
                            onClick={handleSubmit(handleOnSubmitForm)}
                        >
                            Send Application
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        hospitals: makeSelectListHospitals(state),
        isAuthenticated: makeSelectIsAuthenticated(state),
        isSubmitSuccess: makeSelectContactUsSubmitSuccess(state),
        isFetching: makeSelectorIsFetching(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListHospitals: () => dispatch(hospitalActions.getListHospitals()),
        submitApplicationForm: (data) =>
            dispatch(contactUsActions.submitApplicationsFormRequest(data)),
        resetSubmitSuccess: () => dispatch(contactUsActions.resetIsSubmitSuccess())
    };
};

ApplicationsForm.propTypes = {
    hospitals: PropTypes.array,
    isAuthenticated: PropTypes.bool,
    getListHospitals: PropTypes.func,
    submitApplicationForm: PropTypes.func,
    isSubmitSuccess: PropTypes.bool,
    resetSubmitSuccess: PropTypes.func,
    isFetching: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsForm);
