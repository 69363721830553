import httpClient from '../index';
import OUR_SERVICES_CONSTANTS from 'api/constants/our-services';

/**
 * TODO: Get list services
 */

const getListServices = () => {
    try {
        const getListServicesResponse = httpClient.get(OUR_SERVICES_CONSTANTS.LIST_SERVICES);

        return getListServicesResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get center for sight
 */

const getCenterForSight = () => {
    try {
        const getCenterForSightResponse = httpClient.get(
            OUR_SERVICES_CONSTANTS['Centre For Sight']
        );

        return getCenterForSightResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get senior living care
 */

const getSeniorLivingCare = () => {
    try {
        const getSeniorLivingCareResponse = httpClient.get(
            OUR_SERVICES_CONSTANTS['Senior Living Care']
        );

        return getSeniorLivingCareResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get laboratory services
 */

const getLaboratoryServices = () => {
    try {
        const getLaboratoryServicesResponse = httpClient.get(
            OUR_SERVICES_CONSTANTS['Laboratory Services']
        );

        return getLaboratoryServicesResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get dental speciality center
 */

const getDentalSpecialityCenter = () => {
    try {
        const getDentalSpecialityCenterResponse = httpClient.get(
            OUR_SERVICES_CONSTANTS['Dental Specialist Centre']
        );

        return getDentalSpecialityCenterResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get rehabilitation center
 */

const getRehabilitationCenter = () => {
    try {
        const getRehabilitationCenterResponse = httpClient.get(
            OUR_SERVICES_CONSTANTS['Rehabilitation Centre']
        );

        return getRehabilitationCenterResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get bariatric surgery
 */

const getBariatricSurgery = () => {
    try {
        const getBariatricSurgeryResponse = httpClient.get(
            OUR_SERVICES_CONSTANTS['Bariatric Surgery']
        );

        return getBariatricSurgeryResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getListServices,
    getCenterForSight,
    getSeniorLivingCare,
    getLaboratoryServices,
    getDentalSpecialityCenter,
    getRehabilitationCenter,
    getBariatricSurgery
};
