import { camelize } from 'transformer/common';
import { transformContent } from 'utils';

const ourBusinessesDataTransformer = (data) => {
    const camelizedData = camelize(data);
    let sections = camelizedData?.others;
    let sectionsData = [];

    // ? Sections
    sections.forEach((section, idx) => {
        let tempObj = {
            slug: section?.slug,
            items: [],
            type: ''
        };

        if (camelizedData?.staticBlocks[idx].alias === section?.slug) {
            tempObj.items = camelizedData?.staticBlocks[idx].items;
            tempObj.type = camelizedData?.staticBlocks[idx].type;
        }

        sectionsData[idx] = tempObj;
    });

    // ? Subheader
    const subheaderData = {
        title: camelizedData.title
    };

    // ? Meta data
    const metaData = {
        metaTitle: camelizedData.metaTitle,
        metaKeywords: camelizedData.metaKeywords,
        metaDescription: camelizedData.metaDescription
    };

    return {
        sections,
        sectionsData,
        subheaderData,
        metaData
    };
};

// ? Medicines services data transformer
const medicinesServicesDataTransformer = (data) => {
    const camelizedData = camelize(data);

    // ! Header section
    const headerSection = {
        content: transformContent(camelizedData.content)
    };

    // ! Sections
    let sections = camelizedData?.sections;
    let staticBlocks = camelizedData?.staticBlocks;

    sections.forEach((section) => {
        let sectionContent = section?.content;
        let sectionContentHtml = [];

        if (sectionContent.length > 0) {
            sectionContent.forEach((content, idx) => {
                staticBlocks.forEach((block) => {
                    if (content.includes(block.shortcode)) {
                        let tempSectionContentHtml = {
                            items: block?.items,
                            type: block?.type
                        };
                        sectionContentHtml[idx] = tempSectionContentHtml;
                    }
                });

                // ? First index of [
                const firstIndex = content.indexOf('[');
                const lastIndex = content.lastIndexOf(']');

                // ? Get content before and after [ ]
                const contentBefore = content.substring(0, firstIndex);
                const contentAfter = content.substring(lastIndex + 1);

                sectionContent[idx] = contentBefore + contentAfter;
                sectionContent[idx] = transformContent(sectionContent[idx]);
            });
        }

        section.contentHtml = sectionContentHtml;
        return section;
    });

    return {
        headerSection,
        sections
    };
};

const tenderServicesDataTransformer = (data) => {
    let camelizedData = camelize(data);

    let itemsTransformed = [];

    camelizedData.items.forEach((item) => {
        item.position = item.title;
        item.closingDate = item.closedAt.date;
        item.details = transformContent(item.description);
        itemsTransformed.push(item);
    });

    camelizedData.items = itemsTransformed;

    return camelizedData;
};

const searchDataTransformer = (data) => {
    const camelizedData = camelize(data);

    let items = [];
    camelizedData.map((item) => {
        item.textvalue = transformContent(item.textvalue);
        items.push(item);
    });
    return {
        items
    };
};

const hereForYouDataTransformer = (data) => {
    const camelizedData = camelize(data);

    // ? Header section
    const headerSection = {
        title: camelizedData?.subhead,
        desc: camelizedData?.intro
    };

    // ? Sections
    let sections = [];

    camelizedData?.sections.forEach((section) => {
        let content = '';
        if (section.content) {
            content = '<div>';
            section.content.forEach((singleContent) => {
                content += transformContent(singleContent);
            });

            content += '</div>';
        }

        sections.push({
            content
        });
    });

    // ? Static blocks
    sections.forEach((section) => {
        camelizedData?.staticBlocks.forEach((block) => {
            if (section.content.includes(block.shortcode)) {
                section.items = block?.items;

                // ? First index of [
                const firstIndex = section.content.indexOf('[');
                const lastIndex = section.content.lastIndexOf(']');
                // ? Get content before and after [ ]
                const contentBefore = section.content.substring(0, firstIndex);
                const contentAfter = section.content.substring(lastIndex + 1);

                section.content = contentBefore + contentAfter;
                section.isHasItems = true;
            }
        });
    });

    return {
        headerSection,
        content: transformContent(camelizedData?.content),
        sections
    };
};

export {
    ourBusinessesDataTransformer,
    medicinesServicesDataTransformer,
    tenderServicesDataTransformer,
    searchDataTransformer,
    hereForYouDataTransformer
};
