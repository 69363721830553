import ourBusinessesInitState from './ourBusinessesInitState';
import ourBusinessesActionTypes from './ourBusinessesActionTypes';
import {
    getListOurBusinessesHandler,
    getListMedicinesHandler,
    getListTenderHandler,
    searchDataHandler,
    getHereForYouHandler
} from './ourBusinessesReducerHandler';

const ourBusinessesReducer = (state = ourBusinessesInitState, action) => {
    switch (action.type) {
        case ourBusinessesActionTypes.GET_LIST_OUR_BUSINESSES_SUCCESS:
            return getListOurBusinessesHandler(state, action);

        case ourBusinessesActionTypes.GET_LIST_MEDICINES_SUCCESS:
            return getListMedicinesHandler(state, action);

        case ourBusinessesActionTypes.GET_LIST_TENDER_SUCCESS:
            return getListTenderHandler(state, action);

        case ourBusinessesActionTypes.SEARCH_DATA_SUCCESS:
            return searchDataHandler(state, action);

        case ourBusinessesActionTypes.GET_HERE_FOR_YOU_SUCCESS:
            return getHereForYouHandler(state, action);

        default:
            return state;
    }
};

export default ourBusinessesReducer;
