import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ContactIcon from 'assets/icons/ContactIcon';
import AppointmentIcon from 'assets/icons/AppointmentIcon';
import ReactHtmlParser from 'react-html-parser';
import DefaultDoctorImagePath from 'assets/common/default-doctor.jpg';

const { REACT_APP_DOMAIN_MEDIA } = process.env;
const BaseDoctorCard = ({
    avatar = '',
    name,
    certificates = '',
    specialty = [],
    subspecialties = [],
    position,
    desc,
    phone = '',
    slug = ''
}) => {
    const navigate = useNavigate();
    // ? Render avatar
    const renderAvatar = () => {
        if (avatar.length > 0) {
            return (
                <div
                    className='w-[150px] h-[150px] rounded-full overflow-hidden mx-auto'
                    onClick={handleOnGetAppointment}
                >
                    <img
                        className='w-full h-full object-cover object-top'
                        src={`${REACT_APP_DOMAIN_MEDIA}${avatar}`}
                    />
                </div>
            );
        } else {
            return (
                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mx-auto'>
                    <img
                        className='w-full h-full object-cover object-top'
                        src={DefaultDoctorImagePath}
                    />
                </div>
            );
        }
    };
    // ? Render certificates
    const renderCertificates = () => {
        return <div className={`text-[300] mt-1`}>{ReactHtmlParser(certificates)}</div>;
    };

    // ? Render specialties
    const renderSpecialty = () => {
        return <div>{ReactHtmlParser(specialty)}</div>;
    };

    // ? Render specialties
    const renderSubSpecialty = () => {
        return <div>{ReactHtmlParser(subspecialties)}</div>;
    };

    const renderDescValue = () => {
        if (desc.length > 0) {
            return `KKLIU: ${desc}`;
        }
        return '';
    };

    const handleOnGetAppointment = () => {
        navigate(`/doctor/${slug}`);
    };
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col h-[calc(100%-90px)] items-center justify-between py-4 bg-white text-center'>
                <div className='px-4'>
                    {renderAvatar()}
                    <div className='mt-5'>
                        <div
                            className='text-[18px] font-bold text-main cursor-pointer'
                            onClick={handleOnGetAppointment}
                        >
                            {ReactHtmlParser(name)}
                        </div>

                        {renderCertificates()}
                    </div>
                </div>

                <div className='px-4'>
                    <div className='mt-5'>
                        <div className='text-[#888]'>Specialty</div>
                        <div className='w-[70px] h-[2px] bg-main my-1 mx-auto'></div>
                        {renderSpecialty()}
                        {renderSubSpecialty()}
                    </div>

                    <div>{position}</div>
                </div>
            </div>

            <div className='w-full h-[30px] bg-[#e98076] text-white text-center'>
                {renderDescValue()}
            </div>

            <div className='grid grid-cols-2 text-[14px]'>
                <a href={`tel:${phone}`}>
                    <div className='h-[60px] bg-[#7592db] text-white flex items-center justify-center gap-2 hover:opacity-90 cursor-pointer opacity-[0.95]'>
                        <ContactIcon />
                        <div>Contact</div>
                    </div>
                </a>

                <div
                    className='h-[60px] bg-main text-white flex items-center justify-center gap-2 hover:opacity-90 cursor-pointer'
                    onClick={handleOnGetAppointment}
                >
                    <AppointmentIcon />
                    <div>Get Appointment</div>
                </div>
            </div>
        </div>
    );
};

BaseDoctorCard.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    certificates: PropTypes.string,
    specialty: PropTypes.string,
    subspecialties: PropTypes.string,
    position: PropTypes.string,
    desc: PropTypes.string,
    phone: PropTypes.string,
    slug: PropTypes.string
};

export default BaseDoctorCard;
