import React from 'react';
import PropTypes from 'prop-types';
import BasePackageCard from 'components/BasePackageCard';

const HealthTourismOurPackages = ({ items = [] }) => {
    // ? Render base package card
    const renderBasePackageCard = () => {
        return items.map((item, index) => {
            return (
                <BasePackageCard
                    key={index}
                    title={item.title}
                    image={item.image}
                    price={item.price}
                    slug={item.slug}
                />
            );
        });
    };
    return (
        <div className='px-5 py-14 bg-white grid md:grid-cols-3 grid-cols-1 gap-5'>
            {renderBasePackageCard()}
        </div>
    );
};

HealthTourismOurPackages.propTypes = {
    items: PropTypes.array
};

export default HealthTourismOurPackages;
