import React from 'react';
import PropTypes from 'prop-types';

// ~ Components
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';

const TenderHeaderSection = ({ data = {} }) => {
    return (
        <BaseHeaderWithContentSection
            title={data?.title || 'Tender List'}
            imagePath={ourHospitalsImagePath}
            desc='This page contains the plans for issuance of tenders by KPJ Healthcare
            Berhad. <br/> Companies interested in participating in any of the listed biddings
            should apply in the given link.'
        />
    );
};

TenderHeaderSection.propTypes = {
    data: PropTypes.object
};

export default TenderHeaderSection;
