import React from 'react';

import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';

import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';

const ApplicationsHeaderSection = () => {
    return (
        <BaseHeaderWithContentSection
            title='Consultant Specialist Application'
            imagePath={ourHospitalsImagePath}
        />
    );
};

export default ApplicationsHeaderSection;
