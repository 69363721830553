import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/main.scss';
import App from './pages/App';
import BaseCookiesConsent from 'components/BaseCookiesConsent';
import { HelmetProvider } from 'react-helmet-async';

// ~ Services
import { Provider } from 'react-redux';
import configureAppStore from './store/index';

// Create redux store with history
const initialState = {};
const store = configureAppStore(initialState);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <HelmetProvider>
            <React.StrictMode>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.StrictMode>
        </HelmetProvider>

        <BaseCookiesConsent />
    </Provider>
);

export default store;
