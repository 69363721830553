import metaActionTypes from './metaActionTypes';
import metaInitState from './metaInitState';
import {
    getMetaDataHandler,
    getAllEventsHandler,
    getAllStateHandler,
    getAllSpecialitiesHandler,
    getPageWithSlug
} from './metaReducerHandler';

const metaReducer = (state = metaInitState, action) => {
    switch (action.type) {
        case metaActionTypes.GET_META_DATA_SUCCESS:
            return getMetaDataHandler(state, action);
        case metaActionTypes.GET_ALL_EVENTS_SUCCESS:
            return getAllEventsHandler(state, action);
        case metaActionTypes.GET_ALL_STATE_SUCCESS:
            return getAllStateHandler(state, action);
        case metaActionTypes.GET_ALL_SPECIALITIES_SUCCESS:
            return getAllSpecialitiesHandler(state, action);
        case metaActionTypes.GET_PAGE_WITH_SLUG_SUCCESS:
            return getPageWithSlug(state, action);
        default:
            return state;
    }
};

export default metaReducer;
