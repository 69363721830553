import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_DOMAIN_MEDIA } = process.env;

const BasePackageCard = ({
    title = '',
    image = '',
    price = 0,
    slug = '',
    desc = '',
    classNameImage = ''
}) => {
    const navigate = useNavigate();

    const handleOnLinkPackage = () => {
        navigate(`/packages/${slug}`);
    };

    return (
        <div className='flex flex-col justify-between'>
            <div>
                <div className='py-3 bg-[#7592db] text-white text-center'>{title}</div>

                <div className={`h-[380px] ${classNameImage}`}>
                    <img className='h-full mx-auto' src={`${REACT_APP_DOMAIN_MEDIA}${image}`} />
                </div>

                {desc.length > 0 && (
                    <div className='py-2 p-5 text-[14px]'>{ReactHtmlParser(desc)}</div>
                )}
            </div>

            <div className='flex text-white text-center'>
                <div className='bg-[#7592db] py-2 w-[320px] font-bold'>MYR {price}</div>
                <div className='bg-main py-2 w-full cursor-pointer' onClick={handleOnLinkPackage}>
                    <div>View Detail</div>
                </div>
            </div>
        </div>
    );
};

BasePackageCard.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    slug: PropTypes.string,
    desc: PropTypes.string,
    classNameImage: PropTypes.string
};

export default BasePackageCard;
