import OurServicesApi from 'api/our-services';

import { call, takeLatest, put, fork } from 'redux-saga/effects';

import ourServicesActions from './ourServicesActions';
import ourServicesActionTypes from './ourServicesActionTypes';

/**
 * TODO: Get list services
 */

function* getListServicesHandler() {
    try {
        const response = yield call(OurServicesApi.getListServices);
        if (+response.status === 1) {
            yield put(ourServicesActions.listOurServicesSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get center for sight
 */

function* getCenterForSightHandler() {
    try {
        const response = yield call(OurServicesApi.getCenterForSight);
        if (+response.status === 1) {
            yield put(ourServicesActions.getCenterForSightSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get senior living care
 */

function* getSeniorLivingCareHandler() {
    try {
        const response = yield call(OurServicesApi.getSeniorLivingCare);
        if (+response.status === 1) {
            yield put(ourServicesActions.getSeniorLivingCareSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get laboratory services
 */

function* getLaboratoryServicesHandler() {
    try {
        const response = yield call(OurServicesApi.getLaboratoryServices);
        if (+response.status === 1) {
            yield put(ourServicesActions.getLaboratoryServicesSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get dental speciality center
 */

function* getDentalSpecialityCenterHandler() {
    try {
        const response = yield call(OurServicesApi.getDentalSpecialityCenter);
        if (+response.status === 1) {
            yield put(ourServicesActions.getDentalSpecialityCenterSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get rehabilitation services
 */

function* getRehabilitationServicesHandler() {
    try {
        const response = yield call(OurServicesApi.getRehabilitationCenter);
        if (+response.status === 1) {
            yield put(ourServicesActions.getRehabilitationCenterSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get bariatric surgery handler
 */

function* getBariatricSurgeryHandler() {
    try {
        const response = yield call(OurServicesApi.getBariatricSurgery);
        if (+response.status === 1) {
            yield put(ourServicesActions.getBariatricSurgerySuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

function* watchOurServicesSaga() {
    yield takeLatest(ourServicesActionTypes.LIST_OUR_SERVICES_REQUEST, getListServicesHandler);
    yield takeLatest(ourServicesActionTypes.GET_CENTER_FOR_SIGHT_REQUEST, getCenterForSightHandler);
    yield takeLatest(
        ourServicesActionTypes.GET_SENIOR_LIVING_CARE_REQUEST,
        getSeniorLivingCareHandler
    );
    yield takeLatest(
        ourServicesActionTypes.GET_LABORATORY_SERVICES_REQUEST,
        getLaboratoryServicesHandler
    );

    yield takeLatest(
        ourServicesActionTypes.GET_DENTAL_SPECIALITY_CENTER_REQUEST,
        getDentalSpecialityCenterHandler
    );

    yield takeLatest(
        ourServicesActionTypes.GET_REHABILITATION_CENTER_REQUEST,
        getRehabilitationServicesHandler
    );

    yield takeLatest(
        ourServicesActionTypes.GET_BARIATRIC_SURGERY_REQUEST,
        getBariatricSurgeryHandler
    );
}

export default function* rootOurServicesSaga() {
    yield fork(watchOurServicesSaga);
}
