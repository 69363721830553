import React from 'react';
import PropTypes from 'prop-types';
import Item1ImagePath from 'assets/homepage/item-1.png';
import Item2ImagePath from 'assets/homepage/item-2.png';
import Item3ImagePath from 'assets/homepage/item-3.png';
import Item4ImagePath from 'assets/homepage/item-4.png';
import Item5ImagePath from 'assets/homepage/item-5.png';
import Item6ImagePath from 'assets/homepage/item-6.png';

import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const itemsSlider = [
    {
        path: Item1ImagePath,
        alt: 'item-1'
    },
    {
        path: Item2ImagePath,
        alt: 'item-2'
    },
    {
        path: Item3ImagePath,
        alt: 'item-3'
    },
    {
        path: Item4ImagePath,
        alt: 'item-4'
    },
    {
        path: Item5ImagePath,
        alt: 'item-5'
    },
    {
        path: Item6ImagePath,
        alt: 'item-6'
    }
];

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                left: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                background: '#c9c9c9'
            }}
            onClick={onClick}
        >
            <GrFormPrevious
                fontSize='28px'
                color='rgba(0,0,0,0.9)'
                style={{
                    opacity: 0.8
                }}
            />
        </div>
    );
};

PrevArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                right: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                background: '#c9c9c9'
            }}
            onClick={onClick}
        >
            <GrFormNext
                fontSize='28px'
                color='rgba(0,0,0,0.9)'
                style={{
                    opacity: 0.8
                }}
            />
        </div>
    );
};

NextArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

const HomepageWhyUs = () => {
    const settings = {
        className: '',
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 8000,
        speed: 1000,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    // ? Render carousel items
    const renderCarouselItems = () => {
        return itemsSlider.map((item) => {
            return (
                <img
                    src={item.path}
                    alt='...'
                    key={item.alt}
                    className='object-cover w-full h-[330px] xl:h-[450px] 2xl:h-[600px]'
                />
            );
        });
    };
    return (
        <div className='container mx-auto md:my-12 my-5'>
            <div className='text-headline-2 text-center font-bold relative mb-6'>
                <span>Why Us</span>
                <span className='absolute -bottom-2 w-[30px] h-[2px] bg-main left-1/2 -translate-x-1/2'></span>
            </div>

            <div className='text-center leading-4 md:mb-8 text-[18px]'>
                We take care of you every stage of life
            </div>
            <div className='mt-10'>
                <Slider {...settings}>{renderCarouselItems()}</Slider>
            </div>
        </div>
    );
};

export default HomepageWhyUs;
