import ContactUsServices from 'api/contact-us';
import { call, takeLatest, put, fork } from 'redux-saga/effects';

import contactUsActions from './contactUsActions';
import contactUsActionTypes from './contactUsActionTypes';

/**
 * TODO: Get contact us data handler
 */

function* getContactUsDataHandler() {
    try {
        const response = yield call(ContactUsServices.getContactUsData);
        if (+response.status === 1) {
            yield put(contactUsActions.getContactUsDataSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Submit contact us form handler
 */

function* submitContactUsFormHandler({ payload }) {
    try {
        yield put(contactUsActions.startSubmittingResume());
        const response = yield call(ContactUsServices.submitContactUsForm, payload);
        console.log({response});
        if (+response.status === 1) {
            yield put(contactUsActions.submitContactUsFormSuccess(response));
        }
    } catch (error) {
        console.log({error});
        return error;
    }
}

/**
 * TODO: Get care card program handler
 */

function* getCareCardProgramHandler() {
    try {
        const response = yield call(ContactUsServices.getCareCardProgram);
        if (+response.status === 1) {
            yield put(contactUsActions.getCareCardProgramSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get list careers handler
 */

function* getListCareersHandler() {
    try {
        const queryParams = {
            page: 1,
            limit: 100
        };
        const response = yield call(ContactUsServices.getListCareers, queryParams);
        if (+response.status === 1) {
            yield put(contactUsActions.getListCareersSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Submit resume handler
 */

function* submitResumeHandler({ payload }) {
    try {
        yield put(contactUsActions.startSubmittingResume());
        const response = yield call(ContactUsServices.submitResume, payload);
        if (+response.status === 1) {
            yield put(contactUsActions.submitResumeSuccess(response));
        }
    } catch (error) {
        yield put(contactUsActions.submitResumeFailure(error));
    }
}

/**
 * TODO: Submit applications form handler
 */

function* submitApplicationsFormHandler({ payload }) {
    try {
        yield put(contactUsActions.startSubmittingResume());
        const response = yield call(ContactUsServices.submitApplicationsForm, payload);
        if (+response.status === 1) {
            yield put(contactUsActions.submitApplicationsFormSuccess(response));
        }
    } catch (error) {
        yield put(contactUsActions.submitApplicationsFormFailure(error));
    }
}

function* watchContactUsSaga() {
    yield takeLatest(contactUsActionTypes.GET_CONTACT_US_DATA_REQUEST, getContactUsDataHandler);
    yield takeLatest(
        contactUsActionTypes.SUBMIT_CONTACT_US_FORM_REQUEST,
        submitContactUsFormHandler
    );
    yield takeLatest(contactUsActionTypes.GET_CARE_CARD_PROGRAM_REQUEST, getCareCardProgramHandler);
    yield takeLatest(contactUsActionTypes.GET_LIST_CAREERS_REQUEST, getListCareersHandler);
    yield takeLatest(contactUsActionTypes.SUBMIT_RESUME_REQUEST, submitResumeHandler);
    yield takeLatest(
        contactUsActionTypes.SUBMIT_APPLICATIONS_REQUEST,
        submitApplicationsFormHandler
    );
}

export default function* rootContactUsSaga() {
    yield fork(watchContactUsSaga);
}
