import React from 'react';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import HealthTourismHeaderImage from 'assets/common/subheader-health.png';

const healthTourismData = {
    title: 'Health Tourism',
    desc: 'We are committed to provide the very best with the latest specialty health services and facilities to our international patients. Our Health Tourism team are committed to ensure an exceptional healthcare destination experience with limitless possibilities and exceptional quality catered to your needs.'
};
const HealthTourismHeaderSection = () => {
    return (
        <BaseHeaderWithContentSection
            title={healthTourismData.title}
            desc={healthTourismData.desc}
            imagePath={HealthTourismHeaderImage}
        />
    );
};

export default HealthTourismHeaderSection;
