import { camelize } from 'transformer/common';
import { healthTourismDataTransformer } from 'transformer/health-tourism';
/**
 * TODO: Get health tourism handler
 */

const getHealthTourismHandler = (state, { payload }) => {
    const payloadTransformed = healthTourismDataTransformer(camelize(payload));
    return {
        ...state,
        healthTourismLocation: payloadTransformed.location,
        healthTourismTabsData: payloadTransformed.tabsData,
        metaData: payloadTransformed.metaData
    };
};

export { getHealthTourismHandler };
