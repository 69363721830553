import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BaseDropdownMenu from 'components/BaseDropdownMenu';
import { NavLink } from 'react-router-dom';

const TheSideNavBar = ({ menus = [] }) => {
    const currentPath = window.location.pathname.slice(1);

    const [isActivePath, setIsActivePath] = useState(currentPath);
    // ? Render navbar
    const renderSingleItem = (item) => {
        return (
            <NavLink
                key={item.name}
                to={item.path}
                className={`py-2 relative group ${
                    item.path === isActivePath ? 'font-bold border-b-[2px] border-b-white py-2' : ''
                }`}
                onClick={() => setIsActivePath(item.path)}
            >
                {item.name}
                <span
                    className={`absolute -bottom-[0] left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full ${
                        item.path === isActivePath && 'hidden'
                    }`}
                />
            </NavLink>
        );
    };

    // ? Render all the nav bar items
    const renderNavBarItems = () => {
        if (menus.length > 0) {
            return menus.map((item, idx) => {
                return item.childItems ? (
                    <BaseDropdownMenu item={item} key={idx} />
                ) : (
                    renderSingleItem(item)
                );
            });
        }
    };

    return (
        <div className='mt-4 flex justify-end pb-3 text-body-3 gap-6'>{renderNavBarItems()}</div>
    );
};

TheSideNavBar.propTypes = {
    menus: PropTypes.array
};

export default TheSideNavBar;
