import { createSelector } from '@reduxjs/toolkit';
import packageInitState from './packagesInitState';

const selectGlobal = (state) => {
    return state.packages || packageInitState;
};

// ? Make selector the package details
const makeSelectPackageDetails = createSelector(selectGlobal, (globalState) => {
    return globalState.packageDetailsData;
});

export { makeSelectPackageDetails };
