import React from 'react';
import PropTypes from 'prop-types';
//import './brochure.css';

const Brochure = ({ item }) => {
  const _col = item.item_per_row ?? 3;
  let class_column = 'md:col-span-4'; // Default class
  switch (_col) {
    case 1:
      class_column = 'md:col-span-12';
      break;
    case 2:
      class_column = 'md:col-span-6';
      break;
    case 4:
      class_column = 'md:col-span-3';
      break;
    default:
      break;
  }
  const brochureItems = item.items.map((_item) => {
    const link = _item.link || '#';
    const imageUrl = `https://media.kpjhealth.com.my/${_item.image}`;
    return (
      <div key={_item.id} className={`img-container brochure-item`}>
        <div className={`img-item ${class_column}`}>
          <div>
            <img
              data-lightbox='true'
              className='lightbox-image object-cover' 
              src={imageUrl}
              alt='Brochure Image'
            />
          </div>
          <p>
            <a href={link} target='_blank' rel='noreferrer'>
              More
            </a>
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className={`max-w-xs gallery brochure-container lcms-gallery-contents lcms-gallery-contents-${item.id}`} style={{ display: 'flex', gap: '50px'}}>
      <div id='lightbox' className='lightbox' style={{ display: 'none' }}>
        <img id='lightbox-image' className='lightbox-image' src='' alt='Lightbox Image' />
      </div>
      {brochureItems}
    </div>
  );
};

Brochure.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        image_thumb: PropTypes.string,
        price: PropTypes.number,
      })
    ),
    item_per_row: PropTypes.number,
    id: PropTypes.number,
  }),
};

export default Brochure;
