const ourBusinessesActionTypes = {
    GET_LIST_OUR_BUSINESSES_REQUEST: 'GET_LIST_OUR_BUSINESSES_REQUEST',
    GET_LIST_OUR_BUSINESSES_SUCCESS: 'GET_LIST_OUR_BUSINESSES_SUCCESS',
    GET_LIST_OUR_BUSINESSES_FAILURE: 'GET_LIST_OUR_BUSINESSES_FAILURE',

    // ? Get list medicines
    GET_LIST_MEDICINES_REQUEST: 'GET_LIST_MEDICINES_REQUEST',
    GET_LIST_MEDICINES_SUCCESS: 'GET_LIST_MEDICINES_SUCCESS',
    GET_LIST_MEDICINES_FAILURE: 'GET_LIST_MEDICINES_FAILURE',

    // ? Get list tender
    GET_LIST_TENDER_REQUEST: 'GET_LIST_TENDER_REQUEST',
    GET_LIST_TENDER_SUCCESS: 'GET_LIST_TENDER_SUCCESS',
    GET_LIST_TENDER_FAILURE: 'GET_LIST_TENDER_FAILURE',

    // ? Search data
    SEARCH_DATA_REQUEST: 'SEARCH_DATA_REQUEST',
    SEARCH_DATA_SUCCESS: 'SEARCH_DATA_SUCCESS',
    SEARCH_DATA_FAILURE: 'SEARCH_DATA_FAILURE',

    // ? Get here for you
    GET_HERE_FOR_YOU_REQUEST: 'GET_HERE_FOR_YOU_REQUEST',
    GET_HERE_FOR_YOU_SUCCESS: 'GET_HERE_FOR_YOU_SUCCESS',
    GET_HERE_FOR_YOU_FAILURE: 'GET_HERE_FOR_YOU_FAILURE'
};

export default ourBusinessesActionTypes;
