import React from 'react';
import PropTypes from 'prop-types';
import BaseCareerCard from 'components/BaseCareerCard';
import ReactPaginate from 'react-paginate';
import { BrowserView, MobileView } from 'react-device-detect';
// import ReactHtmlParser from 'react-html-parser';

const TenderLayout = ({ data = {}, onChange = () => {} }) => {
    // ? Render sections
    const renderSections = () => {
        if (data.items) {
            if (data.items.length > 0) {
                const { items } = data;

                return items.map((item, idx) => {
                    return (
                        <BaseCareerCard
                            key={idx}
                            data={item}
                            isShowLocation={false}
                            title='Project Name'
                        />
                    );
                });
            } else {
                return (
                    <div className='p-5 text-[18px] text-center'>
                        There is no active tender now. Please check back on a later date.
                    </div>
                );
            }
        }
    };

    const handleOnChangePagination = (data) => {
        onChange(data.selected + 1);
    };

    const renderPagination = () => {
        if (data.paginator && data.items && data.items.length > 0) {
            const pagination = data.paginator;
            return (
                <>
                    <BrowserView>
                        <div className='pagination'>
                            <ReactPaginate
                                pageCount={pagination?.totalPages || 1}
                                pageRangeDisplayed={2}
                                className='flex justify-center'
                                pageClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                                pageLinkClassName='w-full h-full flex justify-center items-center'
                                previousClassName='ml-0 rounded-l-lg border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                                previousLinkClassName='w-full h-full flex justify-center items-center py-2 px-3 '
                                nextClassName='rounded-r-lg border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                                nextLinkClassName='w-full h-full flex justify-center items-center py-2 px-3 '
                                breakClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                                breakLinkClassName='w-full h-full flex justify-center items-center'
                                activeClassName='bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                marginPagesDisplayed={1}
                                onPageChange={handleOnChangePagination}
                            />
                        </div>
                    </BrowserView>

                    <MobileView>
                        <div className='pagination pagination-mobile'>
                            <ReactPaginate
                                pageCount={pagination?.totalPages || 1}
                                pageRangeDisplayed={1}
                                className='flex justify-center'
                                pageClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                                pageLinkClassName='w-full h-full flex justify-center items-center'
                                previousClassName='hidden'
                                previousLinkClassName='hidden'
                                nextClassName='hidden'
                                nextLinkClassName='hidden'
                                breakClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                                breakLinkClassName='w-full h-full flex justify-center items-center'
                                activeClassName='bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                marginPagesDisplayed={1.5}
                                onPageChange={handleOnChangePagination}
                            />
                        </div>
                    </MobileView>
                </>
            );
        }
    };

    return (
        <div className='container mx-auto pt-8 pb-4 px-4 md:px-[80px]'>
            <div className='bg-white'>{renderSections()}</div>

            <div className='mt-4'>{renderPagination()}</div>
        </div>
    );
};

TenderLayout.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func
};

export default TenderLayout;
