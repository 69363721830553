const OUR_SERVICES_CONSTANTS = {
    LIST_SERVICES: 'v1/page/our-services'
    // CENTER_FOR_SIGHT: 'v1/page/centre-for-sight',
    // SENIOR_LIVING_CARE: 'v1/page/senior-living-care',
    // LABORATORY_SERVICES: 'v1/page/laboratory-services',
    // DENTAL_SPECIALITY_CENTER: 'v1/page/dental-specialist-centre',
    // REHAABILITATION_CENTER: 'v1/page/rehabilitation-centre',
    // BARIATRIC_SURGERY: 'v1/page/bariatric-surgery'
};

export default OUR_SERVICES_CONSTANTS;
