import { camelize } from 'transformer/common';
import { transformContent } from 'utils';

const hardCodeDom =
    "<div><iframe width='100%' height='640' src='https://maps.google.com/maps?q=Menara+KPJ%2C+Level+12%2C+238%2C+Jln+Tun+Razak%2C+50400+Kuala+Lumpur&amp;height=615&amp;z=14&amp;w=100%&amp;hl=en&amp;ie=UTF8&amp;iwloc=B&amp;output=embed' frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0'></iframe></div>";

const contactUsLocationDataTransformer = (data, staticBlocks) => {
    let location = {};
    let contents = data.content;
    location.column = data.column;

    // ? Left section
    if (contents[0]) {
        location.leftSection = contents[0];
    }

    // ? Right section
    if (contents[1]) {
        staticBlocks.forEach((block) => {
            if (contents[1].includes(block.shortcode)) {
                location.rightSection = hardCodeDom;
            }
        });
    }

    return location;
};

const contactUsFormDataTransformer = (data) => {
    return {
        title: data.title,
        items: data.items,
        options: data.options,
        id: data.id
    };
};

const contactUsDataTransformer = (data) => {
    const contactUsData = {
        location: {},
        form: {}
    };

    const staticBlocks = data.staticBlocks;

    if (data.sections) {
        // ~ Location
        contactUsData.location = contactUsLocationDataTransformer(data.sections[0], staticBlocks);
        contactUsData.form = contactUsFormDataTransformer(staticBlocks[1]);
    }
    return contactUsData;
};

// ? Care card data header section
const careCardDataHeaderSection = (data) => {
    // ? Header section

    let headerContent = transformContent(data.content);

    // ? First index of [
    const firstIndex = headerContent.indexOf('[');
    const lastIndex = headerContent.lastIndexOf(']');
    // ? Get content before and after [ ]
    const contentBefore = headerContent.substring(0, firstIndex);
    const contentAfter = headerContent.substring(lastIndex + 1);

    const headerSection = {
        title: data.title,
        desc: data.intro,
        content: contentBefore + contentAfter
    };

    return headerSection;
};

// ? Get care card data transformer
const careCardDataTransformer = (data) => {
    const camilizeData = camelize(data);

    // ~ Sections
    let sections = [];
    const staticBlocks = camilizeData?.staticBlocks;

    staticBlocks.forEach((staticBlock) => {
        if (camilizeData.content.includes(staticBlock.shortcode)) {
            let staticBlockItems = staticBlock.items;

            staticBlockItems.forEach((item) => {
                sections[+item.ordering] = item;
            });
        }
    });

    // ~ Sections data
    staticBlocks.forEach((staticBlock) => {
        sections.forEach((section, idx) => {
            if (section?.content?.includes(staticBlock.shortcode)) {
                section.content = section.content.replace(
                    staticBlock.shortcode,
                    staticBlock.content
                );

                staticBlock.items.map((item) => {
                    item.content = transformContent(item.content);
                    return item;
                });

                sections[idx].contentItems = staticBlock.items;
                sections[idx].itemPerRow =
                    staticBlock.itemPerRow.length > 0 ? +staticBlock.itemPerRow : 3;
            }
        });
    });

    const metaData = {
        title: camilizeData.metaTitle,
        description: camilizeData.metaDescription,
        keywords: camilizeData.metaKeywords
    };
    return {
        headerSection: careCardDataHeaderSection(camilizeData),
        sections,
        metaData
    };
};

// ? List careers data transformer
const listCareersDataTransformer = (data) => {
    const camilizeData = camelize(data);
    let items = camilizeData.items;

    items.map((item) => {
        item.details = transformContent(item.details);

        return item;
    });

    return {
        items,
        paginator: camilizeData?.paginator
    };
};

export { contactUsDataTransformer, careCardDataTransformer, listCareersDataTransformer };
