import React from 'react';

const ContactIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.28 12.76' width={13} height={13}>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <path
                        fill='#fff'
                        d='M3.51,5.21A16.93,16.93,0,0,0,7.56,9.26c.12-.18.23-.34.35-.5A1.38,1.38,0,0,1,10,8.39l1.62,1.13a1.36,1.36,0,0,1,.47,1.85A2.44,2.44,0,0,1,10,12.74a5.56,5.56,0,0,1-3-.63A13.17,13.17,0,0,1,.61,5.71,5.57,5.57,0,0,1,0,2.9,2.47,2.47,0,0,1,1.54.61a1.35,1.35,0,0,1,1.62.44c.45.61.89,1.24,1.31,1.88a1.33,1.33,0,0,1-.32,1.81C4,4.9,3.73,5,3.51,5.21Z'
                    />
                    <path
                        fill='#fff'
                        d='M9.71,0h3.16c.33,0,.41.08.41.41V4c0,.31-.08.39-.39.39H9.36a.22.22,0,0,0-.24.14l-.55,1.2A.31.31,0,0,1,8.29,6,.3.3,0,0,1,8,5.74c-.19-.4-.38-.8-.56-1.2a.2.2,0,0,0-.23-.14H6.46c-.23,0-.33-.1-.33-.34V.35c0-.26.1-.35.37-.35Zm0,1.92c.62,0,1.24,0,1.87,0,.19,0,.3-.09.32-.24s-.12-.3-.34-.3H7.9c-.15,0-.28,0-.35.17s0,.38.29.38C8.46,1.93,9.08,1.92,9.7,1.92Zm0,1.1h1.86a.28.28,0,0,0,.32-.26c0-.16-.12-.28-.32-.29H7.85c-.16,0-.28.06-.32.22s.09.33.31.33Z'
                    />
                </g>
            </g>
        </svg>
    );
};

export default ContactIcon;
