const META_CONSTANTS = {
    GET_META: 'v1/site',
    EVENTS: 'v1/events',
    LOGIN: 'authclient/login',
    LIST_STATE: 'v1/states/132',
    LIST_SPECIALITIES: 'v1/specialties',
    PAGE_SLUG: 'v1/page/'
};

export default META_CONSTANTS;
