import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import FacebookIcon from 'assets/icons/FacebookIcon';
import InstagramIcon from 'assets/icons/InstagramIcon';
import TiktokIcon from 'assets/icons/TiktokIcon';
import LinkedinIcon from 'assets/icons/LinkedinIcon';
import TwitterIcon from 'assets/icons/TwitterIcon';
import WhatsappIcon from 'assets/icons/WhatsappIcon';
import YoutubeIcon from 'assets/icons/YoutubeIcon';
import TelegramIcon from 'assets/icons/TelegramIcon';

const FooterMenu = ({ menus = [], social = {} }) => {
    const [email, setEmail] = useState('');
    // ? Render items
    const renderItems = (items) => {
        return items.map((item, idx) => {
            if (item.path.includes('https')) {
                return (
                    <a href={item.path} key={idx}>
                        <div>{ReactHtmlParser(item.name)}</div>
                    </a>
                );
            } else {
                return (
                    <Link to={item.path} key={idx} className='text-start'>
                        <div>{ReactHtmlParser(item.name)}</div>
                    </Link>
                );
            }
        });
    };
    // ? Render menu single section
    const renderMenuSingleSection = (title, items) => {
        return (
            <div>
                <div className='text-[16px] font-[600]'>{title}</div>
                <div className='mt-3 text-[14px]'>{renderItems(items)}</div>
            </div>
        );
    };

    // ? Render menu sections
    const renderMenuSections = () => {
        return menus.map((section, idx) => {
            return (
                <React.Fragment key={idx}>
                    {renderMenuSingleSection(section.title, section.items)}
                </React.Fragment>
            );
        });
    };

    const handleOnChange = (e) => {
        setEmail(e.target.value);
    };
    return (
        <div className='container mx-auto mt-5 flex md:flex-row flex-col text-white'>
            <div className='min-w-[320px] footer-subscribe md:py-10 py-0 px-4'>
                <div className='text-[16px] font-[600]'> Get our latest updates</div>
                <div className='md:mt-5 mt-3 text-[14px]'>
                    Subscribe to our newsletter and get our latest updates.
                </div>
                <form
                    action='https://kpjhealth.us5.list-manage.com/subscribe/post?u=d4f09d8c9a7cc4c525fca3f8d&id=0cf962026f'
                    method='post'
                    className='md:mt-5 mt-3 flex gap-2'
                >
                    <TextInput
                        placeholder='Email address'
                        type='email'
                        value={email}
                        onChange={handleOnChange}
                    />
                    <Button type='submit' color='dark' pill={true} className='rounded-[8px]'>
                        Subscribe
                    </Button>
                </form>
            </div>

            <div className='md:px-[20px] px-4 py-4 bg-main text-white sm:hidden md:hidden'>
            {
                    social.facebookLink
                    ?
                    <a className='float-left mr-4' href={social.facebookLink} target='_blank' rel='noreferrer'>
                        <FacebookIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.instagramLink
                    ?
                    <a className='float-left mr-4' href={social.instagramLink} target='_blank' rel='noreferrer'>
                        <InstagramIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.tiktokLink
                    ?
                    <a className='float-left mr-4' href={social.tiktokLink} target='_blank' rel='noreferrer'>
                        <TiktokIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.linkedInLink
                    ?
                    <a className='float-left mr-4' href={social.linkedInLink} target='_blank' rel='noreferrer'>
                        <LinkedinIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.twitterLink
                    ?
                    <a className='float-left mr-4' href={social.twitterLink} target='_blank' rel='noreferrer'>
                        <TwitterIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.whatsappLink
                    ?
                    <a className='float-left mr-4' href={social.whatsappLink} target='_blank' rel='noreferrer'>
                        <WhatsappIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.youtubeLink
                    ?
                    <a className='float-left mr-4' href={social.youtubeLink} target='_blank' rel='noreferrer'>
                        <YoutubeIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }

                    {
                    social.telegramLink
                    ?
                    <a className='float-left mr-4' href={social.telegramLink} target='_blank' rel='noreferrer'>
                        <TelegramIcon className='cursor-pointer hover:scale-105 transition duration-150 ease-out' />
                    </a>
                    :
                    ""
                    }
            </div>

            <div className='md:px-[20px] px-4 py-8 bg-main text-white grid md:grid-cols-3 grid-cols-1 gap-6 flex-grow'>
                {renderMenuSections()}
            </div>
        </div>
    );
};

FooterMenu.propTypes = {
    menus: PropTypes.array,
    social: PropTypes.object
};

export default FooterMenu;
