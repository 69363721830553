import store from 'index';
import rootCsrSaga from './csrSagas';
import csrReducer from './csrReducer';

const key = 'csr';

export const useCsrSlice = () => {
    store.injectReducer(key, csrReducer);
    store.injectSaga(key, rootCsrSaga);
};
