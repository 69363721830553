import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReactHtmlParser from 'react-html-parser';
import { BrowserView, MobileView } from 'react-device-detect';
import ReactPaginate from 'react-paginate';

// ~ Components
import { Select } from 'flowbite-react';
import BaseCsrCard from 'components/BaseCsrCard';

const NewsContentLayout = ({
    items = [],
    pagination = {},
    allYears = [],
    allCategories = [],
    onChange = () => {},
    onFilter = () => {}
}) => {
    const [yearFilter, setYearFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');

    // ? Render all years options
    const renderAllYearsOptions = (items = []) => {
        if (items.length > 0) {
            return items.map((item, idx) => (
                <option key={idx} value={item.year}>
                    {ReactHtmlParser(item.year)}
                </option>
            ));
        } else {
            return <option>No data</option>;
        }
    };

    // ? Render all categories options
    const renderAllCategoriesOptions = (items = []) => {
        if (items.length > 0) {
            return items.map((item, idx) => (
                <option key={idx} value={item.id}>
                    {ReactHtmlParser(item.name)}
                </option>
            ));
        } else {
            return <option>No data</option>;
        }
    };

    // ? Actions
    const handleOnSelectYear = (e) => {
        setYearFilter(e.target.value);
        setCategoryFilter('');
        onFilter({
            year: e.target.value,
            category: ''
        });
    };

    const handleOnSelectCategory = (e) => {
        setYearFilter('');
        setCategoryFilter(e.target.value);
        onFilter({
            year: '',
            category: e.target.value
        });
    };

    // ? Render filter
    const renderFilter = () => {
        return (
            <>
                <div className='pb-5 flex gap-5'>
                    <Select
                        value={yearFilter}
                        className='md:w-[200px] w-full filter-header'
                        onChange={handleOnSelectYear}
                    >
                        <option value=''>All Year</option>
                        {renderAllYearsOptions(allYears)}
                    </Select>

                    <Select
                        value={categoryFilter}
                        className='md:w-[450px] w-full filter-header'
                        onChange={handleOnSelectCategory}
                    >
                        <option value=''>All Categories</option>
                        {renderAllCategoriesOptions(allCategories)}
                    </Select>
                </div>
            </>
        );
    };

    // ? Render cards
    const renderCards = () => {
        if (items.length > 0) {
            return items.map((item, index) => {
                return (
                    <BaseCsrCard
                        title={item?.title}
                        imagePath={item?.image}
                        date={item?.date?.date}
                        slug={item?.slug}
                        key={index}
                    />
                );
            });
        }
    };

    const handleOnChangePagination = (data) => {
        onChange(data.selected + 1);
    };

    // ? Render pagination
    const renderPagination = () => {
        return (
            <>
                <BrowserView>
                    <div className='pagination mt-2'>
                        <ReactPaginate
                            pageCount={pagination?.totalPages || 1}
                            pageRangeDisplayed={2}
                            className='flex justify-center'
                            pageClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            pageLinkClassName='w-full h-full flex justify-center items-center'
                            previousClassName='ml-0 rounded-l-lg border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            previousLinkClassName='w-full h-full flex justify-center items-center py-2 px-3 '
                            nextClassName='rounded-r-lg border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            nextLinkClassName='w-full h-full flex justify-center items-center py-2 px-3 '
                            breakClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            breakLinkClassName='w-full h-full flex justify-center items-center'
                            activeClassName='bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                            marginPagesDisplayed={1}
                            onPageChange={handleOnChangePagination}
                        />
                    </div>
                </BrowserView>

                <MobileView>
                    <div className='pagination pagination-mobile mt-2'>
                        <ReactPaginate
                            pageCount={pagination?.totalPages || 1}
                            pageRangeDisplayed={1}
                            className='flex justify-center'
                            pageClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            pageLinkClassName='w-full h-full flex justify-center items-center'
                            previousClassName='hidden'
                            previousLinkClassName='hidden'
                            nextClassName='hidden'
                            nextLinkClassName='hidden'
                            breakClassName='w-12 border border-gray-300 bg-white leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-center'
                            breakLinkClassName='w-full h-full flex justify-center items-center'
                            activeClassName='bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                            marginPagesDisplayed={1.5}
                            onPageChange={handleOnChangePagination}
                        />
                    </div>
                </MobileView>
            </>
        );
    };

    return (
        <div className='container mx-auto py-5 pb-0 px-4 md:px-[80px]'>
            {renderFilter()}

            {/* Body */}
            <div className='bg-white p-5'>
                <div className='text-[24px] relative font-bold after after:content-[""] after:h-[2px] after:bg-main after:w-[60px] after:absolute after:left-0 after:bottom-[-8px]'>
                    Latest News
                </div>
                <div className='grid md:grid-cols-3 grid-cols-1 gap-5 mt-10'>{renderCards()}</div>
            </div>

            {renderPagination()}
        </div>
    );
};

NewsContentLayout.propTypes = {
    items: PropTypes.array,
    pagination: PropTypes.object,
    onChange: PropTypes.func,
    allYears: PropTypes.array,
    allCategories: PropTypes.array,
    onFilter: PropTypes.func
};

export default NewsContentLayout;
