import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ width = 18, height = 18, className = '' }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 19.73 19.73'
            width={width}
            height={height}
            className={className}
        >
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <path
                        fill='#fff'
                        d='M19.73,19.07a1,1,0,0,1-.44.56.79.79,0,0,1-.86-.06l-.23-.2-5.29-5.3-.18-.2-.33.24a7.47,7.47,0,0,1-3.73,1.45,7.54,7.54,0,0,1-5.81-1.73,7.63,7.63,0,0,1-2.79-5A7.49,7.49,0,0,1,1.34,3.46,7.65,7.65,0,0,1,6.81.07L7.28,0h1c.23,0,.45.05.67.09a7.68,7.68,0,0,1,4.61,2.5,7.62,7.62,0,0,1,1.9,6.53,7.4,7.4,0,0,1-1.42,3.32l-.22.3a1,1,0,0,1,.19.15l5.2,5.2a6.28,6.28,0,0,1,.48.64ZM14,7.81A6.17,6.17,0,1,0,7.81,14,6.18,6.18,0,0,0,14,7.81Z'
                    />
                </g>
            </g>
        </svg>
    );
};

SearchIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string
};
export default SearchIcon;
