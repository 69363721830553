import ourServicesActionTypes from './ourServicesActionTypes';

const listOurServices = () => ({
    type: ourServicesActionTypes.LIST_OUR_SERVICES_REQUEST
});

const listOurServicesSuccess = (payload) => ({
    type: ourServicesActionTypes.LIST_OUR_SERVICES_SUCCESS,
    payload
});

const listOurServicesFailure = (error) => ({
    type: ourServicesActionTypes.LIST_OUR_SERVICES_FAILURE,
    payload: error
});

// ? Get center for sight
const getCenterForSight = () => ({
    type: ourServicesActionTypes.GET_CENTER_FOR_SIGHT_REQUEST
});

const getCenterForSightSuccess = (payload) => ({
    type: ourServicesActionTypes.GET_CENTER_FOR_SIGHT_SUCCESS,
    payload
});

const getCenterForSightFailure = (error) => ({
    type: ourServicesActionTypes.GET_CENTER_FOR_SIGHT_FAILURE,
    payload: error
});

// ? Get senior living care
const getSeniorLivingCare = () => ({
    type: ourServicesActionTypes.GET_SENIOR_LIVING_CARE_REQUEST
});

const getSeniorLivingCareSuccess = (payload) => ({
    type: ourServicesActionTypes.GET_SENIOR_LIVING_CARE_SUCCESS,
    payload
});

const getSeniorLivingCareFailure = (error) => ({
    type: ourServicesActionTypes.GET_SENIOR_LIVING_CARE_FAILURE,
    payload: error
});

// ? Get laboratory services
const getLaboratoryServices = () => ({
    type: ourServicesActionTypes.GET_LABORATORY_SERVICES_REQUEST
});

const getLaboratoryServicesSuccess = (payload) => ({
    type: ourServicesActionTypes.GET_LABORATORY_SERVICES_SUCCESS,
    payload
});

const getLaboratoryServicesFailure = (error) => ({
    type: ourServicesActionTypes.GET_LABORATORY_SERVICES_FAILURE,
    payload: error
});

// ? Get dental speciality center
const getDentalSpecialityCenter = () => ({
    type: ourServicesActionTypes.GET_DENTAL_SPECIALITY_CENTER_REQUEST
});

const getDentalSpecialityCenterSuccess = (payload) => ({
    type: ourServicesActionTypes.GET_DENTAL_SPECIALITY_CENTER_SUCCESS,
    payload
});

const getDentalSpecialityCenterFailure = (error) => ({
    type: ourServicesActionTypes.GET_DENTAL_SPECIALITY_CENTER_FAILURE,
    payload: error
});

// ? Get rehabilitation center
const getRehabilitationCenter = () => ({
    type: ourServicesActionTypes.GET_REHABILITATION_CENTER_REQUEST
});

const getRehabilitationCenterSuccess = (payload) => ({
    type: ourServicesActionTypes.GET_REHABILITATION_CENTER_SUCCESS,
    payload
});

const getRehabilitationCenterFailure = (error) => ({
    type: ourServicesActionTypes.GET_REHABILITATION_CENTER_FAILURE,
    payload: error
});

// ? Get bariatric surgery
const getBariatricSurgery = () => ({
    type: ourServicesActionTypes.GET_BARIATRIC_SURGERY_REQUEST
});

const getBariatricSurgerySuccess = (payload) => ({
    type: ourServicesActionTypes.GET_BARIATRIC_SURGERY_SUCCESS,
    payload
});

const getBariatricSurgeryFailure = (error) => ({
    type: ourServicesActionTypes.GET_BARIATRIC_SURGERY_FAILURE,
    payload: error
});

export default {
    listOurServices,
    listOurServicesSuccess,
    listOurServicesFailure,
    getCenterForSight,
    getCenterForSightSuccess,
    getCenterForSightFailure,
    getSeniorLivingCare,
    getSeniorLivingCareSuccess,
    getSeniorLivingCareFailure,
    getLaboratoryServices,
    getLaboratoryServicesSuccess,
    getLaboratoryServicesFailure,
    getDentalSpecialityCenter,
    getDentalSpecialityCenterSuccess,
    getDentalSpecialityCenterFailure,
    getRehabilitationCenter,
    getRehabilitationCenterSuccess,
    getRehabilitationCenterFailure,
    getBariatricSurgery,
    getBariatricSurgerySuccess,
    getBariatricSurgeryFailure
};
