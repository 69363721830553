const contactUsActionTypes = {
    GET_CONTACT_US_DATA_REQUEST: 'GET_CONTACT_US_DATA_REQUEST',
    GET_CONTACT_US_DATA_SUCCESS: 'GET_CONTACT_US_DATA_SUCCESS',
    GET_CONTACT_US_DATA_FAILURE: 'GET_CONTACT_US_DATA_FAILURE',

    // ? Submit contact us form
    SUBMIT_CONTACT_US_FORM_REQUEST: 'SUBMIT_CONTACT_US_FORM_REQUEST',
    SUBMIT_CONTACT_US_FORM_SUCCESS: 'SUBMIT_CONTACT_US_FORM_SUCCESS',
    SUBMIT_CONTACT_US_FORM_FAILURE: 'SUBMIT_CONTACT_US_FORM_FAILURE',

    // ? Get care card program
    GET_CARE_CARD_PROGRAM_REQUEST: 'GET_CARE_CARD_PROGRAM_REQUEST',
    GET_CARE_CARD_PROGRAM_SUCCESS: 'GET_CARE_CARD_PROGRAM_SUCCESS',
    GET_CARE_CARD_PROGRAM_FAILURE: 'GET_CARE_CARD_PROGRAM_FAILURE',

    // ? Get list careers
    GET_LIST_CAREERS_REQUEST: 'GET_LIST_CAREERS_REQUEST',
    GET_LIST_CAREERS_SUCCESS: 'GET_LIST_CAREERS_SUCCESS',
    GET_LIST_CAREERS_FAILURE: 'GET_LIST_CAREERS_FAILURE',

    // ? Submit resume
    SUBMIT_RESUME_REQUEST: 'SUBMIT_RESUME_REQUEST',
    SUBMIT_RESUME_SUCCESS: 'SUBMIT_RESUME_SUCCESS',
    SUBMIT_RESUME_FAILURE: 'SUBMIT_RESUME_FAILURE',
    START_SUBMITTING_RESUME: 'START_SUBMITTING_RESUME',

    // ? Reset is submit success
    RESET_IS_SUBMIT_SUCCESS: 'RESET_IS_SUBMIT_SUCCESS',

    // ? Submit applications
    SUBMIT_APPLICATIONS_REQUEST: 'SUBMIT_APPLICATIONS_REQUEST',
    SUBMIT_APPLICATIONS_SUCCESS: 'SUBMIT_APPLICATIONS_SUCCESS',
    SUBMIT_APPLICATIONS_FAILURE: 'SUBMIT_APPLICATIONS_FAILURE'
};

export default contactUsActionTypes;
