import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// ~ Components
import NewsHeaderSectionLayout from 'layouts/news/NewsHeaderSectionLayout';
import NewsContentLayout from 'layouts/news/NewsContentLayout';

// ~ Services
import { connect } from 'react-redux';
import { useNewsSlice } from 'services/news';
import newsActions from 'services/news/newsActions';
import { makeSelectNewsData } from 'services/news/newsSelector';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { isEmptyObject } from 'utils';

const NewsPage = ({
    getListNews = () => {},
    newsData = {},
    metaData = {},
    isAuthenticated = false
}) => {
    const [queryParams, setQueryParams] = useState({});
    const renderMetaData = () => {
        if (!isEmptyObject(newsData)) {
            const metaDataNews = newsData?.metaData;
            return (
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {metaDataNews?.title !== '' ? metaDataNews.title : metaData.title}
                    </title>
                    <meta
                        name='description'
                        content={
                            metaDataNews?.description !== ''
                                ? metaDataNews?.description
                                : metaData.description
                        }
                    />
                    <meta
                        name='keywords'
                        content={
                            metaDataNews?.keywords !== ''
                                ? metaDataNews.keywords
                                : metaData.keywords
                        }
                    />
                </Helmet>
            );
        }
    };

    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useNewsSlice();
            getListNews({ page: 1, limit: 12 });
        }
    }, [isAuthenticated]);

    const handleOnChangePagination = (currentPage) => {
        getListNews({ page: currentPage, limit: 12, ...queryParams });
        // ? Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleOnFilter = (params) => {
        setQueryParams(params);
        getListNews({ page: 1, limit: 12, ...params });

        // ? Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer'>
                <NewsHeaderSectionLayout data={newsData?.headerSection} />
                <NewsContentLayout
                    items={newsData?.sections}
                    pagination={newsData?.pagination}
                    allYears={newsData?.allYears}
                    allCategories={newsData?.allCategories}
                    onChange={handleOnChangePagination}
                    onFilter={handleOnFilter}
                />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListNews: (params) => dispatch(newsActions.getListNewsRequest(params))
    };
};

const mapStateToProps = (state) => {
    return {
        newsData: makeSelectNewsData(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

NewsPage.propTypes = {
    getListNews: PropTypes.func,
    newsData: PropTypes.object,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
