import React from 'react';

import PropTypes from 'prop-types';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';

const NewsHeaderSectionLayout = ({ data = {} }) => {
    return (
        <BaseHeaderWithContentSection
            title={data?.title}
            desc=''
            imagePath={ourHospitalsImagePath}
        />
    );
};

NewsHeaderSectionLayout.propTypes = {
    data: PropTypes.object
};

export default NewsHeaderSectionLayout;
