import { transformContent } from 'utils';

const hardCodeDom =
    "<div><iframe width='100%' height='640' src='https://maps.google.com/maps?q=Menara+KPJ%2C+Level+12%2C+238%2C+Jln+Tun+Razak%2C+50400+Kuala+Lumpur&amp;height=615&amp;z=14&amp;w=100%&amp;hl=en&amp;ie=UTF8&amp;iwloc=B&amp;output=embed' frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0'></iframe></div>";

const healthTourismLocationDataTransformer = (data, staticBlocks) => {
    let location = {};
    let contents = data.content;
    location.column = data.column;

    // ? Left section
    if (contents[0]) {
        location.leftSection = transformContent(contents[0]);
    }

    // ? Right section
    if (contents[1]) {
        staticBlocks.forEach((block) => {
            if (contents[1].includes(block.shortcode)) {
                location.rightSection = hardCodeDom;
            }
        });
    }

    return location;
};

const healthTourismTabsDataTransformer = (staticBlocks) => {
    let tabsData = {};

    staticBlocks.forEach((block, index) => {
        if (index !== 0) {
            if (block.type === 'tab') {
                tabsData = block;
            }
        }
    });

    tabsData.items.forEach((item) => {
        if (item.staticBlocks !== '[]') {
            // ? First index of [
            const firstIndex = item.content.indexOf('[');
            const lastIndex = item.content.lastIndexOf(']');

            // ? Get content before and after [ ]
            const contentBefore = item.content.substring(0, firstIndex);
            const contentAfter = item.content.substring(lastIndex + 1);

            item.content = contentBefore + contentAfter;

            const type = item.staticBlocks.split('"')[3];
            staticBlocks.forEach((block) => {
                if (block.shortcode.includes(type)) {
                    item.contentItems = block.items;
                    item.typeTab = type;
                }
            });
        }
    });

    return tabsData;
};

const healthTourismDataTransformer = (data) => {
    const healthTourismData = {
        location: {},
        tabsData: {},
        metaData: {}
    };
    const staticBlocks = data.staticBlocks;

    if (data.sections) {
        // ~ Location
        healthTourismData.location = healthTourismLocationDataTransformer(
            data.sections[0],
            staticBlocks
        );

        // ~ Tabs
        healthTourismData.tabsData = healthTourismTabsDataTransformer(staticBlocks);
    }

    healthTourismData.metaData = {
        title: data.metaTitle,
        description: data.metaDescription,
        keywords: data.metaKeywords
    };

    return healthTourismData;
};

export { healthTourismDataTransformer };
