import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, Label, TextInput } from 'flowbite-react';
import contactUsActions from 'services/contact-us/contactUsActions';
import { connect } from 'react-redux';
import { makeSelectContactUsSubmitSuccess } from 'services/contact-us/contactUsSelector';
import { useContactUsSlice } from 'services/contact-us';
import { toast } from 'react-toastify';

const { REACT_APP_CAPCHA_V3 } = process.env;

const HealthTourismPatientCenter = ({ items = [], submitContactUsForm = () => {}, id }) => {
    const [submitData, setSubmitData] = useState({});

    // ? On change input
    const handleOnChangeValue = (e, label) => {
        setSubmitData({ ...submitData, [label]: e.target.value });
    };

    // ? Submit form
    const handleOnSubmitForm = () => {
        window.grecaptcha.ready(() => {
            try {
                window.grecaptcha
                    .execute(REACT_APP_CAPCHA_V3 + '', { action: 'submit' })
                    .then(() => {
                        submitData['Are you an existing patient?'] =
                            submitData['Are you an existing patient?'] === 'Yes' ? 1 : 0;
                        const payload = {
                            name: '',
                            email: '',
                            phone: '',
                            is_existing_patient: '',
                            feedback: '',
                            block_id: id,
                            extra_content: submitData
                        };
                        submitContactUsForm(payload);
                    });
            } catch (error) {
                toast.error('Something went wrong!');
            }
        });
    };

    // ? Render single radio button
    const renderRadioButton = (label, index) => {
        return (
            <div key={index} className='flex items-center gap-2'>
                <Radio id='united-state' name='countries' value={label} />
                <Label htmlFor='united-state'>{label}</Label>
            </div>
        );
    };
    // ? Render radio buttons
    const renderRadioButtons = (items, label) => {
        return (
            <fieldset
                className='flex flex-col gap-4'
                id='radio'
                onChange={(e) => handleOnChangeValue(e, label)}
            >
                <legend className='mb-2'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </legend>
                {items.map((label, index) => renderRadioButton(label, index))}
            </fieldset>
        );
    };

    // ? Render provide detail
    const renderProvideDetail = (label) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='provide-detail'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <textarea
                    id='provide-detail'
                    className='border border-gray-300 rounded-lg p-2'
                    rows='5'
                    onChange={(e) => handleOnChangeValue(e, label)}
                />
            </div>
        );
    };

    // ? Render other input
    const renderOtherInput = (label) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='other-input'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <TextInput onChange={(e) => handleOnChangeValue(e, label)} />
            </div>
        );
    };

    // ? Render form
    const renderForm = () => {
        return items.map((item) => {
            if (item.itemType === 'radio_button') {
                return (
                    <Fragment key={item.id}>
                        {renderRadioButtons(item.options, item.title)}
                    </Fragment>
                );
            }
            if (item.itemType === 'textarea') {
                return <Fragment key={item.id}>{renderProvideDetail(item.title)}</Fragment>;
            }
            if (item.itemType === 'plain_text') {
                return <Fragment key={item.id}>{renderOtherInput(item.title)}</Fragment>;
            }
        });
    };

    useEffect(() => {
        useContactUsSlice();
    }, []);

    return (
        <div className='bg-white md:p-6 px-0 -mt-[60px]'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4 py-5'>{renderForm()}</div>

            <div
                className='text-white bg-[#000] px-8 py-2  w-fit rounded-xl text-[14px] cursor-pointer ml-auto'
                onClick={handleOnSubmitForm}
            >
                Submit
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitContactUsForm: (params) =>
            dispatch(contactUsActions.submitContactUsFormRequest(params))
    };
};

const mapStateToProps = (state) => {
    return {
        isSubmitSuccess: makeSelectContactUsSubmitSuccess(state)
    };
};

HealthTourismPatientCenter.propTypes = {
    items: PropTypes.array,
    submitContactUsForm: PropTypes.func,
    isSubmitSuccess: PropTypes.bool,
    id: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthTourismPatientCenter);
