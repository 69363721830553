import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
// ~ Components
import { connect } from 'react-redux';
import { Label, TextInput, Select } from 'flowbite-react';
import contactUsActions from 'services/contact-us/contactUsActions';
import {
    makeSelectorIsFetching,
    makeSelectContactUsSubmitSuccess
} from 'services/contact-us/contactUsSelector';
import { toast } from 'react-toastify';

const { REACT_APP_CAPCHA_V3 } = process.env;

const CardCareForm = ({
    items = [],
    submitContactUsForm = () => {},
    id,
    isFetching = false,
    resetSubmitSuccess = () => {},
    isSubmitSuccess = false,
    onSuccess = () => {}
}) => {
    const [submitData, setSubmitData] = useState({});
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();

    // ? On change input
    const handleOnChangeValue = (e, label) => {
        setSubmitData({ ...submitData, [label]: e.target.value });
    };

    // ? Render select input
    const renderCombobox = (label, options = []) => {
        return (
            <div id='select'>
                <div className='mb-2 block'>
                    <Label htmlFor='countries' value={label} />
                </div>
                <Select id='option' required={true} onChange={(e) => handleOnChangeValue(e, label)}>
                    <option>Choose Your Preferred Hospital</option>
                    {options.map((option, index) => {
                        if (option.length > 0) {
                            return <option key={index}>{option}</option>;
                        }
                    })}
                </Select>
            </div>
        );
    };

    // ? Render input
    const renderInput = (label = '') => {
        const labelFormatted = label.replaceAll('.', '');
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='other-input'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <TextInput
                    {...register(labelFormatted, { required: true })}
                    onChange={(e) => handleOnChangeValue(e, label)}
                />
                {errors && errors[labelFormatted] && (
                    <div className='text-[12px] text-normal text-red-500 -mt-1'>
                        *This field is required
                    </div>
                )}
            </div>
        );
    };

    const renderTextArea = (label) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='provide-detail'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <textarea
                    {...register(label, { required: true })}
                    id='provide-detail'
                    className='border border-gray-300 rounded-lg p-2'
                    rows='5'
                    onChange={(e) => handleOnChangeValue(e, label)}
                />

                {errors && errors[label] && (
                    <div className='text-[12px] text-normal text-red-500 -mt-1'>
                        *This field is required
                    </div>
                )}
            </div>
        );
    };

    // ? Render form
    const renderForm = (item, idx) => {
        if (item.itemType === 'plain_text' || item.itemType === 'email') {
            return <Fragment key={idx}>{renderInput(item.title)}</Fragment>;
        }

        if (item.itemType === 'dropdown') {
            return <Fragment key={idx}>{renderCombobox(item.title, item.options)}</Fragment>;
        }

        if (item.itemType === 'textarea') {
            return <Fragment key={item.id}>{renderTextArea(item.title)}</Fragment>;
        }
    };

    // ? Render all fields
    const renderAllFields = () => {
        if (items.length > 0) {
            return items.map((item, idx) => {
                return <Fragment key={idx}>{renderForm(item)}</Fragment>;
            });
        }
    };

    const handleOnSubmitData = () => {
        window.grecaptcha.ready(() => {
            try {
                window.grecaptcha.execute(REACT_APP_CAPCHA_V3, { action: 'submit' }).then(() => {
                    const payload = {
                        name: '',
                        email: '',
                        phone: '',
                        is_existing_patient: '',
                        feedback: '',
                        block_id: id,
                        extra_content: submitData
                    };

                    submitContactUsForm(payload);

                    setTimeout(() => {
                        onSuccess();
                    }, 1000);
                });
            } catch (error) {
                toast.error('Something went wrong!');
            }
        });
    };

    useEffect(() => {
        if (isSubmitSuccess) {
            setSubmitData({});

            const elements = document.getElementsByTagName('input');
            for (let ii = 0; ii < elements.length; ii++) {
                elements[ii].value = '';
            }
            document.getElementById('select').getElementsByTagName('select')[0].value =
                'Choose Your Preferred Hospital';

            document.getElementById('provide-detail').value = '';

            resetSubmitSuccess();
        }
    }, [isSubmitSuccess]);
    return (
        <div className='col-span-3'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-5'>{renderAllFields()}</div>

            <div className='w-fit ml-auto mt-5'>
                <button
                    disabled={isFetching}
                    className='text-white bg-[#000] px-8 py-2 w-fit rounded-xl text-[14px] cursor-pointer ml-auto disabled:opacity-50'
                    onClick={handleSubmit(handleOnSubmitData)}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitContactUsForm: (payload) =>
            dispatch(contactUsActions.submitContactUsFormRequest(payload)),
        resetSubmitSuccess: () => dispatch(contactUsActions.resetIsSubmitSuccess())
    };
};

const mapStateToProps = (state) => {
    return {
        isFetching: makeSelectorIsFetching(state),
        isSubmitSuccess: makeSelectContactUsSubmitSuccess(state)
    };
};

CardCareForm.propTypes = {
    items: PropTypes.array,
    submitContactUsForm: PropTypes.func,
    isFetching: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resetSubmitSuccess: PropTypes.func,
    isSubmitSuccess: PropTypes.bool,
    onSuccess: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCareForm);
