import React from 'react';
import ApplicationsHeaderSection from 'layouts/applications/ApplicationsHeaderSection';
import ApplicationsForm from 'layouts/applications/ApplicationsForm';

const Applications = () => {
    return (
        <div className='bg-layer'>
            <ApplicationsHeaderSection />
            <ApplicationsForm />
        </div>
    );
};

export default Applications;
