import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

// ~ Components
import SearchHeaderSection from 'layouts/search/SearchHeaderSection';
import SearchLayout from 'layouts/search/SearchLayout';

// ~ Services
import { connect } from 'react-redux';
import { useOurBusinessesSlice } from 'services/our-businesses';
import ourBusinessesActions from 'services/our-businesses/ourBusinessesActions';
import { makeSelectSearchData } from 'services/our-businesses/ourBusinessesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const SearchPage = ({ searchData = () => {}, searchDataItems = {}, isAuthenticated = false }) => {
    const [search] = useSearchParams();

    useEffect(() => {
        useOurBusinessesSlice();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const queryParams = search.get('q');
            searchData({ q: queryParams });
        }
    }, [search, isAuthenticated]);

    return (
        <div className='bg-layer'>
            <SearchHeaderSection />
            <SearchLayout items={searchDataItems?.items} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchData: (search) => dispatch(ourBusinessesActions.searchDataRequest(search))
    };
};

const mapStateToProps = (state) => {
    return {
        searchDataItems: makeSelectSearchData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

SearchPage.propTypes = {
    searchData: PropTypes.func,
    searchDataItems: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
