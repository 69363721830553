import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const MedicinesHeaderSection = ({ data = {} }) => {
    return <div className='medicines-header'>{ReactHtmlParser(data?.content)}</div>;
};

MedicinesHeaderSection.propTypes = {
    data: PropTypes.object
};

export default MedicinesHeaderSection;
