import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseServiceCard from 'components/BaseServiceCard';
import { makeSelectListServices } from 'services/our-services/ourServicesSelector';

const OurServicesLayout = ({ sections = [] }) => {
    // ? Render services card
    const renderServicesCard = () => {
        return sections.map((service, index) => {
            return (
                <BaseServiceCard key={index} content={service?.content} column={service.column} />
            );
        });
    };
    return (
        <div className='container mx-auto flex gap-8 pt-8 pb-4 flex-col px-4 md:px-[80px] items-center'>
            {renderServicesCard()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sections: makeSelectListServices(state)
    };
};

OurServicesLayout.propTypes = {
    sections: PropTypes.array
};

export default connect(mapStateToProps)(OurServicesLayout);
