import { camelize } from 'transformer/common';
import {
    metaDataTransformer,
    allEventsTransformer,
    specialtiesDataTransformer
} from 'transformer/meta';
import { transformContent } from 'utils';

/**
 * TODO: Get meta data
 */

const getMetaDataHandler = (state, { payload }) => {
    const dataTransformer = metaDataTransformer(payload);
    return {
        ...state,
        headerData: dataTransformer.headerData,
        banners: dataTransformer.banners,
        subHeaderData: dataTransformer.subHeaderData,
        isOnlyStatic: dataTransformer.isOnlyStatic,
        footerData: dataTransformer.footerData,
        metaData: dataTransformer.metaData,
        isAuthenticated: true
    };
};

/**
 * TODO: Get all events
 */

const getAllEventsHandler = (state, { payload }) => {
    const dataTransformer = allEventsTransformer(payload);
    return {
        ...state,
        events: dataTransformer.events,
        mobileEvents: dataTransformer.mobileEvents
    };
};

/**
 * TODO: Get all states
 */

const getAllStateHandler = (state, { payload }) => {
    return {
        ...state,
        states: payload
    };
};

/**
 * TODO: Get all Specialties
 */

const getAllSpecialitiesHandler = (state, { payload }) => {
    return {
        ...state,
        specialties: specialtiesDataTransformer(camelize(payload.items))
    };
};

/**
 * TODO: Get page with slug
 */

const getPageWithSlug = (state, { payload }) => {
    const camelizeData = camelize(payload);

    camelizeData.content = transformContent(camelizeData.content);
    return {
        ...state,
        pageData: camelizeData
    };
};

export {
    getMetaDataHandler,
    getAllEventsHandler,
    getAllStateHandler,
    getAllSpecialitiesHandler,
    getPageWithSlug
};
