import doctorActionTypes from './doctorActionTypes';

const getListDoctors = (data) => ({
    type: doctorActionTypes.GET_LIST_DOCTORS_REQUEST,
    payload: data
});

const getListDoctorsSuccess = (payload) => ({
    type: doctorActionTypes.GET_LIST_DOCTORS_SUCCESS,
    payload
});

const getListDoctorsFailure = (error) => ({
    type: doctorActionTypes.GET_LIST_DOCTORS_FAILURE,
    payload: error
});

// ? Get doctor details
const getDoctorDetails = (data) => ({
    type: doctorActionTypes.GET_DOCTOR_DETAILS_REQUEST,
    payload: data
});

const getDoctorDetailsSuccess = (payload) => ({
    type: doctorActionTypes.GET_DOCTOR_DETAILS_SUCCESS,
    payload
});

const getDoctorDetailsFailure = (error) => ({
    type: doctorActionTypes.GET_DOCTOR_DETAILS_FAILURE,
    payload: error
});

export default {
    getListDoctors,
    getListDoctorsSuccess,
    getListDoctorsFailure,
    getDoctorDetails,
    getDoctorDetailsSuccess,
    getDoctorDetailsFailure
};
