import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// ~ Components
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';

const CareCardProgramHeaderSection = ({ data = {} }) => {
    return (
        <>
            <BaseHeaderWithContentSection
                imagePath={ourHospitalsImagePath}
                title={data?.title}
                desc={data?.desc}
            />

            <div className='mx-auto container md:mt-0 pt-5'>{ReactHtmlParser(data?.content)}</div>
        </>
    );
};

CareCardProgramHeaderSection.propTypes = {
    data: PropTypes.object
};

export default CareCardProgramHeaderSection;
