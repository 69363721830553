import htppClient from '../index';
import DOCTOR_CONSTANTS from 'api/constants/doctor';

/**
 * TODO: Get list doctors
 */

const getListDoctors = async (params) => {
    try {
        const getListDoctorsResponse = await htppClient.get(DOCTOR_CONSTANTS.LIST_DOCTOS, {
            params
        });

        return getListDoctorsResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get doctor details
 */

const getDoctorDetails = async (slug) => {
    try {
        const getDoctorDetailsResponse = await htppClient.get(
            `${DOCTOR_CONSTANTS.GET_DOCTOR_DETAILS}/${slug}`
        );

        return getDoctorDetailsResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getListDoctors,
    getDoctorDetails
};
