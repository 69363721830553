import React from 'react';
import PropTypes from 'prop-types';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';

const OurBusinessesHeaderSection = ({ data = {} }) => {
    return <BaseHeaderWithContentSection title={data?.title} imagePath={ourHospitalsImagePath} />;
};

OurBusinessesHeaderSection.propTypes = {
    data: PropTypes.object
};

export default OurBusinessesHeaderSection;
