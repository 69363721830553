import { camelize, chunkArray } from 'transformer/common';

const { REACT_APP_DOMAIN } = process.env;
// ? The header data transformer
const topHeaderDataTransformer = (data) => {
    return {
        generalLine: data.generalLine,
        facebookLink: data.facebookUrl,
        instagramLink: data.instagramUrl,
        tiktokLink: data.tiktokUrl,
        twitterLink: data.twitterUrl,
        linkedInLink: data.linkedInUrl,
        whatsappLink: data.whatsappUrl,
        telegramLink: data.telegramUrl,
        youtubeLink: data.youtubeUrl,
    };
};

const headerMenuDataTransformer = (data) => {
    const headerValues = Object.values(data.header);
    let menus = [];
    headerValues.forEach((header) => {
        menus[+header.position] = {
            name: header.name,
            path: header.slug,
            childItems: header.childs.length > 0 ? header.childs : null
        };
    });
    return menus;
};

// ? The footer menus
const footerMenuDataTransformer = (data) => {
    const footerValues = Object.values(data);

    let menus = [];
    footerValues.forEach((footer) => {
        menus[+footer.position] = {
            name: footer.name,
            path: footer.slug
        };
    });

    return menus;
};

// ? The footer copyright data transformer
const footerCopyrightDataTransformer = (data) => {
    return {
        pdpa: `${REACT_APP_DOMAIN}${data.pdpaLink}`,
        kkliuNo: data.kkliuNo
    };
};

// ? Subheader data transformer
const subHeaderDataTransformer = (data) => {
    let subHeaderData = [];
    const leftItem = {
        url: data.homepageLeftUrl,
        logo: data.homepageLeftLogo,
        content: data.homepageLeftContent,
        color: '#eda780'
    };

    subHeaderData.push(leftItem);

    const centerItem = {
        url: data.homepageCenterUrl,
        logo: data.homepageCenterLogo,
        content: data.homepageCenterContent,
        color: '#e78076'
    };
    subHeaderData.push(centerItem);

    const rightItem = {
        url: data.homepageRightUrl,
        logo: data.homepageRightLogo,
        content: data.homepageRightContent,
        color: '#5775d2'
    };
    subHeaderData.push(rightItem);

    return subHeaderData;
};

// ? Events data transformer
const eventsDataTransformer = (data) => {
    // ? Chunk array to array with 3 items
    return chunkArray(data, 4);
};

const metaDataTransformer = (data = {}) => {
    const dataCamalized = camelize(data);
    const dataTransformed = {
        headerData: {
            topHeader: topHeaderDataTransformer(dataCamalized.meta),
            menus: headerMenuDataTransformer(dataCamalized.menus)
        },
        footerData: {
            copyright: footerCopyrightDataTransformer(dataCamalized.meta),
            social: topHeaderDataTransformer(dataCamalized.meta),
            menus: [
                {
                    title: dataCamalized.meta.footerLeftTitle,
                    items: footerMenuDataTransformer(dataCamalized.menus.footerLeft)
                },
                {
                    title: dataCamalized.meta.footerCenterTitle,
                    items: footerMenuDataTransformer(dataCamalized.menus.footerCenter)
                },
                {
                    title: dataCamalized.meta.footerRightTitle,
                    items: footerMenuDataTransformer(dataCamalized.menus.footerRight)
                }
            ]
        },
        banners: dataCamalized.banners,
        subHeaderData: subHeaderDataTransformer(dataCamalized.meta),
        isOnlyStatic: dataCamalized.meta.onlyStatic === '1',
        metaData: {
            title: dataCamalized.meta.title,
            description: dataCamalized.meta.description,
            keywords: dataCamalized.meta.keyword,
            metaDescription: dataCamalized.meta.metaDescription,
            metaKeywords: dataCamalized.meta.metaKeyword
        }
    };

    return dataTransformed;
};

// ? All events transformer
const allEventsTransformer = (data) => {
    const dataCamalized = camelize(data);
    const dataTransformed = {
        events: eventsDataTransformer(dataCamalized.items)
    };
    return dataTransformed;
};

// ? Specialties data transformer
const specialtiesDataTransformer = (data) => {
    let dataFilter = data
        .filter((value, index, self) => index === self.findIndex((t) => t.name === value.name))
        .map((item) => item.name);

    let dataFilterValues = [];

    data.forEach((item) => {
        if (dataFilter.includes(item.name)) {
            const index = dataFilter.indexOf(item.name);
            if (dataFilterValues[index]) {
                dataFilterValues[index] += `-${item.id}`;
            } else {
                dataFilterValues[index] = `${item.id}`;
            }
        }
    });

    let result = [];

    dataFilter.forEach((item, index) => {
        result.push({
            name: item,
            id: dataFilterValues[index]
        });
    });

    return result;
};

export { metaDataTransformer, allEventsTransformer, specialtiesDataTransformer };
