import httpClient from '../index';
import PACKAGES_CONSTANTS from 'api/constants/packages';

/**
 * TODO: Get package details with slug
 */

const getPackageDetails = async (payload) => {
    try {
        const getPackageDetailsResponse = await httpClient.get(
            `${PACKAGES_CONSTANTS.GET_PACKAGE_DETAILS}/${payload?.slug}`
        );

        return getPackageDetailsResponse;
    } catch (error) {
        payload?.navigate('/404');
        return error;
    }
};

export default {
    getPackageDetails
};
