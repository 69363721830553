import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Carousel } from 'flowbite-react';
import BaseMultipleImages from 'components/BaseMultipleImages';
import metaActions from 'services/meta/metaActions';
import { makeSelectEvents } from 'services/meta/metaSelectors';

const HomepageEvents = ({ events = [] }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(metaActions.getAllEvents());
        }, 1000);
    }, []);

    // ? Render carousel items
    const renderCarouselItems = () => {
        if (events.length === 0) {
            return <></>;
        }

        return events.map((event, index) => {
            return (
                <div key={index}>
                    <BaseMultipleImages images={event} />
                </div>
            );
        });
    };

    return (
        <div className='container mx-auto md:my-12 mt-4 mb-0'>
            <div className='text-headline-2 text-center font-bold relative md:mb-6 mb-0'>
                <span>News</span>
                <span className='absolute -bottom-2 w-[30px] h-[2px] bg-main left-1/2 -translate-x-1/2'></span>
            </div>

            <div className='md:mt-12 mt-4 md:h-[260px] h-[560px] md:px-0 px-4'>
                <Carousel
                    slideInterval={5000}
                    leftControl={<></>}
                    rightControl={<></>}
                    slide={false}
                    id='events'
                    indicators={true}
                >
                    {renderCarouselItems()}
                </Carousel>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        events: makeSelectEvents(state)
    };
};

HomepageEvents.propTypes = {
    events: PropTypes.array
};

export default connect(mapStateToProps)(HomepageEvents);
