import metaActionTypes from './metaActionTypes';

const getMetaData = () => ({
    type: metaActionTypes.GET_META_DATA_REQUEST
});

const getMetaDataSuccess = (data) => ({
    type: metaActionTypes.GET_META_DATA_SUCCESS,
    payload: data
});

const getMetaDataFailure = (error) => {
    return {
        type: metaActionTypes.GET_META_DATA_FAILURE,
        payload: error
    };
};

// ? Get all events
const getAllEvents = (data) => ({
    type: metaActionTypes.GET_ALL_EVENTS_REQUEST,
    payload: data
});

const getAllEventsSuccess = (data) => ({
    type: metaActionTypes.GET_ALL_EVENTS_SUCCESS,
    payload: data
});

const getAllEventsFailure = (error) => {
    return {
        type: metaActionTypes.GET_ALL_EVENTS_FAILURE,
        payload: error
    };
};

// ? Get all states
const getAllState = () => ({
    type: metaActionTypes.GET_ALL_STATE_REQUEST
});

const getAllStateSuccess = (data) => ({
    type: metaActionTypes.GET_ALL_STATE_SUCCESS,
    payload: data
});

const getAllStateFailure = (error) => {
    return {
        type: metaActionTypes.GET_ALL_STATE_FAILURE,
        payload: error
    };
};

// ? Get all Specialties
const getAllSpecialities = (data) => ({
    type: metaActionTypes.GET_ALL_SPECIALITIES_REQUEST,
    payload: data
});

const getAllSpecialitiesSuccess = (data) => ({
    type: metaActionTypes.GET_ALL_SPECIALITIES_SUCCESS,
    payload: data
});

const getAllSpecialitiesFailure = (error) => {
    return {
        type: metaActionTypes.GET_ALL_SPECIALITIES_FAILURE,
        payload: error
    };
};

// ? Get page with slug
const getPageWithSlug = (data) => ({
    type: metaActionTypes.GET_PAGE_WITH_SLUG_REQUEST,
    payload: data
});

const getPageWithSlugSuccess = (data) => ({
    type: metaActionTypes.GET_PAGE_WITH_SLUG_SUCCESS,
    payload: data
});

const getPageWithSlugFailure = (error) => {
    return {
        type: metaActionTypes.GET_PAGE_WITH_SLUG_FAILURE,
        payload: error
    };
};

export default {
    getMetaData,
    getMetaDataSuccess,
    getMetaDataFailure,
    getAllEvents,
    getAllEventsSuccess,
    getAllEventsFailure,
    getAllState,
    getAllStateSuccess,
    getAllStateFailure,
    getAllSpecialities,
    getAllSpecialitiesSuccess,
    getAllSpecialitiesFailure,
    getPageWithSlug,
    getPageWithSlugSuccess,
    getPageWithSlugFailure
};
