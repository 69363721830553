import { combineReducers } from 'redux';

const rootReducer = () => {
    return {};
};
const staticReducers = {
    root: rootReducer
};
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (injectedReducers) => {
    // Initially we don't have any injectedReducers, so returning identity function to avoid the error
    const rootReducer = combineReducers({
        ...injectedReducers,
        ...staticReducers
    });
    return rootReducer;
};

export default createReducer;
