import React from 'react';

import SubheaderImagePath from 'assets/common/subheader-doctors.png';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';

const SearchHeaderSection = () => {
    return <BaseHeaderWithContentSection imagePath={SubheaderImagePath} title='Search Result' />;
};

export default SearchHeaderSection;
