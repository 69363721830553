import React from 'react';
import ContainerLayout from 'layouts/core/ContainerLayout';
import { Route, Routes } from 'react-router-dom';
import MainRoutes from 'routes/MainRoutes';
import Toast from 'components/BaseToast';
import ScrollToTop from 'layouts/core/ScrollToTop';

const App = () => {
    return (
        <ContainerLayout>
            <ScrollToTop>
                <Routes>
                    {MainRoutes.map((route, index) => {
                        return <Route path={route.path} element={route.element} key={index} />;
                    })}
                </Routes>
                <Toast />
            </ScrollToTop>
        </ContainerLayout>
    );
};

export default App;
