import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const BaseServiceCard = ({ content = '', column = '1' }) => {
    return (
        <div className={`grid grid-cols-${column}`}>
            <div>{ReactHtmlParser(content)}</div>
        </div>
    );
};

BaseServiceCard.propTypes = {
    content: PropTypes.any,
    column: PropTypes.string
};

export default BaseServiceCard;
