import store from 'index';
import rootContactUsSaga from './contactUsSagas';
import contactUsReducer from './contactUsReducer';

const key = 'contactUs';

export const useContactUsSlice = () => {
    store.injectReducer(key, contactUsReducer);
    store.injectSaga(key, rootContactUsSaga);
};
