import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import OurServicesLayout from 'layouts/our-services/OurServicesLayout';
import ourServicesActions from 'services/our-services/ourServicesActions';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';

import { makeSelectMetaData } from 'services/meta/metaSelectors';
import { makeSelectOurServicesMetaData } from 'services/our-services/ourServicesSelector';

const OurServices = ({ metaData = {}, metaDataOurServices = {} }) => {
    useEffect(() => {}, []);

    // ? Render meta data
    const renderMetaData = () => {
        return (
            <Helmet>
                <meta charSet='utf-8' />
                <title>
                    {metaDataOurServices?.title !== ''
                        ? metaDataOurServices?.title
                        : metaData?.title}
                </title>
                <meta
                    name='description'
                    content={
                        metaDataOurServices?.description !== ''
                            ? metaDataOurServices?.description
                            : metaData?.description
                    }
                />
                <meta
                    name='keywords'
                    content={
                        metaDataOurServices?.keywords !== '' ? metaDataOurServices?.keywords : ''
                    }
                />
            </Helmet>
        );
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer'>
                <OurServicesHeaderSection />

                <OurServicesLayout />

                <OurServicesFooter currentIndex={0} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListServices: () => dispatch(ourServicesActions.listOurServices())
    };
};

const mapStateToProps = (state) => {
    return {
        metaData: makeSelectMetaData(state),
        metaDataOurServices: makeSelectOurServicesMetaData(state)
    };
};

OurServices.propTypes = {
    getListServices: PropTypes.func.isRequired,
    metaData: PropTypes.object,
    metaDataOurServices: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(OurServices);
