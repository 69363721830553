import React from 'react';

import Award1ImagePath from 'assets/homepage/award-1.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HomepageAwards = () => {
    const settings = {
        className: '',
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoPlay: true
    };

    return (
        <div className='container mx-auto md:mt-12 mt-4'>
            <div className='md:h-[220px] h-fit bg-white px-6 md:py-6 py-3 flex flex-col justify-between'>
                <div className='text-headline-2 font-bold'>Awards & Accreditations</div>

                <div className='flex md:flex-row flex-col justify-between items-center'>
                    <div>
                        KPJ Healthcare Berhad (KPJ) is one of the leading private healthcare <br />
                        providers
                    </div>

                    <div className='md:h-[110px] md:w-[45%] w-full h-[160px]'>
                        <Slider {...settings}>
                            <img src={Award1ImagePath} className='h-[100px]' />
                        </Slider>
                    </div>
                </div>

                <div></div>
            </div>
        </div>
    );
};

export default HomepageAwards;
