import httpClient from '../index';
import OUR_BUSINESSES_CONSTANTS from 'api/constants/our-businesses';

/**
 * TODO: Get list our businesses
 */

const getListOurBusinesses = async () => {
    try {
        const getListOurBusinessesResponse = await httpClient.get(
            OUR_BUSINESSES_CONSTANTS.GET_LIST_OUR_BUSINESSES
        );

        return getListOurBusinessesResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get list medicines
 */

const getListMedicines = async () => {
    try {
        const getListMedicinesResponse = await httpClient.get(
            OUR_BUSINESSES_CONSTANTS.GET_LIST_MEDICINES
        );

        return getListMedicinesResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get list tender
 */

const getListTender = async (params) => {
    try {
        const getListTenderResponse = await httpClient.get(
            OUR_BUSINESSES_CONSTANTS.GET_LIST_TENDER,
            {
                params
            }
        );

        return getListTenderResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Search data
 */

const searchData = async (data) => {
    try {
        const searchDataResponse = await httpClient.post(
            OUR_BUSINESSES_CONSTANTS.SEARCH_DATA,
            data
        );
        return searchDataResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get here for you
 */

const getHereForYou = async () => {
    try {
        const getHereForYouResponse = await httpClient.get(
            OUR_BUSINESSES_CONSTANTS.GET_HERE_FOR_YOU
        );

        return getHereForYouResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getListOurBusinesses,
    getListMedicines,
    getListTender,
    searchData,
    getHereForYou
};
