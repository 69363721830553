import { createSelector } from '@reduxjs/toolkit';
import ourServicesState from './ourServicesInitState';

const selectGlobal = (state) => {
    return state.ourServices || ourServicesState;
};

// ? Make selector the list services
const makeSelectListServices = createSelector(selectGlobal, (globalState) => {
    return globalState.sections;
});

// ? Make selector the subheader
const makeSelectOurServicesSubheader = createSelector(selectGlobal, (globalState) => {
    return globalState.subheader;
});

//? Make selector the meta data
const makeSelectOurServicesMetaData = createSelector(selectGlobal, (globalState) => {
    return globalState.metaData;
});

// ? Make selector the center for sight
const makeSelectCenterForSight = createSelector(selectGlobal, (globalState) => {
    return globalState.centerForSight;
});

// ? Make selector the senior living care
const makeSelectSeniorLivingCare = createSelector(selectGlobal, (globalState) => {
    return globalState.seniorLivingCare;
});

// ? Make selector the laboratory services
const makeSelectLaboratoryServices = createSelector(selectGlobal, (globalState) => {
    return globalState.laboratoryServices;
});

// ? Make selector the dental speciality center
const makeSelectDentalSpecialityCenter = createSelector(selectGlobal, (globalState) => {
    return globalState.dentalSpecialityCenter;
});

// ? Make selector the rehabilitation services
const makeSelectRehabilitationServices = createSelector(selectGlobal, (globalState) => {
    return globalState.rehabilitationServices;
});

// ? Make selector the bariatric surgery
const makeSelectBariatricSurgery = createSelector(selectGlobal, (globalState) => {
    return globalState.bariatricSurgery;
});

export {
    makeSelectListServices,
    makeSelectOurServicesSubheader,
    makeSelectOurServicesMetaData,
    makeSelectCenterForSight,
    makeSelectSeniorLivingCare,
    makeSelectLaboratoryServices,
    makeSelectDentalSpecialityCenter,
    makeSelectRehabilitationServices,
    makeSelectBariatricSurgery
};
