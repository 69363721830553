import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BaseCard = ({ label = '', title = '', desc = '', actionText = '', imagePath, to = '' }) => {
    return (
        <div className='bg-white px-3 py-7 flex-col flex gap-7'>
            <div>
                <div className='font-[400] uppercase text-body-3'>{label}</div>

                <div className='font-bold capitalize text-[30px] mt-4 text-main'>
                    {ReactHtmlParser(title)}
                </div>
            </div>

            <div>{desc}</div>

            <Link to={to} className='mt-auto'>
                <div className='px-3 py-2 bg-black text-white w-fit rounded-xl min-w-[140px] text-center capitalize cursor-pointer'>
                    {actionText}
                </div>
                <img src={imagePath} alt='Introduce' />
            </Link>
        </div>
    );
};

BaseCard.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    actionText: PropTypes.string,
    imagePath: PropTypes.any,
    to: PropTypes.string
};

export default BaseCard;
