import { camelize } from 'transformer/common';
import { transformContent } from 'utils';

// ? Csr data transformer
const csrDataTransformer = (data) => {
    const camelizedData = camelize(data);

    // ! Header section
    const headerSection = {
        title: camelizedData.subhead
    };

    // ! Sections
    let sections = [];
    const staticBlocks = camelizedData?.staticBlocks;

    staticBlocks.forEach((block) => {
        if (camelizedData.content.includes(block.shortcode)) {
            sections = block.items;
        }
    });

    const metaData = {
        title: camelizedData.metaTitle,
        description: camelizedData.metaDescription,
        keywords: camelizedData.metaKeywords
    };

    return {
        headerSection,
        sections,
        metaData
    };
};

// ? Klinik waqaf data transformer
const klinikWaqafDataTransformer = (data) => {
    const camelizedData = camelize(data);

    // ! Header section
    const headerSection = {
        title: camelizedData.subhead
    };

    const content = transformContent(camelizedData.content);
    const metaData = {
        title: camelizedData.metaTitle,
        description: camelizedData.metaDescription,
        keywords: camelizedData.metaKeywords
    };

    return { headerSection, content, sections: camelizedData?.sections, metaData };
};

// ? covid19 boolet data transformer
const covid19BookletDataTransformer = (data) => {
    const camelizedData = camelize(data);
    const metaData = {
        title: camelizedData.metaTitle,
        description: camelizedData.metaDescription,
        keywords: camelizedData.metaKeywords
    };

    // ! Header section
    const headerSection = {
        title: camelizedData.subhead
    };

    const content = transformContent(camelizedData.content);

    return { headerSection, content, sections: camelizedData?.sections, metaData };
};

export { csrDataTransformer, klinikWaqafDataTransformer, covid19BookletDataTransformer };
