import HealthTourismServices from 'api/health-tourism';

import { call, takeLatest, put, fork } from 'redux-saga/effects';

import healthTourismActions from './healthTourismActions';
import healthTourismActionTypes from './healthTourismActionTypes';

/**
 * TODO: Get health tourism page
 */

function* getHealthTourismPageHandler() {
    try {
        const response = yield call(HealthTourismServices.getHealthTourismPage);
        if (+response.status === 1) {
            yield put(healthTourismActions.getHealthTourismPageSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

function* watchHealthTourismSaga() {
    yield takeLatest(
        healthTourismActionTypes.GET_HEALTH_TOURISM_PAGE_REQUEST,
        getHealthTourismPageHandler
    );
}

export default function* rootHealthTourismSaga() {
    yield fork(watchHealthTourismSaga);
}
