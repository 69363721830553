import { camelize } from 'transformer/common';
import { transformContent } from 'utils';

const listNewsDataTransformer = (data) => {
    const camelizedData = camelize(data);

    // ? Meta data
    const metaData = {
        metaTitle: camelizedData.metaTitle,
        metaKeywords: camelizedData.metaKeywords,
        metaDescription: camelizedData.metaDescription
    };

    // ? Header section
    const headerSection = {
        title: 'KPJ News',
        desc: ''
    };

    return {
        metaData,
        headerSection,
        sections: camelizedData?.items,
        pagination: camelizedData?.paginator,
        allYears: camelizedData?.allYear,
        allCategories: camelizedData?.allCategory
    };
};

// ~ News details

const newsDetailsDataTransformer = (data) => {
    const camelizedData = camelize(data);

    // ? Meta data
    const metaData = {
        metaTitle: camelizedData.metaTitle,
        metaKeywords: camelizedData.metaKeywords,
        metaDescription: camelizedData.metaDescription
    };

    // ? Header section
    const headerSection = {
        title: camelizedData.subhead || 'KPJ News',
        desc: camelizedData.intro
    };

    // ? Content section
    let content = transformContent(camelizedData.content);

    const contentSection = {
        title: camelizedData.title,
        content,
        time: camelizedData.date.date,
        image: camelizedData.image
    };

    return {
        metaData,
        headerSection,
        contentSection
    };
};

export { listNewsDataTransformer, newsDetailsDataTransformer };
