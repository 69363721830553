import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, Label, TextInput, Select } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import contactUsActions from 'services/contact-us/contactUsActions';
import { connect } from 'react-redux';
import {
    makeSelectContactUsSubmitSuccess,
    makeSelectorIsFetching
} from 'services/contact-us/contactUsSelector';
import { toast } from 'react-toastify';
// import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_CAPCHA_V3 } = process.env;

const ContactUsFormSection = ({
    items = [],
    submitContactUsForm = () => {},
    id,
    isFetching = false,
    isSubmitSuccess = false,
    resetSubmitSuccess = () => {}
}) => {
    const [submitData, setSubmitData] = useState({});
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();

    // ? On change input
    const handleOnChangeValue = (e, label) => {
        setSubmitData({ ...submitData, [label]: e.target.value });
    };

    // ? Submit data
    const hanldeOnSubmitData = () => {
        window.grecaptcha.ready(() => {
            try {
                window.grecaptcha.execute(REACT_APP_CAPCHA_V3, { action: 'submit' }).then(() => {
                    submitData['Are you an existing patient?'] =
                        submitData['Are you an existing patient?'] === 'No' ? 0 : 1;
                   const payload = {
                        name: submitData['Name'],
                        email: submitData['Email'],
                        phone: submitData['Contact Number'],
                        is_existing_patient: submitData['Are you an existing patient?'],
                        feedback: submitData[' Your Inquiry or Feedback'],
                        block_id: id,
                        extra_content: submitData
                    };
                    submitContactUsForm(payload);
                });
            } catch (error) {
                toast.error('Something went wrong!');
            }
        });
    };

    // ? Render single radio button
    const renderRadioButton = (label, index) => {
        return (
            <div key={index} className='flex items-center gap-2'>
                <Radio
                    id='united-state'
                    name='countries'
                    value={label}
                    defaultChecked={index === 0}
                />
                <Label htmlFor='united-state'>{label}</Label>
            </div>
        );
    };

    // ? Render radio buttons
    const renderRadioButtons = (items, label) => {
        return (
            <fieldset
                className='flex flex-col gap-4'
                id='radio'
                onChange={(e) => handleOnChangeValue(e, label)}
            >
                <legend className='mb-2'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </legend>
                {items.map((label, index) => renderRadioButton(label, index))}
            </fieldset>
        );
    };

    // ? Render provide detail
    const renderProvideDetail = (label) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='provide-detail'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <textarea
                    {...register(label, { required: true })}
                    id='provide-detail'
                    className='border border-gray-300 rounded-lg p-2'
                    rows='5'
                    onChange={(e) => handleOnChangeValue(e, label)}
                />

                {errors && errors[label] && (
                    <div className='text-[12px] text-normal text-red-500 -mt-1'>
                        *This field is required
                    </div>
                )}
            </div>
        );
    };

    // ? Render other input
    const renderOtherInput = (label) => {
        return (
            <div className='flex flex-col gap-2'>
                <Label htmlFor='other-input'>
                    {label} <span className='text-[#FF0000]'>*</span>
                </Label>
                <TextInput
                    {...register(label, { required: true })}
                    onChange={(e) => handleOnChangeValue(e, label)}
                    name={label}
                />

                {errors && errors[label] && (
                    <div className='text-[12px] text-normal text-red-500 -mt-1'>
                        *This field is required
                    </div>
                )}
            </div>
        );
    };

    // ? Render select input
    const renderSelectInput = (label, options = []) => {
        return (
            <div id='select'>
                <div className='mb-2 block'>
                    <Label htmlFor='countries' value={label} />
                </div>
                <Select id='option' required={true} onChange={(e) => handleOnChangeValue(e, label)}>
                    <option>Choose Your Preferred Hospital</option>
                    {options.map((option, index) => {
                        if (option.length > 0) {
                            return <option key={index}>{option}</option>;
                        }
                    })}
                </Select>
            </div>
        );
    };

    const renderForm = () => {
        return items.map((item) => {
            if (item.itemType === 'radio_button') {
                return (
                    <Fragment key={item.id}>
                        {renderRadioButtons(item.options, item.title)}
                    </Fragment>
                );
            }
            if (item.itemType === 'textarea') {
                return <Fragment key={item.id}>{renderProvideDetail(item.title)}</Fragment>;
            }
            if (item.itemType === 'plain_text' || item.itemType === 'email') {
                return <Fragment key={item.id}>{renderOtherInput(item.title)}</Fragment>;
            }

            if (item.itemType === 'dropdown') {
                return (
                    <Fragment key={item.id}>{renderSelectInput(item.title, item.options)}</Fragment>
                );
            }
        });
    };

    useEffect(() => {
        if (isSubmitSuccess) {
            // const successMessage =
            //     'Your inquiry has been received. <br /> Alternatively, please call us at our hotline for immediate response. Thank You.';
            // const successMessage =
                // 'Your inquiry has been received. <br /> We will be in touch shortly. Alternatively, please call us at our hotline for immediate response. Thank You.';
            // toast.success(ReactHtmlParser(successMessage));
            // toast.success(successMessage);
            setSubmitData({});

            const elements = document.getElementsByTagName('input');
            for (let ii = 0; ii < elements.length; ii++) {
                elements[ii].value = '';
            }
            document.getElementById('select').getElementsByTagName('select')[0].value =
                'Choose Your Preferred Hospital';

            document.getElementById('provide-detail').value = '';

            resetSubmitSuccess();
        }
    }, [isSubmitSuccess]);

    return (
        <>
            <div className='mx-auto container'>
                <div className='px-4 pb-8 bg-[#fff]'>
                    <form
                        className='px-7 py-9 bg-[#f3f5f7]'
                        onSubmit={handleSubmit(hanldeOnSubmitData)}
                    >
                        <div className='text-[20px] font-bold'>Your Inquiry / Feedback:</div>

                        <div className='grid md:grid-cols-2 grid-cols-1 gap-4 py-5'>
                            {renderForm()}
                        </div>

                        <div className='w-fit ml-auto'>
                            <button
                                disabled={isFetching}
                                className='text-white bg-[#000] px-8 py-2 w-fit rounded-xl text-[14px] cursor-pointer ml-auto disabled:opacity-80'
                                type='submit'
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitContactUsForm: (params) =>
            dispatch(contactUsActions.submitContactUsFormRequest(params)),
        resetSubmitSuccess: () => dispatch(contactUsActions.resetIsSubmitSuccess())
    };
};

const mapStateToProps = (state) => {
    return {
        isSubmitSuccess: makeSelectContactUsSubmitSuccess(state),
        isFetching: makeSelectorIsFetching(state)
    };
};

ContactUsFormSection.propTypes = {
    items: PropTypes.array,
    submitContactUsForm: PropTypes.func,
    isSubmitSuccess: PropTypes.bool,
    id: PropTypes.any,
    isFetching: PropTypes.bool,
    resetSubmitSuccess: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsFormSection);
