import React from 'react';
import PropTypes from 'prop-types';
import DefaultEventImagePath from 'assets/common/default-event.png';
import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_DOMAIN, REACT_APP_DOMAIN_MEDIA } = process.env;

const BaseMultipleImages = ({ images = [], className = '' }) => {
    // ? Render images
    const renderImages = () => {
        return images.map((imageData, index) => {
            return (
                <a
                    key={index}
                    href={`${REACT_APP_DOMAIN}news/${imageData.slug}`}
                    className='md:h-[300px] max-h-[260px]'
                >
                    <div className='h-[150px] bg-white'>
                        <img
                            src={
                                imageData.imageThumb
                                    ? `${REACT_APP_DOMAIN_MEDIA}${imageData.imageThumb}`
                                    : DefaultEventImagePath
                            }
                            className='h-full mx-auto'
                        />
                    </div>
                    <div className='title py-3 text-[14px] text-[#5675d3] text-justify'>
                        {ReactHtmlParser(imageData.title)}
                    </div>
                </a>
            );
        });
    };
    return (
        <div className={`grid md:grid-cols-4 grid-cols-2 md:gap-6 gap-4 ${className}`}>
            {renderImages()}
        </div>
    );
};

BaseMultipleImages.propTypes = {
    images: PropTypes.array,
    className: PropTypes.string
};

export default BaseMultipleImages;
