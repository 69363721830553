import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
// import { ReactDOM } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import OurServicesHeaderImagePath from 'assets/common/subheader-ourservices.png';
// ~ Services
import metaActions from 'services/meta/metaActions';
import { makeSelectPageData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
// ~ static blocks
import Accordion from 'pages/static-block/accordion/accordion';
import RichText from 'pages/static-block/rich_text/rich_text';
import Slideshow from 'pages/static-block/slideshow/slideshow';
import Gallery from 'pages/static-block/gallery/gallery';
import GoogleMap from 'pages/static-block/google_map/google_map';
import Package from 'pages/static-block/list_package/list_package';
import News from 'pages/static-block/list_news/list_news';
import Event from 'pages/static-block/list_event/list_event';
import Clinic from 'pages/static-block/list_clinic/list_clinic';
import Doctor from 'pages/static-block/list_doctor/list_doctor';
import Tab from 'pages/static-block/tab/tab';
import Brochure from 'pages/static-block/brochure/brochure';
import Form from 'pages/static-block/contact_form/contact_form';

const PageSlug = ({
    getPageDataWithSlug = () => {},
    pageWithSlugData = {},
    isAuthenticated = false
}) => {
    const { slug } = useParams();
    const navigate = useNavigate();

    var contain_sb = [];

    const process_sb = (page_data) => {
        // ~ static blocks
        var _static_block_content = false;
        page_data.staticBlocks.forEach((element) => {
            switch (element.type) {
                case 'accordion':
                    import('pages/static-block/accordion/accordion.css');
                    import('pages/static-block/accordion/custom.js');
                    _static_block_content = <Accordion items={element.items} />;
                    break;
                case 'rich_text':
                    import('pages/static-block/rich_text/rich_text.css');
                    _static_block_content = <RichText item={element} />;
                    break;
                case 'slideshow':
                    import('pages/static-block/slideshow/slideshow.css');
                    _static_block_content = <Slideshow item={element} />;
                    break;
                case 'gallery':
                    import('pages/static-block/gallery/gallery.css');
                    import('pages/static-block/lib/lightbox.css');
                    import('pages/static-block/lib/lightbox.js');
                    _static_block_content = <Gallery item={element} />;
                    break;
                case 'google_map':
                    import('pages/static-block/google_map/google_map.css');
                    _static_block_content = <GoogleMap item={element} />;
                    break;
                case 'list_package':
                    import('pages/static-block/list_package/list_package.css');
                    _static_block_content = <Package item={element} />;
                    break;
                case 'list_news':
                    import('pages/static-block/list_news/list_news.css');
                    _static_block_content = <News item={element} />;
                    break;
                case 'list_event':
                    import('pages/static-block/list_event/list_event.css');
                    _static_block_content = <Event item={element} />;
                    break;
                case 'list_clinic':
                    import('pages/static-block/list_clinic/list_clinic.css');
                    _static_block_content = <Clinic item={element} />;
                    break;
                case 'list_doctor':
                    import('pages/static-block/list_doctor/list_doctor.css');
                    _static_block_content = <Doctor item={element} />;
                    break;
                case 'tab':
                    import('pages/static-block/tab/tab.css');
                    import('pages/static-block/tab/custom.js');
                    _static_block_content = <Tab item={element} />;
                    break;
                case 'brochure':
                    import('pages/static-block/brochure/brochure.css');
                    import('pages/static-block/lib/lightbox.css');
                    import('pages/static-block/lib/lightbox.js');
                    _static_block_content = <Brochure item={element} />;
                    break;
                case 'contact_form':
                    import('pages/static-block/contact_form/contact_form.css');
                    import('pages/static-block/contact_form/custom.js');
                    _static_block_content = <Form item={element} />;
                    break;

                default:
                    break;
            }
            if (_static_block_content) {
                // console.log(page_data?.content);
                // ~ revamp pageWithSlugData.content
                page_data.content = page_data?.content
                    .replace(`<br>`, `<br />`)
                    .replace(
                        `<p>${element.shortcode}<br></p>`,
                        ReactDOMServer.renderToString(_static_block_content)
                        // _static_block_content
                    )
                    .replace(
                        `<p><br>${element.shortcode}</p>`,
                        ReactDOMServer.renderToString(_static_block_content)
                        // _static_block_content
                    )
                    .replace(
                        `<p>${element.shortcode}</p>`,
                        ReactDOMServer.renderToString(_static_block_content)
                        // _static_block_content
                    )
                    // .replace(
                    //     `<p><span style="font-family: &quot;Source Sans Pro&quot;, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;; font-size: 1rem;">${element.shortcode}</span><br></p>`,
                    //     ReactDOMServer.renderToString(_static_block_content)
                    //     // _static_block_content
                    // )
                    // .replace(
                    //     `<p><span style="font-family: &quot;Source Sans Pro&quot;, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;; font-size: 1rem;">${element.shortcode}</span></p>`,
                    //     ReactDOMServer.renderToString(_static_block_content)
                    //     // _static_block_content
                    // )
                    .replace(
                        `style="font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; font-size: 1rem;"`,
                        ``
                        // _static_block_content
                    )
                    .replace(
                        `style="font-family: " source="" sans="" pro",="" -apple-system,="" blinkmacsystemfont,="" "segoe="" ui",="" roboto,="" "helvetica="" neue",="" arial,="" sans-serif,="" "apple="" color="" emoji",="" ui="" symbol";="" font-size:="" 1rem;"=""`,
                        ``
                        // _static_block_content
                    )
                    .replace(
                        new RegExp(`"/media/hospital`, 'g'),
                        `"https://media.kpjhealth.com.my/media/hospital`
                    )
                    .replace(
                        `${element.shortcode}`,
                        ReactDOMServer.renderToString(_static_block_content)
                        // _static_block_content
                    )
                    .replace(
                        ReactDOMServer.renderToString(element.shortcode),
                        ReactDOMServer.renderToString(_static_block_content)
                        // _static_block_content
                    );
            }
            if (element.isContainStaticBlock == '1' && !contain_sb.includes(element.id)) {
                contain_sb.push(element.id);
                // console.log('contain sb');
                // console.log(page_data);
                page_data = process_sb(page_data);
            }
        });
        return page_data;
    };

    useEffect(() => {
        if (slug && isAuthenticated) {
            getPageDataWithSlug({ slug, navigate });
        }
    }, [slug, isAuthenticated]);

    if (pageWithSlugData.staticBlocks) {
        // pageWithSlugData?.content.replace("<br>", "");
        pageWithSlugData = process_sb(pageWithSlugData);
    }
    return (
        <>
            {pageWithSlugData.subhead && pageWithSlugData?.subhead.length > 0 && (
                <BaseHeaderWithContentSection
                    imagePath={OurServicesHeaderImagePath}
                    title={pageWithSlugData?.subhead}
                    desc={pageWithSlugData?.intro}
                />
            )}
            <div className='container mx-auto py-5 pb-0 px-4 md:px-[80px]'>
                {ReactHtmlParser(pageWithSlugData?.content)}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPageDataWithSlug: (slug) => dispatch(metaActions.getPageWithSlug(slug))
    };
};

const mapStateToProps = (state) => {
    return {
        pageWithSlugData: makeSelectPageData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

PageSlug.propTypes = {
    getPageDataWithSlug: PropTypes.func,
    pageWithSlugData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSlug);
