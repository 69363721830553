import React from 'react';
import PropTypes from 'prop-types';
import BaseServiceCardDetails from 'components/BaseServiceCardDetails';

const OurServicesDetailsLayout = ({ data = {} }) => {
    return (
        <div className='container mx-auto flex gap-8 py-8 flex-col px-4 md:px-[80px]'>
            <BaseServiceCardDetails data={data} />
        </div>
    );
};

OurServicesDetailsLayout.propTypes = {
    data: PropTypes.object,
    staticData: PropTypes.object
};

export default OurServicesDetailsLayout;
