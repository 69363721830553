import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'flowbite-react';
import ReactHtmlParser from 'react-html-parser';
// import HealthTourismInMalaysia from './HealthTourismInMalaysia';
import HealthTourismPatientCenter from './HealthTourismPatientCenter';
import HealthTourismOurPackages from './HealthTourismOurPackages';

const HealthTourismTabs = ({ tabs = [] }) => {
    const renderStaticBlock = (tab) => {
        if (tab.typeTab === 'contact_form') {
            // use tab.contentItems[0].staticBlockId beceause this tab is different, the id/staticBlockId is not the contact form sb id
            return <HealthTourismPatientCenter items={tab.contentItems} id={tab.contentItems[0].staticBlockId} />;
        }

        if (tab.typeTab === 'list_package') {
            return <HealthTourismOurPackages items={tab.contentItems} />;
        }
    };
    // ? Render tabs
    const renderTabs = () => {
        return tabs.map((tab, index) => {
            return (
                <Tabs.Item key={index} title={tab.title} className='text-uppercase'>
                    <div className='bg-white p-6'>
                        {ReactHtmlParser(tab.content)}
                        {renderStaticBlock(tab)}
                    </div>
                </Tabs.Item>
            );
        });
    };

    // ? Render tabs group

    const renderTabGroup = () => {
        if (tabs.length > 0) {
            return (
                <Tabs.Group
                    style='underline'
                    className='container mx-auto md:px-[80px] px-0 justify-center border-none health-tabs w-full md:mt-10 mt-0'
                >
                    {renderTabs()}
                </Tabs.Group>
            );
        }
    };

    useEffect(() => {
        // ? Scroll to top
        window.scrollTo(0, 0);
    }, [tabs]);

    return <>{renderTabGroup()}</>;
};

HealthTourismTabs.propTypes = {
    data: PropTypes.object,
    tabs: PropTypes.array
};

export default HealthTourismTabs;
