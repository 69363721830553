import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { MD5 } from 'crypto-js';
import ReactHtmlParser from 'react-html-parser';

const Form = ({ item }) => {
  var labelButton = item.options.buttonLabel || 'Submit';
  var includeCore = item.options.includeDefaultField
    ? item.options.includeDefaultField === '1'
    : false;
  var form_action = '/submit-contact-us';
  var coreField;
  var recaptchaKey;
  
  var sourceField = btoa(JSON.stringify(item.items));
  
  var message = item.alias == 'de-rantau-programme' ? 
  'Thank you for your interest in our DE Rantau Community. '+
  'We will be in touch with you the soonest.' 
  :
  'Your inquiry has been received. We will be in touch shortly. '+
  'Alternatively, please call us at our hotline for immediate response. Thank You.Your inquiry has been received. '+
  'We will be in touch within 48 hours.';

  const formItems = item.items.map((_item) => {
    var field_name = _item.title;
    var field_type = _item.itemType;
    var _mandatory = _item.mandatory ? 'mandatory' : '';
    var input_required = _item.mandatory ? true : false;
    var span_mandatory = _item.mandatory ? <span className="_label_mandatory" style={{color:'red'}} >*</span> : '';
    var inputRef = useRef(_item.title);

    var extraFieldName = '' + MD5(field_name).toString() + '';
    return (
      <div key={_item.id} className="field withfull" style={{ width: '50%' }} >
<br></br>
         <div className="label-wrapper">
        <label style={{ display: 'block' }} >
          {field_name} {span_mandatory}
        </label>
        </div>
  
        {/* text */}
        {field_type === 'plain_text' && (
          <input
            required={input_required}
            name={extraFieldName}
            type="text"
            className={`lcms-txt ${_mandatory} rounded-md`}
            id={MD5(field_name).toString()}
            ref={inputRef}
            style={{ backgroundColor: '#f2f2f2',borderColor: '#cccccc',width: '90%'  }}
          />
        )}
        
        {/* email */}
        {field_type === 'email' && (
          <input
            required={input_required}
            name={extraFieldName}
            type="email"
            className={`lcms-txt ${_mandatory} rounded-md`}
            id={MD5(field_name).toString()}
            ref={field_name.toString()}
            style={{ backgroundColor: '#f2f2f2',borderColor: '#cccccc',width: '90%' }}
          />
        )}
        {/* textarea */}
        {field_type === 'textarea' && (
          <textarea
            required={input_required}
            name={extraFieldName}
            className={`lcms-textarea ${_mandatory} rounded-md`}
            id={MD5(field_name).toString()}
            ref={field_name.toString()}
            style={{borderColor: '#cccccc',width: '90%',height:'80%'}}
          ></textarea>
        )}
        {/* dropdown */}
        {field_type === 'dropdown' && (
          <select
            required={input_required}
            name={extraFieldName}
            className={`lcms-dropdown ${_mandatory} rounded-md`}
            id={MD5(field_name).toString()}
            ref={field_name.toString()}
          >
            {_item.options.map((option) => {
              if (option) {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              }
            })}
          </select>
        )}
        {/* radio */}
        {field_type === 'radio_button' && (
          <div className="py-1">
            {_item.options.map((option) => {
              if (option) {
                return (
                  <label key={option} className="block">
                    <input
                      required={input_required}
                      className="default:ring-2"
                      value={option}
                      name={extraFieldName}
                      type="radio"
                      id={MD5(field_name).toString()}
                      ref={field_name.toString()}
                    />
                    {option}
                  </label>
                );
              }
            })}
          </div>
        )}
        {/* checkbox */}
        {field_type === 'checkbox' && (
          <label className="block">
            <input
              required={input_required}
              name={extraFieldName}
              type="checkbox"
              value={_item.content}
              className={`default:ring-2`}
              id={MD5(field_name).toString()}
              ref={field_name.toString()}
              style={{ width: 'auto', height: 'auto' }}
            />
          </label>
        )}
      </div>
    );
  });

  if (includeCore) {
    coreField = (
      <div className='flex flex-wrap'>
        <div className='field withfull' style={{ width: '50%' }} >
          <div className="label-wrapper">
            <label style={{ display: 'block' }} >
              Full Name <span className="label_mandatory" style={{ color: 'red' }}>*</span>
            </label>
            <input
              required
              name="name"
              type="text"
              className="lcms-txt mandatory rounded-md"
              id="full-name"
              style={{ backgroundColor: '#f2f2f2',borderColor: '#cccccc',width: '90%'  }}
            />
          </div>
        </div>
        <div className='field withfull' style={{ width: '50%' }} >
          <div className="label-wrapper">
            <label style={{ display: 'block' }} >
              Are you an existing patient? <span className="label_mandatory" style={{ color: 'red' }} >*</span>
            </label>

            <div className="py-1">
              <label className="block">
                <input
                  required
                  className="default:ring-2"
                  value="1"
                  name="is_existing_patient"
                  type="radio"
                  id="exist-patient-yes"
                />
                Yes
              </label>
              <label className="block">
                <input
                  required
                  className="default:ring-2"
                  value="0"
                  name="is_existing_patient"
                  type="radio"
                  id="exist-patient-no"
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className='field withfull' style={{ width: '50%' }} >
          <div className="label-wrapper">
            <label style={{ display: 'block' }} >
              Phone Number <span className="label_mandatory">*</span>
            </label>
            <input
              required
              name="phone"
              type="tel"
              className="lcms-txt mandatory rounded-md"
              id="phone-number"
              style={{ backgroundColor: '#f2f2f2',borderColor: '#cccccc',width: '90%'  }}
            />
          </div>
        </div>
        <div className='field withfull' style={{ width: '50%' }} >
          <div className="label-wrapper">
            <label style={{ display: 'block' }} >
              Email <span className="label_mandatory">*</span>{' '}
            </label>
            <input
              required
              name="email"
              type="email"
              className="lcms-txt mandatory rounded-md"
              id="customer-email"
              style={{ backgroundColor: '#f2f2f2',borderColor: '#cccccc',width: '90%'  }}
            />
          </div>
        </div>
        <div className='field withfull' style={{ width: '50%' }} >
          <div className="label-wrapper">
            <label style={{ display: 'block' }} >Inquiry / Feedback </label>
            <textarea name="feedback" className="lcms-textarea mandatory rounded-md" 
              style={{borderColor: '#cccccc',width: '90%',height:'80%'}}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="lcms-sections" id="contact-us">
      <div className="plexcms-container">
        <div className="lcms-span-container ">
          <div className="lcms-content-container">
            <div className="lcms-contact-form-contents lcms-contact-form-contents-2233">
              <form id="contact-us-form" action={form_action}>
                <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
                <input type="hidden" name="action" value="validate_captcha" />
                <input type="hidden" name="block_id" value={item.id} />
                <input type="hidden" name="source" value={sourceField} />
                <div className="field-container flex flex-wrap">
                  {formItems} {coreField}
                </div>
                <span className="msg-error error"></span>
                <div className="action action-submit text-right" style={{ marginTop: '40px' }} >
                  <button
                    className="primary-btn bg-blue-500 hover:bg-black-700 text-white font-bold py-2 px-4 rounded"
                    name="submitted"
                    id="btn-validate"
                    data-sitekey={recaptchaKey}
                    data-callback="onSubmit"
                    data-action="submit"
                    style={{borderRadius: '10px',background:'black'}}
                  >
                    {labelButton}
                  </button>
                </div>
                <div className="lcms-contact-form-submitted">
                  <div className="bg-white">
                    <h3 className="p-5">
                      <span
                        onClick="$('.lcms-contact-form-submitted').hide();"
                        className="float-right text-xl"
                        id="popup-close-x"
                      >
                        {ReactHtmlParser('&times')}
                      </span>
                      Inquiry Submitted
                    </h3>
                    <div className="px-5 pb-5 border-b border-gray-200">
                      {message}
                    </div>
                    <div className="action text-right p-5 border-t border-gray-200">
                      <a
                        onClick="$('.lcms-contact-form-submitted').hide();location.reload();"
                        className="site-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        id="popup-close"
                      >
                        Done
                      </a>
                    </div>
                    <div className="lcms-contact-form-submittedfail">
                      <div className="bg-white">
                        <h3 className="p-5">
                          <span
                            onClick="$('.lcms-contact-form-submittedfail').hide();location.reload();"
                            className="float-right text-xl"
                          >
                            {ReactHtmlParser('&times')}
                          </span>
                          Inquiry Fail
                        </h3>
                        <div className="px-5 pb-5 border-b border-gray-200 lcms-contact-form-submittedfail-content">
                          Please insert all the fields
                        </div>
                        <div className="action text-right p-5 border-t border-gray-200">
                          <a
                            onClick="$('.lcms-contact-form-submittedfail').hide();"
                            className="site-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          >
                            Done
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  item: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        content: PropTypes.string,
        image: PropTypes.string,
        imageThumb: PropTypes.string,
        price: PropTypes.number
      })
    ),
    options: PropTypes.shape({
      buttonLabel: PropTypes.string,
      includeDefaultField: PropTypes.string
    }),
    itemPerRow: PropTypes.string,
    id: PropTypes.number,
    alias:PropTypes.string,
  })
};

export default Form;
