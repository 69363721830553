import React from 'react';
import PropTypes from 'prop-types';
import { Sidebar } from 'flowbite-react';

import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

const TheSideBarMobile = ({ className = '', menus = [], onClose = () => {} }) => {
    // ? Render sidebar item
    const renderSidebarItem = (item, key) => {
        if (item.path) {
            return (
                <Sidebar.Item key={key}>
                    <Link to={item.path} onClick={onClose}>
                        <div className='whitespace-pre-wrap'> {ReactHtmlParser(item.name)}</div>
                    </Link>
                </Sidebar.Item>
            );
        } else {
            return (
                <Sidebar.Item key={key}>
                    <a href={item.slug}>
                        <div className='whitespace-pre-wrap'> {ReactHtmlParser(item.name)}</div>
                    </a>
                </Sidebar.Item>
            );
        }
    };

    // ? Render sidebar item with dropdown
    const renderSidebarItemWithDropdown = (item, key) => {
        return (
            <Sidebar.Collapse label={item.name} key={key}>
                {item.childItems.map((dropdownItem, index) => {
                    return renderSidebarItem(dropdownItem, index);
                })}
            </Sidebar.Collapse>
        );
    };

    // ? Render menus
    const renderMenus = () => {
        if (menus.length > 0) {
            return menus.map((navbarItem, index) => {
                if (navbarItem.childItems) {
                    return renderSidebarItemWithDropdown(navbarItem, index);
                }
                return renderSidebarItem(navbarItem, index);
            });
        }
    };
    return (
        <div className={`${className} w-fit`}>
            <Sidebar aria-label='Sidebar with multi-level dropdown example'>
                <Sidebar.Items>
                    <Sidebar.ItemGroup>{renderMenus()}</Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>
        </div>
    );
};

TheSideBarMobile.propTypes = {
    className: PropTypes.string,
    menus: PropTypes.array,
    onClose: PropTypes.func
};

export default TheSideBarMobile;
