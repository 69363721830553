import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

// ? Layouts
import PackagesBreadcrumb from 'layouts/packages/PackagesBreadcrumb';
import PackagesDetailsLayout from 'layouts/packages/PackagesDetailsLayout';

// ? Services
import { usePackagesSlice } from 'services/packages';
import packagesActions from 'services/packages/packagesActions';
import { makeSelectPackageDetails } from 'services/packages/packagesSelectors';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const PackageDetails = ({
    getPackageDetails = () => {},
    packageDetails = {},
    isAuthenticated = false
}) => {
    const { slug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        usePackagesSlice();
        if (slug && isAuthenticated) {
            getPackageDetails({ slug, navigate });
        }
    }, [slug, isAuthenticated]);

    return (
        <div className='bg-layer' id='package'>
            <PackagesBreadcrumb slug={slug} />
            <PackagesDetailsLayout data={packageDetails} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPackageDetails: (slug) => dispatch(packagesActions.getPackageDetailsRequest(slug))
    };
};

const mapStateToProps = (state) => {
    return {
        packageDetails: makeSelectPackageDetails(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

PackageDetails.propTypes = {
    getPackageDetails: PropTypes.func,
    packageDetails: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageDetails);
