import React from 'react';

const DoctorDetailIcon = () => {
    return (
        <svg
            fill='#fff'
            width={22}
            height={22}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 22.34 22.32'
        >
            <g id='Layer_2'>
                <g id='Layer_1-2'>
                    <path d='M5.57,10.71a1.68,1.68,0,0,1,0,.2v3.48a.3.3,0,0,1-.2.31,1.64,1.64,0,0,0-.81,2,1.67,1.67,0,0,0,3.24-.63,1.6,1.6,0,0,0-.9-1.41.33.33,0,0,1-.21-.35q0-1.87,0-3.75c0-.23.07-.32.29-.35l1.77-.2a.56.56,0,0,1,.33.12c.63.46,1.26.93,1.88,1.41.15.12.25.1.4,0,.59-.46,1.2-.9,1.79-1.36a.76.76,0,0,1,.56-.16c.25,0,.51,0,.78,0v3.31a7.81,7.81,0,0,0-.88.39,2.71,2.71,0,0,0-1.34,2.35c0,1.26,0,2.53,0,3.79a.6.6,0,0,0,0,.12h2.2V19H13.44c0-.07,0-.11,0-.15,0-.9,0-1.8,0-2.69a1.66,1.66,0,0,1,3.27-.4,2.32,2.32,0,0,1,.07.46v2.62a.93.93,0,0,1,0,.14H15.65v1.13h2.22V16.3a2.8,2.8,0,0,0-2.06-2.82c-.07,0-.17-.11-.17-.17,0-1,0-2,0-3a17.08,17.08,0,0,1,1.67.67,8.78,8.78,0,0,1,5,7.84c0,.51,0,1,0,1.52a2,2,0,0,1-2,2H2.1a2,2,0,0,1-2-1.62.36.36,0,0,1,0-.1,10.07,10.07,0,0,1,1.37-6.43,9,9,0,0,1,3.88-3.35Z' />
                    <path d='M11.18,0A4.46,4.46,0,1,1,6.72,4.44,4.45,4.45,0,0,1,11.18,0Z' />
                </g>
            </g>
        </svg>
    );
};

export default DoctorDetailIcon;
