import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// ~ Components
import OurBusinessesTabs from './OurBusinessesTabs';

const OurBusinessesLayout = ({ sectionsData = [] }) => {
    // ? Render data
    const renderOurBusinessesLayout = () => {
        return sectionsData.map((section, idx) => {
            if (section.type === 'tab')
                return <OurBusinessesTabs key={idx} tabs={section?.items} />;
            else {
                return <Fragment key={idx}></Fragment>;
            }
        });
    };
    return <div>{renderOurBusinessesLayout()}</div>;
};

OurBusinessesLayout.propTypes = {
    sectionsData: PropTypes.array
};

export default OurBusinessesLayout;
