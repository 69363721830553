import DoctorServices from 'api/doctor';

import { call, takeLatest, put, fork } from 'redux-saga/effects';
import doctorActions from './doctorActions';
import doctorActionTypes from './doctorActionTypes';

/**
 * TODO: Get list doctors
 */

function* getListDoctorsHandler({ payload }) {
    try {
        const response = yield call(DoctorServices.getListDoctors, payload);
        if (+response.status === 1) {
            yield put(doctorActions.getListDoctorsSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get doctor details
 */

function* getDoctorDetailsHandler({ payload }) {
    try {
        const response = yield call(DoctorServices.getDoctorDetails, payload?.slug);
        if (+response.status === 1) {
            yield put(doctorActions.getDoctorDetailsSuccess(response.data));
        }
        if (+response.status === 0) {
            payload?.navigate('/404');
        }
    } catch (error) {
        return error;
    }
}

function* watchDoctorSaga() {
    yield takeLatest(doctorActionTypes.GET_LIST_DOCTORS_REQUEST, getListDoctorsHandler);
    yield takeLatest(doctorActionTypes.GET_DOCTOR_DETAILS_REQUEST, getDoctorDetailsHandler);
}

export default function* rootDoctorSaga() {
    yield fork(watchDoctorSaga);
}
