import doctorActionTypes from './doctorActionTypes';
import doctorInitState from './doctorInitState';

import { getListDoctorsHandler, getDoctorDetailsHandler } from './doctorReducerHandler';

const doctorReducer = (state = doctorInitState, action) => {
    switch (action.type) {
        case doctorActionTypes.GET_LIST_DOCTORS_SUCCESS:
            return getListDoctorsHandler(state, action);
        case doctorActionTypes.GET_DOCTOR_DETAILS_SUCCESS:
            return getDoctorDetailsHandler(state, action);
        default:
            return state;
    }
};

export default doctorReducer;
