import ourBusinessesServices from 'api/our-businesses';

import { call, takeLatest, put, fork } from 'redux-saga/effects';
import ourBusinessesActions from './ourBusinessesActions';
import ourBusinessesActionTypes from './ourBusinessesActionTypes';

/**
 * TODO: Get list our businesses
 */

function* getListOurBusinessesHandler() {
    try {
        const response = yield call(ourBusinessesServices.getListOurBusinesses);
        if (+response.status === 1) {
            yield put(ourBusinessesActions.getListOurBusinessesSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get list medicines
 */

function* getListMedicinesHandler() {
    try {
        const response = yield call(ourBusinessesServices.getListMedicines);

        if (+response.status === 1) {
            yield put(ourBusinessesActions.getListMedicinesSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get list tender
 */

function* getListTenderHandler({ payload }) {
    try {
        const requestParams = {
            page: payload?.page || 1,
            limit: payload?.limit || 10
        };

        const response = yield call(ourBusinessesServices.getListTender, requestParams);

        if (+response.status === 1) {
            yield put(ourBusinessesActions.getListTenderSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Search data
 */

function* searchDataHandler({ payload }) {
    try {
        const response = yield call(ourBusinessesServices.searchData, payload);

        if (+response.status === 1) {
            yield put(ourBusinessesActions.searchDataSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get here for you handler
 */

function* getHereForYouHandler() {
    try {
        const response = yield call(ourBusinessesServices.getHereForYou);

        if (+response.status === 1) {
            yield put(ourBusinessesActions.getHereForYouSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

function* watchOurBusinessesSaga() {
    yield takeLatest(
        ourBusinessesActionTypes.GET_LIST_OUR_BUSINESSES_REQUEST,
        getListOurBusinessesHandler
    );

    yield takeLatest(ourBusinessesActionTypes.GET_LIST_MEDICINES_REQUEST, getListMedicinesHandler);

    yield takeLatest(ourBusinessesActionTypes.GET_LIST_TENDER_REQUEST, getListTenderHandler);

    yield takeLatest(ourBusinessesActionTypes.SEARCH_DATA_REQUEST, searchDataHandler);

    yield takeLatest(ourBusinessesActionTypes.GET_HERE_FOR_YOU_REQUEST, getHereForYouHandler);
}

export default function* ourBusinessesSaga() {
    yield fork(watchOurBusinessesSaga);
}
