import httpClient from '../index';
import NEWS_CONSTANTS from 'api/constants/news';

const getListNews = async (params) => {
    try {
        const getListNewsResponse = await httpClient.get(NEWS_CONSTANTS.GET_LIST_EVENTS_AND_NEWS, {
            params
        });

        return getListNewsResponse;
    } catch (error) {
        return error;
    }
};

const getNewsDetails = async (payload) => {
    try {
        const getNewsDetailsResponse = await httpClient.get(
            `${NEWS_CONSTANTS.GET_NEWS_DETAILS}/${payload?.slug}`
        );

        return getNewsDetailsResponse;
    } catch (error) {
        payload?.navigate('/404');
        return error;
    }
};

export default {
    getListNews,
    getNewsDetails
};
