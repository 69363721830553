import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
// import './list_package.css';

const Package = ({ item }) => {
    const packageItems = item.items.map((_item) => {
        var url = '/packages/' + _item.slug;
        var _image = 'https://media.kpjhealth.com.my/' + _item.image;
        if (_item.description.includes('<br>')) {
            // console.log('include <br>');
        } else {
            // console.log('not include <br>');
        }
        var _desc = _item.description.replace(/<br>/g, '<br />');
        // console.log(_desc);
        return (
            <div
                key={_item.id}
                className='lcms-plexcart-item w-full md:w-4/12 p-3'
                data-class='package_container'
            >
                <div data-class='package_inner_container' className='w-full h-full'>
                    <div
                        style={{
                            backgroundImage: `url(${_image})`,
                            height: `300px`,
                            backgroundSize: `cover`,
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <a className='title title-first' data-class='package_title' href={url}>
                            {_item.title}
                        </a>
                    </div>

                    <div className='lcms-plexcart-item-content'>
                        <div className='pt-3'>
                            <div className='package_description'>{ReactHtmlParser(_desc)}</div>
                            <div
                                className='flex justify-between'
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    right: '0'
                                }}
                            >
                                <a
                                    className='view-detail-btn primary-btn w-full text-center'
                                    data-class='book_an_appointment'
                                >
                                    MYR {_item.price.toFixed(2)}
                                </a>
                                {/*<a
                                className='view-detail-btn primary-btn w-100'
                                data-class='book_an_appointment'
                                href={`/packages/appointment/${_item.slug}`}
                            >
                                Book Package
                            </a>*/}
                                <a
                                    className='view-detail-btn primary-btn w-full text-center'
                                    data-class='find_out_more'
                                    href={url}
                                >
                                    View Details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className='plexcms-container'>
            <div className='lcms-span-container lg:w-full sm:w-full md:w-full lg:inherit sm:inherit md:inherit'>
                <div className='lcms-content-container'>
                    <div className='lcms-sections' id='packages'>
                        <div className='plexcms-container'>
                            <div className='lcms-span-container lg:w-full w-full sm:w-full md:w-full lg:inherit inherit sm:inherit md:inherit'>
                                <div className='lcms-content-container _static'>
                                    <div className='lcms-plexcart-items flex flex-wrap'>
                                        {packageItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Package.propTypes = {
    item: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
                slug: PropTypes.string,
                content: PropTypes.string,
                image: PropTypes.string,
                image_thumb: PropTypes.string,
                price: PropTypes.number
            })
        ),
        item_per_row: PropTypes.number
    })
};

export default Package;