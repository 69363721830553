import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import OurServicesHeaderSection from 'layouts/our-services/OurServicesHeaderSection';
import OurServicesDetailsLayout from 'layouts/our-services/OurServicesDetailsLayout';
import OurServicesFooter from 'layouts/our-services/OurServicesFooter';

import { connect } from 'react-redux';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import {
    makeSelectCenterForSight,
    makeSelectListServices
} from 'services/our-services/ourServicesSelector';
import ourServicesActions from 'services/our-services/ourServicesActions';

const CenterForSight = ({
    centerForSight = {},
    getCenterForSight = () => {},
    sections = [],
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            getCenterForSight();
        }
    }, [sections, isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{centerForSight?.meta?.title}</title>
                <meta name='description' content={centerForSight?.meta?.description} />
                <meta name='keywords' content={centerForSight?.meta?.keywords} />
            </Helmet>

            <div className='bg-layer'>
                <OurServicesHeaderSection defaultCheckedTitle='centre-for-sight' />
                <OurServicesDetailsLayout data={centerForSight?.layout} />
                <OurServicesFooter currentIndex={1} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListServices: () => dispatch(ourServicesActions.listOurServices()),
        getCenterForSight: () => dispatch(ourServicesActions.getCenterForSight())
    };
};

const mapStateToProps = (state) => {
    return {
        metaData: makeSelectMetaData(state),
        centerForSight: makeSelectCenterForSight(state),
        sections: makeSelectListServices(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

CenterForSight.propTypes = {
    centerForSight: PropTypes.object,
    metaData: PropTypes.object,
    getCenterForSight: PropTypes.func,
    sections: PropTypes.array,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(CenterForSight);
