import React from 'react';
import PropTypes from 'prop-types';
// import './slideshow.css';

// ~ slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slideshow = ({ item }) => {
    const _fade = item.options.effect === 'fade';
    const settings = {
        className: '',
        arrows: item.options.showMakers,
        dots: true,
        fade: _fade,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoPlay: item.options.slideAutomatically,
        autoplaySpeed: item.options.delay,
        speed: 4000
    };
    const { items } = item;
    return (
        <div
            style={{ height: item.options.height }}
            className={`lcms-sections`}
            id={`slideshow-${item.id}`}
        >
            <div className={`plexcms-container`}>
                <div
                    className={`lcms-span-container lg:w-full w-full sm:w-full md:w-full lg:inherit inherit sm:inherit md:inherit`}
                >
                    <div className={`lcms-content-container`}>
                        <div
                            className={`lcms-slideshow-contents lg:w-full w-full sm:w-full md:w-full lg:inherit inherit sm:inherit md:inherit`}
                        >
                            <div id={`banner${item.id}`} className={`bannerwid`}>
                                <ul>
                                    <Slider {...settings}>
                                        {items.map((slideItem) => (
                                            <li
                                                key={slideItem.id}
                                                style={{
                                                    backgroundImage: `url(${
                                                        'https://media.kpjhealth.com.my/' +
                                                        slideItem.image
                                                    })`
                                                }}
                                            >
                                                <div
                                                    className={`lcms-slideshow-mobile-bg`}
                                                    style={{
                                                        backgroundImage: `url(${
                                                            'https://media.kpjhealth.com.my/' +
                                                            slideItem.image
                                                        })`
                                                    }}
                                                >
                                                    <div className={`bjqs-caption-wrapper`}></div>
                                                    <img
                                                        src={
                                                            'https://media.kpjhealth.com.my/' +
                                                            slideItem.image
                                                        }
                                                        alt={slideItem.title}
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                    </Slider>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Slideshow.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        options: PropTypes.shape({
            slideAutomatically: PropTypes.string,
            showMakers: PropTypes.string,
            showControl: PropTypes.string,
            centerMakers: PropTypes.string,
            effect: PropTypes.string,
            height: PropTypes.string,
            delay: PropTypes.string
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                image: PropTypes.string,
                title: PropTypes.string
            })
        )
    })
};

export default Slideshow;
