import React from 'react';
import PropTypes from 'prop-types';
import BaseHospitalCard from 'components/BaseHospitalCard';

import { connect } from 'react-redux';
import { makeSelectListHospitals } from 'services/hospitals/hospitalSelector';

const OurHospitalsLayout = ({ hospitals = [] }) => {
    // ? Render hospital list
    const renderHospitalList = () => {
        if (hospitals.length > 0) {
            return hospitals.map((hospital) => {
                if (hospital.isHidden == 0) {
                    return (
                        <BaseHospitalCard
                            id={hospital.id}
                            address={hospital.address}
                            phone={hospital.tel}
                            name={hospital.name}
                            image={hospital.photo}
                            website={hospital.website}
                            description={hospital.description}
                            key={hospital.id}
                        />
                    );
                }
            });
        }
    };

    return (
        <div className='container mx-auto pt-5 flex flex-col gap-3 px-4 md:px-[80px]'>
            {renderHospitalList()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        hospitals: makeSelectListHospitals(state)
    };
};

OurHospitalsLayout.propTypes = {
    hospitals: PropTypes.array
};

export default connect(mapStateToProps)(OurHospitalsLayout);
