import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ~ Components
import HereForYouHeaderSection from 'layouts/hereforyou/HereForYouHeaderSection';
import HereForYouLayout from 'layouts/hereforyou/HereForYouLayout';
import { Helmet } from 'react-helmet-async';

// ~ Services
import { useOurBusinessesSlice } from 'services/our-businesses';
import ourBusinessesActions from 'services/our-businesses/ourBusinessesActions';
import { makeSelectIsAuthenticated, makeSelectMetaData } from 'services/meta/metaSelectors';
import { makeSelectHereForYouData } from 'services/our-businesses/ourBusinessesSelector';

const HereForYou = ({
    getHereForYouData = () => {},
    isAuthenticated = false,
    hereForYouData = {},
    metaData = {}
}) => {
    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useOurBusinessesSlice();
            getHereForYouData();
        }
    }, [isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta name='keywords' content={metaData?.keywords} />
            </Helmet>

            <div className='bg-layer'>
                <HereForYouHeaderSection data={hereForYouData?.headerSection} />
                <HereForYouLayout
                    content={hereForYouData?.content}
                    sections={hereForYouData?.sections}
                />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getHereForYouData: () => dispatch(ourBusinessesActions.getHereForYouRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: makeSelectIsAuthenticated(state),
        metaData: makeSelectMetaData(state),
        hereForYouData: makeSelectHereForYouData(state)
    };
};

HereForYou.propTypes = {
    getHereForYouData: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    hereForYouData: PropTypes.object,
    metaData: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(HereForYou);
