import { camelize } from 'transformer/common';
import OUR_SERVICES_CONSTANTS from 'api/constants/our-services';
import { transformContent } from 'utils';

const ourServicesSectionsDataTransformer = (data) => {
    let dataTransformed = camelize(data);
    let result = [];

    dataTransformed.forEach((item) => {
        let contents = item?.content;
        let tempContents = [];

        contents.forEach((content) => {
            tempContents.push(transformContent(content));
        });

        item.content = tempContents;

        result.push(item);
    });

    return result;
};

const ourServicesSubheaderDataTransformer = (data) => {
    const dataCamelized = camelize(data);

    dataCamelized?.others.forEach((item) => {
        OUR_SERVICES_CONSTANTS[item.title] = `v1/page/${item.slug}`;
    });

    return {
        desc: dataCamelized?.intro,
        title: dataCamelized?.subhead,
        options: dataCamelized?.others
    };
};

const ourServicesMetaDataTransformer = (data) => {
    const dataCamelized = camelize(data);

    return {
        description: dataCamelized?.metaDescription,
        title: dataCamelized?.metaTitle,
        keywords: dataCamelized?.metaKeywords
    };
};

export {
    ourServicesSectionsDataTransformer,
    ourServicesSubheaderDataTransformer,
    ourServicesMetaDataTransformer
};
