import { listHospitalsDataTransformer } from 'transformer/hospital';

const getListHospitalsHandler = (state, { payload }) => {
    return {
        ...state,
        hospitals: listHospitalsDataTransformer(payload)
    };
};

export { getListHospitalsHandler };
