import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// import './accordion.css';
// import './custom.js';

const Accordion = ({ items }) => {
    const accordionItems = items.map((item, key) => {
        var _class = "";
        if (key === 0) {
            _class = 'rounded-tl-lg';
        }
        item.content = item.content.replace(`<br>`, `<br/>`);

        return (
            <div
                key={item.id}
                className={`_parent border border-black-200 ${_class}`}
                style={{backgroundColor:'rgb(87 117 211)',fontFamily:'inherit'}} 
            >
                <div className='_item grid items-center cursor-pointer border-t border-gray-200'>
                    <h4 className='p-4 text-white'>
                        <i className='fas fa-chevron-up'></i>
                        {ReactHtmlParser(item.title)}
                    </h4>
                    <div className='_content bg-white border-t border-gray-200 border-b p-2 hidden'>
                        {ReactHtmlParser(item.content)}
                    </div>
                </div>
            </div>
        );
    });

    return <div>{accordionItems}</div>;
};

Accordion.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            content: PropTypes.string
        })
    )
};

export default Accordion;
