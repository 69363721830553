import PackagesServices from 'api/packages';

import { call, takeLatest, put, fork } from 'redux-saga/effects';

import packagesActions from './packagesActions';
import packagesActionTypes from './packagesActionTypes';

/**
 * TODO: Get package details
 */

function* getPackageDetailsHandler({ payload }) {
    try {
        const response = yield call(PackagesServices.getPackageDetails, payload);
        if (+response.status === 1) {
            yield put(packagesActions.getPackageDetailsSuccess(response.data));
        }

        if (+response.status === 0) {
            payload?.navigate('/404');
        }
    } catch (error) {
        return error;
    }
}

function* watchPackagesSagas() {
    yield takeLatest(packagesActionTypes.GET_PACKAGE_DETAILS_REQUEST, getPackageDetailsHandler);
}

function* rootPackagesSagas() {
    yield fork(watchPackagesSagas);
}

export default rootPackagesSagas;
