import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'flowbite-react';
import FindDoctorImagePath from 'assets/common/find-doctor.png';

// ? Options value
const options = [
    {
        label: 'Our Hospitals',
        value: '/our-hospitals'
    },
    {
        label: 'Find Doctors',
        value: '/find-doctor'
    },
    {
        label: 'Specialties Centers',
        value: '/our-services'
    },
    {
        label: 'Health Tourism',
        value: '/health-tourism'
    },
    {
        label: 'Corporate Profile',
        value: 'https://kpj.listedcompany.com/profile.html'
    },
    {
        label: 'Investor Relations',
        value: 'https://kpj.listedcompany.com/events_calendar.html'
    }
];

const FooterFindDoctor = () => {
    const [valueSelect, setValueSelect] = useState('');
    const navigate = useNavigate();

    const handleOnSearch = () => {
        if (valueSelect.includes('https://') || valueSelect.includes('http://')) {
            window.open(valueSelect, '_self');
            return;
        } else {
            navigate(valueSelect);
        }
    };

    // ? Render items
    const renderItems = () => {
        if (options.length > 0) {
            return options.map((item, idx) => (
                <option key={idx} value={item.value}>
                    {item.label}
                </option>
            ));
        } else {
            return <option>No data</option>;
        }
    };

    const handleOnChangeValue = (e) => {
        setValueSelect(e.target.value);
    };

    return (
        <div className='h-[260px]'>
            <div className='container mx-auto h-full relative'>
                <div className='w-full h-full absolute top-0 left-0 container'>
                    <img
                        src={FindDoctorImagePath}
                        className='w-full h-full object-cover md:object-top object-right'
                    />
                </div>

                <div className='relative md:mx-14 mx-5 h-full flex md:justify-center justify-start flex-col py-4'>
                    <div className='md:text-[40px] text-[24px] md:text-start text-center font-bold'>
                        I am looking for
                    </div>
                    <div className='flex gap-2 w-full mt-4 md:flex-row flex-col'>
                        <Select className='md:w-[400px] w-full' onChange={handleOnChangeValue}>
                            {renderItems()}
                        </Select>
                        <button
                            className='px-[1rem] md:h-auto h-[32px] rounded-[10px] w-fit md:mx-0 mx-auto bg-secondary text-white min-w-[100px]'
                            onClick={handleOnSearch}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterFindDoctor;
