import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FooterFindDoctor from './FooterFindDoctor';
import FooterMenu from './FooterMenu';
import FooterCopyright from './FooterCopyright';
import { makeSelectFooterData } from 'services/meta/metaSelectors';

const TheFooter = ({ footerData = {} }) => {
    return (
        <footer className='bg-layer md:pt-8 pt-4'>
            <FooterFindDoctor />

            <FooterMenu menus={footerData?.menus} social={footerData?.social}/>

            <FooterCopyright data={footerData?.copyright} />
        </footer>
    );
};

const mapStateToProps = (state) => {
    return {
        footerData: makeSelectFooterData(state)
    };
};

TheFooter.propTypes = {
    footerData: PropTypes.object
};

export default connect(mapStateToProps)(TheFooter);
