import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ~ Components
import MedicinesHeaderSection from 'layouts/medicines/MedicinesHeaderSection';
import MedicinesLayout from 'layouts/medicines/MedicinesLayout';

// ~ Services
import { useOurBusinessesSlice } from 'services/our-businesses';
import ourBusinessesActions from 'services/our-businesses/ourBusinessesActions';
import { makeSelectListMedicinesData } from 'services/our-businesses/ourBusinessesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const MedicinesServices = ({
    getListMedicinesData = () => {},
    medicinesData = {},
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            useOurBusinessesSlice();
            getListMedicinesData();
        }
    }, [isAuthenticated]);

    return (
        <div className='bg-layer pt-5'>
            <div className='py-5 container mx-auto'>
                <div className='bg-white p-5'>
                    <MedicinesHeaderSection data={medicinesData?.headerSection} />
                </div>

                <div className='bg-white p-5'>
                    <MedicinesLayout sections={medicinesData?.sections} />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListMedicinesData: () => dispatch(ourBusinessesActions.getListMedicinesRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        medicinesData: makeSelectListMedicinesData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

MedicinesServices.propTypes = {
    getListMedicinesData: PropTypes.func,
    medicinesData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicinesServices);
