import React from 'react';

const AppointmentIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.93 12.93' width={13} height={13}>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <path
                        fill='#fff'
                        d='M0,4.62H12.93v6.91a1.37,1.37,0,0,1-1,1.35,1.75,1.75,0,0,1-.4,0H1.43A1.38,1.38,0,0,1,0,11.5V4.62ZM5.56,9.07l-1-1a.46.46,0,1,0-.65.64L5.17,10a.48.48,0,0,0,.72,0L9.05,7.3a.48.48,0,0,0,.09-.68.46.46,0,0,0-.69,0C8,7,7.53,7.39,7.07,7.78Z'
                    />
                    <path
                        fill='#fff'
                        d='M0,3.68c0-.54,0-1.08,0-1.6A1.31,1.31,0,0,1,1.36.93H3.23V.48a.47.47,0,1,1,.93,0V.92H8.77V.51a.47.47,0,1,1,.93,0V.92h1.8a1.39,1.39,0,0,1,1.43,1.44V3.68Z'
                    />
                </g>
            </g>
        </svg>
    );
};

export default AppointmentIcon;
