import React from 'react';
import PropTypes from 'prop-types';

const PhoneIcon = ({ height = 21, width = 21 }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 21.95 22'
            height={height}
            width={width}
        >
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <path
                        fill='#fff'
                        className='cls-1'
                        d='M4.53,0a2.69,2.69,0,0,1,1.2.52l.15.13,3,3a2.09,2.09,0,0,1,.34,2.6,1.84,1.84,0,0,1-1,.8A2.12,2.12,0,0,0,6.87,8.2a1.8,1.8,0,0,0-.09,1.23,7.3,7.3,0,0,0,1.64,2.89,10,10,0,0,0,3.12,2.48,2.74,2.74,0,0,0,1.63.38A1.94,1.94,0,0,0,14.84,14a2.38,2.38,0,0,1,.92-1.2,2,2,0,0,1,2.4.21c.6.55,1.17,1.14,1.74,1.72s1.08,1,1.56,1.59a2.13,2.13,0,0,1,.1,2.58,5.2,5.2,0,0,1-.38.44c-.58.58-1.17,1.15-1.74,1.75a3,3,0,0,1-2.2.95,7.24,7.24,0,0,1-2.65-.45,18,18,0,0,1-4.66-2.49A30.37,30.37,0,0,1,3.64,13,19,19,0,0,1,.47,7.4,7.47,7.47,0,0,1,0,4.63,3,3,0,0,1,1,2.46C1.48,2,2,1.45,2.48.93A2.69,2.69,0,0,1,4.05,0Z'
                    />
                </g>
            </g>
        </svg>
    );
};

PhoneIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number
};

export default PhoneIcon;
