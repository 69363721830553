import newServices from 'api/news';
import { takeLatest, call, put, fork } from 'redux-saga/effects';

import newsActions from './newsActions';
import newsActionTypes from './newsActionTypes';

/**
 * TODO: Get list news
 */

function* getListNewsHandler({ payload }) {
    try {
        const requestParams = {
            page: payload?.page || 1,
            limit: payload?.limit || 12,
            category_id: payload?.category || '',
            year: payload?.year || ''
        };
        const response = yield call(newServices.getListNews, requestParams);

        if (+response.status === 1) {
            yield put(newsActions.getListNewsSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

/**
 * TODO: Get news details
 */

function* getNewsDetailsHandler({ payload }) {
    try {
        const response = yield call(newServices.getNewsDetails, payload);

        if (+response.status === 1) {
            yield put(newsActions.getNewsDetailsSuccess(response.data));
        }
    } catch (error) {
        return error;
    }
}

function* watchNewsSaga() {
    yield takeLatest(newsActionTypes.GET_LIST_NEWS_REQUEST, getListNewsHandler);
    yield takeLatest(newsActionTypes.GET_NEWS_DETAILS_REQUEST, getNewsDetailsHandler);
}

export default function* newsSaga() {
    yield fork(watchNewsSaga);
}
