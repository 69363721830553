const ourServicesInitState = {
    sections: [],
    centerForSight: {},
    seniorLivingCare: {},
    laboratoryServices: {},
    dentalSpecialityCenter: {},
    rehabilitationServices: {},
    bariatricSurgery: {}
};

export default ourServicesInitState;
