import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LogoPath from 'assets/common/logo.svg';
import { GiHamburgerMenu } from 'react-icons/gi';

import TheSideBarMobile from './TheSideBarMobile';

import { useMetaSlice } from 'services/meta';
import { useOurServicesSlice } from 'services/our-services';
import metaActions from 'services/meta/metaActions';
import ourServicesActions from 'services/our-services/ourServicesActions';

import { makeSelectHeaderData } from 'services/meta/metaSelectors';

const TheMobileHeader = ({
    headerData = {},
    getMetaData = () => {},
    getListStates = () => {},
    //    getAllSpecialities = () => {},
    getListServices = () => {}
}) => {
    const [isShowSideBar, setIsShowSideBar] = useState(false);

    useEffect(() => {
        useMetaSlice();
        getMetaData();
        setTimeout(() => {
            getListStates();
            //    getAllSpecialities();
            useOurServicesSlice();
            getListServices();
        }, 1000);
    }, []);

    const handleOnDirectToHomepage = () => {
        window.location.href = '/';
    };

    return (
        <div className='relative'>
            <div className='py-4 bg-main flex justify-between px-4 items-center cursor-pointer relative'>
                <div className='w-[200px]' onClick={handleOnDirectToHomepage}>
                    <img src={LogoPath} />
                </div>

                <GiHamburgerMenu
                    color='white'
                    fontSize={18}
                    onClick={() => setIsShowSideBar((prevState) => !prevState)}
                />
            </div>
            {isShowSideBar && (
                <TheSideBarMobile
                    menus={headerData?.menus}
                    onClose={() => setIsShowSideBar((prevState) => !prevState)}
                />
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMetaData: () => dispatch(metaActions.getMetaData()),
        getListStates: () => dispatch(metaActions.getAllState()),
        getAllSpecialities: () => dispatch(metaActions.getAllSpecialities()),
        getListServices: () => dispatch(ourServicesActions.listOurServices())
    };
};

const mapStateToProps = (state) => {
    return {
        headerData: makeSelectHeaderData(state)
    };
};

TheMobileHeader.propTypes = {
    headerData: PropTypes.object,
    getMetaData: PropTypes.func,
    getListStates: PropTypes.func,
    getAllSpecialities: PropTypes.func,
    getListServices: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(TheMobileHeader);
