import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// ~ Components
import TenderHeaderSection from 'layouts/tender/TenderHeaderSection';
import TenderLayout from 'layouts/tender/TenderLayout';

//  ~ Services
import { connect } from 'react-redux';
import { useOurBusinessesSlice } from 'services/our-businesses';
import ourBusinessesActions from 'services/our-businesses/ourBusinessesActions';
import { makeSelectTenderData } from 'services/our-businesses/ourBusinessesSelector';
import { makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const Tender = ({ getListTender = () => {}, tenderData = {}, isAuthenticated = false }) => {
    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useOurBusinessesSlice();
            getListTender({ page: 1, limit: 10 });
        }
    }, [isAuthenticated]);

    const handleOnChangePagination = (page) => {
        getListTender({ page, limit: 10 });
    };

    return (
        <div className='bg-layer'>
            <TenderHeaderSection />
            <TenderLayout data={tenderData} onChange={handleOnChangePagination} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListTender: (params) => dispatch(ourBusinessesActions.getListTenderRequest(params))
    };
};

const mapStateToProps = (state) => {
    return {
        tenderData: makeSelectTenderData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

Tender.propTypes = {
    getListTender: PropTypes.func,
    tenderData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Tender);
