const metaActionTypes = {
    GET_META_DATA_REQUEST: 'GET_META_DATA_REQUEST',
    GET_META_DATA_SUCCESS: 'GET_META_DATA_SUCCESS',
    GET_META_DATA_FAILURE: 'GET_META_DATA_FAILURE',

    // ? Get all events
    GET_ALL_EVENTS_REQUEST: 'GET_ALL_EVENTS_REQUEST',
    GET_ALL_EVENTS_SUCCESS: 'GET_ALL_EVENTS_SUCCESS',
    GET_ALL_EVENTS_FAILURE: 'GET_ALL_EVENTS_FAILURE',

    // ? Get all state
    GET_ALL_STATE_REQUEST: 'GET_ALL_STATE_REQUEST',
    GET_ALL_STATE_SUCCESS: 'GET_ALL_STATE_SUCCESS',
    GET_ALL_STATE_FAILURE: 'GET_ALL_STATE_FAILURE',

    // ? Get list of Specialties
    GET_ALL_SPECIALITIES_REQUEST: 'GET_ALL_SPECIALITIES_REQUEST',
    GET_ALL_SPECIALITIES_SUCCESS: 'GET_ALL_SPECIALITIES_SUCCESS',
    GET_ALL_SPECIALITIES_FAILURE: 'GET_ALL_SPECIALITIES_FAILURE',

    // ? Get page with slug
    GET_PAGE_WITH_SLUG_REQUEST: 'GET_PAGE_WITH_SLUG_REQUEST',
    GET_PAGE_WITH_SLUG_SUCCESS: 'GET_PAGE_WITH_SLUG_SUCCESS',
    GET_PAGE_WITH_SLUG_FAILURE: 'GET_PAGE_WITH_SLUG_FAILURE'
};

export default metaActionTypes;
