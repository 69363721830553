import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';
// ~ Components
import { Tabs } from 'flowbite-react';
import CardCaseProgramPackages from './CardCaseProgramPackages';
import CardCaseProgramAccordion from './CardCaseProgramAccordion';
import CardCareForm from './CardCareForm';

const { REACT_APP_DOMAIN_MEDIA } = process.env;

const CardCareProgramTabs = ({ tabs = [] }) => {
    const toastId = React.useRef(null);
    // ? Render more tab content
    const renderMoreTabContent = (contents = []) => {
        if (contents.length > 0) {
            return contents.map((content, idx) => {
                if (content.type === 'gallery') {
                    return <img key={idx} src={`${REACT_APP_DOMAIN_MEDIA}${content.image}`} />;
                }
            });
        }
    };

    const handleOnSuccess = () => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(
                'Your inquiry has been received. We will be in touch shortly. Alternatively, please call us at our hotline for immediate response. Thank You.'
            );
        }
    };

    // ? Render tab content
    const renderTabContent = (tab = {}) => {
        // ! List packages
        if (tab.staticBlocks.includes('list_package')) {
            return <CardCaseProgramPackages packages={tab.contentItems} />;
        }

        // ! Accordion
        if (tab.staticBlocks.includes('accordion')) {
            return <CardCaseProgramAccordion items={tab.contentItems} />;
        }

        // ! Form
        if (tab.staticBlocks.includes('form')) {
            return (
                <CardCareForm items={tab.contentItems} id={tab.id} onSuccess={handleOnSuccess} />
            );
        }
        return <>{renderMoreTabContent(tab.contentItems)}</>;
    };

    // ? Render tabs
    const renderTabs = () => {
        return tabs.map((tab, index) => {
            return (
                <Tabs.Item
                    key={index}
                    title={ReactHtmlParser(tab.title)}
                    className='text-uppercase'
                >
                    <div className='bg-white p-6'>
                        <div>{ReactHtmlParser(tab.content)}</div>
                        <div className={`grid md:grid-cols-${tab?.itemPerRow} grid-cols-1 gap-5`}>
                            {renderTabContent(tab)}
                        </div>
                    </div>
                </Tabs.Item>
            );
        });
    };

    // ? Render tab group
    const renderTabGroup = () => {
        if (tabs.length > 0) {
            return (
                <Tabs.Group
                    id='card-care-tabs'
                    style='underline'
                    className='container mx-auto md:px-[80px] px-4 justify-center border-none w-full mt-5'
                >
                    {renderTabs()}
                </Tabs.Group>
            );
        }
    };
    return <div>{renderTabGroup()}</div>;
};

CardCareProgramTabs.propTypes = {
    tabs: PropTypes.array
};

export default CardCareProgramTabs;
