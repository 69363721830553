import Cookies from 'js-cookie';

import httpClient from '../index';
import META_CONSTANTS from 'api/constants/meta';
import NEWS_CONSTANTS from 'api/constants/news';

const { REACT_APP_API_KEY, REACT_APP_API_SECRET, REACT_APP_BASE_TOKEN_KEY } = process.env;
/**
 * TODO: Get homepage data
 */
const getMetaData = async () => {
    try {
        const requestParams = {
            key: REACT_APP_API_KEY,
            secret: REACT_APP_API_SECRET
        };
        const loginResponse = await httpClient.post(META_CONSTANTS.LOGIN, requestParams);

        Cookies.set(REACT_APP_BASE_TOKEN_KEY, loginResponse.access_token);

        const getMetaDataResponse = await httpClient.get(META_CONSTANTS.GET_META);

        return getMetaDataResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get all events
 */
const getAllEvents = async (params) => {
    try {
        // const getAllEventsResponse = await httpClient.get(META_CONSTANTS.EVENTS, { params });
        const getAllEventsResponse = await httpClient.get(NEWS_CONSTANTS.GET_LIST_EVENTS_AND_NEWS, {
            params
        });
        return getAllEventsResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get all state
 */
const getAllState = async () => {
    try {
        const getAllStateResponse = await httpClient.get(META_CONSTANTS.LIST_STATE);

        return getAllStateResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get list of Specialties
 */

const getAllSpecialities = async (params) => {
    try {
        const getAllSpecialitiesResponse = await httpClient.get(META_CONSTANTS.LIST_SPECIALITIES, {
            params
        });

        return getAllSpecialitiesResponse;
    } catch (error) {
        return error;
    }
};

/**
 * TODO: Get page with slug
 */

const getPageWithSlug = async (slug) => {
    try {
        const getPageWithSlugResponse = await httpClient.get(META_CONSTANTS.PAGE_SLUG + slug);

        return getPageWithSlugResponse;
    } catch (error) {
        return error;
    }
};

export default {
    getMetaData,
    getAllEvents,
    getAllState,
    getAllSpecialities,
    getPageWithSlug
};
