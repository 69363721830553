import packagesActionsTypes from './packagesActionTypes';

const getPackageDetailsRequest = (payload) => {
    return {
        type: packagesActionsTypes.GET_PACKAGE_DETAILS_REQUEST,
        payload
    };
};

const getPackageDetailsSuccess = (payload) => ({
    type: packagesActionsTypes.GET_PACKAGE_DETAILS_SUCCESS,
    payload
});

const getPackageDetailsFailure = (error) => ({
    type: packagesActionsTypes.GET_PACKAGE_DETAILS_FAILURE,
    payload: error
});

export default {
    getPackageDetailsRequest,
    getPackageDetailsSuccess,
    getPackageDetailsFailure
};
