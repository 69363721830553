import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'flowbite-react';

const PackagesBreadcrumb = ({ slug }) => {
    return (
        <div className='container mx-auto pt-5 flex flex-col gap-3 px-4 md:px-[80px]'>
            <Breadcrumb className='capitalize text-main'>
                <Breadcrumb.Item>Packages</Breadcrumb.Item>
                <Breadcrumb.Item>{slug}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    );
};

PackagesBreadcrumb.propTypes = {
    slug: PropTypes.string
};
export default PackagesBreadcrumb;
