import React from 'react';

const LocationDetailIcon = () => {
    return (
        <svg
            width={18}
            height={22}
            fill='#fff'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 18.06 24.09'
        >
            <g id='Layer_2'>
                <g id='Layer_1-2'>
                    <path d='M9.65,0c.47.08,1,.13,1.43.24a9.11,9.11,0,0,1,6.85,7.29,11,11,0,0,1-.78,6,24.35,24.35,0,0,1-3.76,6.24,36.14,36.14,0,0,1-3.71,4,.81.81,0,0,1-1.29,0A33.42,33.42,0,0,1,2,15.71,15.5,15.5,0,0,1,.11,10.59,9.11,9.11,0,0,1,7.5.14c.3-.06.61-.09.92-.14ZM9.18,14.05A5,5,0,1,0,4,9.19,5,5,0,0,0,9.18,14.05Z' />
                </g>
            </g>
        </svg>
    );
};

export default LocationDetailIcon;
