import MetaServives from 'api/meta';
import { call, takeLatest, put, fork } from 'redux-saga/effects';

import metaActions from './metaActions';
import metaActionTypes from './metaActionTypes';

/**
 * TODO: Get meta data
 */
function* getMetaDataHandler() {
    try {
        const getMetaDataResponse = yield call(MetaServives.getMetaData);

        if (getMetaDataResponse.status === '1') {
            yield put(metaActions.getMetaDataSuccess(getMetaDataResponse.data));
        }
    } catch (error) {
        yield put(metaActions.getMetaDataFailure(error));
    }
}

/**
 * TODO: Get all events
 */
function* getAllEventsHandler({ payload }) {
    try {
        const requestParams = {
            page: payload?.page || 1,
            limit: payload?.limit || 12
        };
        const getAllEventsResponse = yield call(MetaServives.getAllEvents, requestParams);
        if (+getAllEventsResponse.status === 1) {
            yield put(metaActions.getAllEventsSuccess(getAllEventsResponse.data));
        }
    } catch (error) {
        yield put(metaActions.getAllEventsFailure(error));
    }
}

/**
 * TODO: Get all states
 */

function* getAllStateHandler() {
    try {
        const getAllStateResponse = yield call(MetaServives.getAllState);
        if (+getAllStateResponse.status === 1) {
            yield put(metaActions.getAllStateSuccess(getAllStateResponse.data));
        }
    } catch (error) {
        yield put(metaActions.getAllStateFailure(error));
    }
}

/**
 * TODO: Get all Specialties
 */

function* getAllSpecialitiesHandler({ payload }) {
    try {
        const getAllSpecialitiesResponse = yield call(MetaServives.getAllSpecialities, payload);

        if (+getAllSpecialitiesResponse.status === 1) {
            yield put(metaActions.getAllSpecialitiesSuccess(getAllSpecialitiesResponse.data));
        }
    } catch (error) {
        yield put(metaActions.getAllSpecialitiesFailure(error));
    }
}

/**
 * TODO: Get page with slug
 */

function* getPageWithSlugHandler({ payload }) {
    try {
        const getPageWithSlugResponse = yield call(MetaServives.getPageWithSlug, payload?.slug);

        if (+getPageWithSlugResponse.status === 1) {
            yield put(metaActions.getPageWithSlugSuccess(getPageWithSlugResponse.data));
        } else {
            payload?.navigate('/404');
        }
    } catch (error) {
        yield put(metaActions.getPageWithSlugFailure(error));
    }
}

function* watchMetaSaga() {
    yield takeLatest(metaActionTypes.GET_META_DATA_REQUEST, getMetaDataHandler);
    yield takeLatest(metaActionTypes.GET_ALL_EVENTS_REQUEST, getAllEventsHandler);
    yield takeLatest(metaActionTypes.GET_ALL_STATE_REQUEST, getAllStateHandler);
    yield takeLatest(metaActionTypes.GET_ALL_SPECIALITIES_REQUEST, getAllSpecialitiesHandler);
    yield takeLatest(metaActionTypes.GET_PAGE_WITH_SLUG_REQUEST, getPageWithSlugHandler);
}

export default function* rootMetaSaga() {
    yield fork(watchMetaSaga);
}
