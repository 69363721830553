import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';
import { Select, Button } from 'flowbite-react';
import { makeSelectOurServicesSubheader } from 'services/our-services/ourServicesSelector';
import OurServicesHeaderImagePath from 'assets/common/subheader-ourservices.png';

const OurServicesHeaderSection = ({ subheaderData = {}, defaultCheckedTitle = '' }) => {
    const [slug, setSlug] = useState('');
    const nagative = useNavigate();
    const handleOnApplyFilter = () => {
        if (slug.length > 0) {
            nagative(`/${slug}`);
        } else {
            nagative('/our-services');
        }
    };

    useState(() => {
        setSlug(defaultCheckedTitle);
    }, []);

    useEffect(() => {}, [subheaderData]);

    const handleOnChangeSelect = (e) => {
        setSlug(e.target.value);
    };

    // ? Render options
    const renderOptions = () => {
        if (subheaderData.options) {
            return subheaderData.options.map((item) => {
                return (
                    <option key={item.id} value={item.slug}>
                        {item.title}
                    </option>
                );
            });
        }
    };
    return (
        <BaseHeaderWithContentSection
            title={subheaderData?.title}
            desc={subheaderData?.desc}
            imagePath={OurServicesHeaderImagePath}
        >
            <div className='flex gap-3 md:mt-7 mt-4 md:flex-row flex-col '>
                <Select className='w-[360px]' onChange={handleOnChangeSelect} value={slug}>
                    {renderOptions()}
                </Select>

                <Button
                    className='px-2 md:w-[100px] w-fit mx-auto'
                    color='dark'
                    onClick={handleOnApplyFilter}
                >
                    Search
                </Button>
            </div>
        </BaseHeaderWithContentSection>
    );
};

const mapStateToProps = (state) => {
    return {
        subheaderData: makeSelectOurServicesSubheader(state)
    };
};

OurServicesHeaderSection.propTypes = {
    subheaderData: PropTypes.object,
    defaultCheckedTitle: PropTypes.string
};

export default connect(mapStateToProps)(OurServicesHeaderSection);
