import React from 'react';
import PropTypes from 'prop-types';
import { MdLocationPin } from 'react-icons/md';
import PhoneCircledIcon from 'assets/icons/PhoneCircledIcon';
import { Button } from 'flowbite-react';
import ReactHtmlParser from 'react-html-parser';

const { REACT_APP_DOMAIN_MEDIA } = process.env;

const BaseHospitalCard = ({
    id = '',
    address = '',
    phone = '',
    name = '',
    image = '',
    website = '',
    description = ''
}) => {
    return (
        <div>
            <div className='our-service-desktop'>
                <div className='grid grid-cols-1 h-fit overflow-hidden md:grid-cols-2'>
                    {/* Left section */}
                    <div className='bg-hospital-card flex' id={`${id}`}>
                        <div className='flex md:flex-row flex-col p-3 h-full px-5 gap-5 md:items-start w-full align-top'>
                            <div className='md:min-w-[200px] md:w-[200px] min-w-[160px] w-[160px] h-[160px] overflow-hidden rounded-2xl mt-3 mx-auto'>
                                <img
                                    src={`${REACT_APP_DOMAIN_MEDIA}${image}`}
                                    className='h-full w-full object-cover'
                                />
                            </div>

                            <div className='text-center md:text-start'>
                                <div className='font-bold text-white mt-3 text-[18px]'>{name}</div>

                                <div className='mt-1 text-white text-[13px]'>
                                    {ReactHtmlParser(description)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right section */}

                    <div className='h-full p-6 bg-white md:min-h-fit min-h-[250px]'>
                        <div className='flex items-center gap-1'>
                            <MdLocationPin color='#606060' fontSize={18} />
                            <div className='text-[#606060]'>Address</div>
                        </div>

                        <div className='text-main mt-3 ml-1 font-bold'>{address}</div>

                        <div className='w-full h-[1px] bg-main mx-1 my-5'></div>

                        <div className='flex justify-between'>
                            <div className='flex items-center gap-3 cursor-pointer'>
                                <PhoneCircledIcon />
                                <div className='text-[14px]'>
                                    <a href={`tel:${phone}`}>{phone}</a>
                                </div>
                            </div>

                            <a href={website}>
                                <Button
                                    color='dark'
                                    className='px-4 py-0 rounded-2xl text-[12px] uppercase'
                                    size='xs'
                                >
                                    Visit Website
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='our-service-mobile'>
                <div className='grid grid-cols-1'>
                    <div className='bg-hospital-card p-3 w-full h-full'>
                        <div className='md:min-w-[200px] md:w-[200px] min-w-[160px] w-[160px] h-[160px] overflow-hidden rounded-2xl mt-3 mx-auto'>
                            <img
                                src={`${REACT_APP_DOMAIN_MEDIA}${image}`}
                                className='h-full w-full object-cover'
                            />
                        </div>

                        <div className='text-center md:text-start'>
                            <div className='font-bold text-white mt-3 text-[18px]'>{name}</div>

                            <div className='mt-1 text-white text-[13px]'>
                                {ReactHtmlParser(description)}
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-full bg-white p-6'>
                        <div className='flex items-center gap-1'>
                            <MdLocationPin color='#606060' fontSize={18} />
                            <div className='text-[#606060]'>Address</div>
                        </div>

                        <div className='text-main mt-3 ml-1 font-bold'>{address}</div>

                        <div className='w-full h-[1px] bg-main mx-1 my-5'></div>

                        <div className='flex justify-between'>
                            <div className='flex items-center gap-3 cursor-pointer'>
                                <PhoneCircledIcon />
                                <div className='text-[14px]'>
                                    <a href={`tel:${phone}`}>{phone}</a>
                                </div>
                            </div>

                            <a href={website}>
                                <Button
                                    color='dark'
                                    className='px-4 py-0 rounded-2xl text-[12px] uppercase'
                                    size='xs'
                                >
                                    Visit Website
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BaseHospitalCard.propTypes = {
    id: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    website: PropTypes.string,
    description: PropTypes.string
};

export default BaseHospitalCard;
