import hospitalActionTypes from './hospitalActionTypes';
import hospitalInitState from './hospitalInitState';

import { getListHospitalsHandler } from './hospitalReducerHandler';

const hospitalReducer = (state = hospitalInitState, action) => {
    switch (action.type) {
        case hospitalActionTypes.GET_LIST_HOSPITALS_SUCCESS:
            return getListHospitalsHandler(state, action);
        default:
            return state;
    }
};

export default hospitalReducer;
