import { createSelector } from '@reduxjs/toolkit';

const selectGlobal = (state) => {
    return state.hospital || {};
};

// ? Make selector the list hospitals
const makeSelectListHospitals = createSelector(selectGlobal, (globalState) => {
    return globalState.hospitals;
});

export { makeSelectListHospitals };
