import React from 'react';
import PropTypes from 'prop-types';
//  ~ Components
import ourHospitalsImagePath from 'assets/common/subheader-hospitals.png';
import BaseHeaderWithContentSection from 'components/BaseHeaderWithContentSection';

const CsrHeaderSection = ({ data = {} }) => {
    return <BaseHeaderWithContentSection title={data?.title} imagePath={ourHospitalsImagePath} />;
};

CsrHeaderSection.propTypes = {
    data: PropTypes.object
};
export default CsrHeaderSection;
