import React from 'react';
import PropTypes from 'prop-types';

import BaseAccordion from 'components/BaseAccordion';

const CardCaseProgramAccordion = ({ items = [] }) => {
    return (
        <div className='col-span-3'>
            <BaseAccordion items={items} />
        </div>
    );
};

CardCaseProgramAccordion.propTypes = {
    items: PropTypes.array
};

export default CardCaseProgramAccordion;
