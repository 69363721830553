import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

//  ~ Components
import OurBusinessesHeaderSection from 'layouts/our-businesses/OurBusinessesHeaderSection';
import OurBusinessesLayout from 'layouts/our-businesses/OurBusinessesLayout';

// ~ Services
import { connect } from 'react-redux';
import { useOurBusinessesSlice } from 'services/our-businesses';
import ourBusinessesActions from 'services/our-businesses/ourBusinessesActions';

import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';
import { makeSelectOurBusinessesData } from 'services/our-businesses/ourBusinessesSelector';
import { isEmptyObject } from 'utils';

const OurBusinesses = ({
    getListOurBusinesses = () => {},
    ourBusinessesData = {},
    metaData = {},
    isAuthenticated = false
}) => {
    // ? Mounted
    useEffect(() => {
        if (isAuthenticated) {
            useOurBusinessesSlice();
            getListOurBusinesses();
        }
    }, [isAuthenticated]);

    const renderMetaData = () => {
        if (!isEmptyObject(ourBusinessesData)) {
            const metaDataOurBusinesses = ourBusinessesData?.metaData;

            return (
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {metaDataOurBusinesses?.title !== ''
                            ? metaDataOurBusinesses.title
                            : metaData.title}
                    </title>
                    <meta
                        name='description'
                        content={
                            metaDataOurBusinesses?.description !== ''
                                ? metaDataOurBusinesses?.description
                                : metaData.description
                        }
                    />
                    <meta
                        name='keywords'
                        content={
                            metaDataOurBusinesses?.keywords !== ''
                                ? metaDataOurBusinesses.keywords
                                : metaData.keywords
                        }
                    />
                </Helmet>
            );
        }
    };

    return (
        <>
            {renderMetaData()}
            <div className='bg-layer'>
                <OurBusinessesHeaderSection data={ourBusinessesData?.subheaderData} />
                <OurBusinessesLayout sectionsData={ourBusinessesData?.sectionsData} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListOurBusinesses: () => dispatch(ourBusinessesActions.getListOurBusinessesRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        ourBusinessesData: makeSelectOurBusinessesData(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

OurBusinesses.propTypes = {
    getListOurBusinesses: PropTypes.func,
    ourBusinessesData: PropTypes.object,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(OurBusinesses);
