import React from 'react';

import BaseCard from 'components/BaseCard';
import Introduce1ImagePath from 'assets/homepage/introduce-1.png';
import Introduce2ImagePath from 'assets/homepage/introduce-2.png';
import Introduce3ImagePath from 'assets/homepage/introduce-3.png';

const IntroduceData = [
    {
        label: 'Our Services',
        title: 'Best In <br/> Industry',
        desc: 'With the latest addition, the Group now has more than 1,022 medical consultants offering a full range of medical and surgical services from all disciplines. It has also benefited tremendously from the presence of 13,422 team members made up of management, nursing, allied and support services.',
        actionText: 'Our Services',
        imagePath: Introduce1ImagePath,
        to: 'our-services'
    },
    {
        label: 'Our Hospital',
        title: 'We are in the neighbourhood',
        desc: 'Rest and recuperate in one of our themed suites. recover in utmost comfort.',
        actionText: 'Discover our hospital',
        imagePath: Introduce2ImagePath,
        to: 'our-hospitals'
    },
    {
        label: 'Health Tourism',
        title: 'Travel with <br/> Care',
        desc: `The International Patient Centre (IPC) at KPJ Group of Hospitals is a one-stop service that open its doors to specialists' expertise, personalized patient care and advanced technology at our hospitals. Dedicated at experienced patient care, the team provides genuine care, comfort and attend to the needs of patients before, during and if necessary, after their visit.`,
        actionText: 'Find out more',
        imagePath: Introduce3ImagePath,
        to: 'health-tourism'
    }
];

const HomepageIntroduce = () => {
    // ? Render introduce cards
    const renderIntroduceCards = () => {
        return IntroduceData.map((item, index) => {
            return (
                <BaseCard
                    key={index}
                    label={item.label}
                    desc={item.desc}
                    actionText={item.actionText}
                    title={item.title}
                    imagePath={item.imagePath}
                    to={item.to}
                ></BaseCard>
            );
        });
    };
    return (
        <div className='container mx-auto grid md:grid-cols-3 grid-cols-1 gap-[6px]'>
            {renderIntroduceCards()}
        </div>
    );
};

export default HomepageIntroduce;
