import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { useHospitalSlice } from 'services/hospitals';
import {
    makeSelectIsAuthenticated,
    makeSelectMetaData,
    makeSelectStates
} from 'services/meta/metaSelectors';
import hospitalActions from 'services/hospitals/hospitalActions';
import OurHospitalsHeaderSection from 'layouts/our-hospitals/OurHospitalsHeaderSection';
import OurHospitalsLayout from 'layouts/our-hospitals/OurHospitalsLayout';

const OurHospitals = ({
    getListHospitals = () => {},
    states = [],
    metaData = {},
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            useHospitalSlice();
            getListHospitals({ page: 1 });
        }
    }, [isAuthenticated]);

    // ? Handle on search
    const handlerOnSearch = (state) => {
        getListHospitals({ page: 1, state_id: state });
    };

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta name='keywords' content={metaData?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <OurHospitalsHeaderSection items={states} onSearch={handlerOnSearch} />

                <OurHospitalsLayout />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListHospitals: (params) => dispatch(hospitalActions.getListHospitals(params))
    };
};

const mapStateToProps = (state) => {
    return {
        states: makeSelectStates(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

OurHospitals.propTypes = {
    getListHospitals: PropTypes.func,
    states: PropTypes.array,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(OurHospitals);
