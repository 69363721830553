import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
// import './rich_text.css';

const RichText = ({ item }) => {
    return (
        <div key={item.id} className='lcms-content-container'>
            <div className='lcms-richtext-contents'>{ReactHtmlParser(item.content)}</div>
        </div>
    );
};

RichText.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string
    })
};

export default RichText;
