import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useContactUsSlice } from 'services/contact-us';

import ContactUsLocationSection from 'layouts/contact-us/ContactUsLocationSection';
import ContactUsInformationSection from 'layouts/contact-us/ContactUsInformationSection';
import ContactUsFormSection from 'layouts/contact-us/ContactUsFormSection';
import contactUsActions from 'services/contact-us/contactUsActions';
import {
    makeSelectContactUsLocation,
    makeSelectContactUsForm
} from 'services/contact-us/contactUsSelector';
import { makeSelectMetaData, makeSelectIsAuthenticated } from 'services/meta/metaSelectors';

const ContactUs = ({
    getContactUsData = () => {},
    contactUsLocation = {},
    contactUsForm = {},
    metaData = {},
    isAuthenticated = false
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            useContactUsSlice();
            getContactUsData();
        }
    }, [isAuthenticated]);

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                <title>{metaData?.title}</title>
                <meta name='description' content={metaData?.description} />
                <meta name='keywords' content={metaData?.keywords} />
            </Helmet>
            <div className='bg-layer'>
                <ContactUsLocationSection data={contactUsLocation} />
                <ContactUsInformationSection />
                <ContactUsFormSection items={contactUsForm?.items} id={contactUsForm.id} />
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContactUsData: () => dispatch(contactUsActions.getContactUsDataRequest())
    };
};

const mapStateToProps = (state) => {
    return {
        contactUsLocation: makeSelectContactUsLocation(state),
        contactUsForm: makeSelectContactUsForm(state),
        metaData: makeSelectMetaData(state),
        isAuthenticated: makeSelectIsAuthenticated(state)
    };
};

ContactUs.propTypes = {
    getContactUsData: PropTypes.func,
    contactUsLocation: PropTypes.object,
    contactUsForm: PropTypes.any,
    metaData: PropTypes.object,
    isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
