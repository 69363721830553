import React from 'react';
import CookieConsent from 'react-cookie-consent';

import FooterLogoImagePath from 'assets/common/footer-logo.png';

const BaseCookiesConsent = () => {
    return (
        <CookieConsent
            expires={999}
            style={{
                background: '#5675D3',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
            }}
            contentClasses='w-fit flex-[none!important]'
            buttonStyle={{
                width: '100px',
                height: '30px',
                color: '#061a50',
                background: '#e2e8f0',
                border: 0,
                borderRadius: '8px',
                fontSize: '12px',
                whiteSpace: 'nowrap'
            }}
            buttonText='Accept Cookies'
        >
            <div className='flex items-center container justify-between pr-0'>
                <div className='flex gap-5 items-center'>
                    <img src={FooterLogoImagePath} />
                    <div className='text-[14px]'>
                        By clicking “Accept Cookies”, you agree to the storing of cookies on your
                        device to enhance site navigation, analyze site usage.{' '}
                    </div>
                </div>
            </div>
        </CookieConsent>
    );
};

export default BaseCookiesConsent;
