const ourServicesActionTypes = {
    LIST_OUR_SERVICES_REQUEST: 'LIST_OUR_SERVICES_REQUEST',
    LIST_OUR_SERVICES_SUCCESS: 'LIST_OUR_SERVICES_SUCCESS',
    LIST_OUR_SERVICES_FAILURE: 'LIST_OUR_SERVICES_FAILURE',

    // ? Get center for sight
    GET_CENTER_FOR_SIGHT_REQUEST: 'GET_CENTER_FOR_SIGHT_REQUEST',
    GET_CENTER_FOR_SIGHT_SUCCESS: 'GET_CENTER_FOR_SIGHT_SUCCESS',
    GET_CENTER_FOR_SIGHT_FAILURE: 'GET_CENTER_FOR_SIGHT_FAILURE',

    // ? Get senior living care
    GET_SENIOR_LIVING_CARE_REQUEST: 'GET_SENIOR_LIVING_CARE_REQUEST',
    GET_SENIOR_LIVING_CARE_SUCCESS: 'GET_SENIOR_LIVING_CARE_SUCCESS',
    GET_SENIOR_LIVING_CARE_FAILURE: 'GET_SENIOR_LIVING_CARE_FAILURE',

    // ? Get laboratory services
    GET_LABORATORY_SERVICES_REQUEST: 'GET_LABORATORY_SERVICES_REQUEST',
    GET_LABORATORY_SERVICES_SUCCESS: 'GET_LABORATORY_SERVICES_SUCCESS',
    GET_LABORATORY_SERVICES_FAILURE: 'GET_LABORATORY_SERVICES_FAILURE',

    // ? Get dental speciality center
    GET_DENTAL_SPECIALITY_CENTER_REQUEST: 'GET_DENTAL_SPECIALITY_CENTER_REQUEST',
    GET_DENTAL_SPECIALITY_CENTER_SUCCESS: 'GET_DENTAL_SPECIALITY_CENTER_SUCCESS',
    GET_DENTAL_SPECIALITY_CENTER_FAILURE: 'GET_DENTAL_SPECIALITY_CENTER_FAILURE',

    // ? Get rehabilitation center
    GET_REHABILITATION_CENTER_REQUEST: 'GET_REHABILITATION_CENTER_REQUEST',
    GET_REHABILITATION_CENTER_SUCCESS: 'GET_REHABILITATION_CENTER_SUCCESS',
    GET_REHABILITATION_CENTER_FAILURE: 'GET_REHABILITATION_CENTER_FAILURE',

    // ? Get bariatric surgery
    GET_BARIATRIC_SURGERY_REQUEST: 'GET_BARIATRIC_SURGERY_REQUEST',
    GET_BARIATRIC_SURGERY_SUCCESS: 'GET_BARIATRIC_SURGERY_SUCCESS',
    GET_BARIATRIC_SURGERY_FAILURE: 'GET_BARIATRIC_SURGERY_FAILURE'
};

export default ourServicesActionTypes;
