import hospitalActionTypes from './hospitalActionTypes';

const getListHospitals = (params) => ({
    type: hospitalActionTypes.GET_LIST_HOSPITALS_REQUEST,
    payload: params
});

const getListHospitalsSuccess = (payload) => ({
    type: hospitalActionTypes.GET_LIST_HOSPITALS_SUCCESS,
    payload
});

const getListHospitalsFailure = (error) => ({
    type: hospitalActionTypes.GET_LIST_HOSPITALS_FAILURE,
    payload: error
});

export default {
    getListHospitals,
    getListHospitalsSuccess,
    getListHospitalsFailure
};
