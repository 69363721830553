import React from 'react';
import PropTypes from 'prop-types';
import BasePackageCard from 'components/BasePackageCard';

const CardCaseProgramPackages = ({ packages = [] }) => {
    // ? Render packages
    const renderPackages = () => {
        if (packages.length > 0) {
            return packages.map((pkg, idx) => {
                return (
                    <BasePackageCard
                        title={pkg.title}
                        image={pkg.image}
                        price={pkg.price}
                        slug={pkg.slug}
                        desc={pkg.description}
                        key={idx}
                        classNameImage='h-[220px] card-care-package'
                    />
                );
            });
        }
    };
    return <>{renderPackages()}</>;
};

CardCaseProgramPackages.propTypes = {
    packages: PropTypes.array
};
export default CardCaseProgramPackages;
